import { DateTime } from "luxon";

export const handleLoyaltyTransactionsExport = (data) =>
    data?.map((item) => ({
        created_at: DateTime.fromISO(item.loyalty?.created_at).toFormat("ff"),
        updated_at: DateTime.fromISO(item.loyalty?.updated_at).toFormat("ff"),
        points: item?.loyalty?.balance,
        customer_type: item?.loyalty?.customer_type,
        loyalty_type: item?.loyalty?.type,
    }))
const handleLoyaltiesExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.loyalty_balance?.created_at).toFormat("ff"),
        updated_at: DateTime.fromISO(item.loyalty_balance?.updated_at).toFormat("ff"),
        points: item?.loyalty_balance?.balance,
        customer_type: item?.loyalty_balance?.customer_type,
        loyalty_balance: ""
    }))
export default handleLoyaltiesExport