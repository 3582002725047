import React from "react"
import Table from "@mui/material/Table"
import Paper from "@mui/material/Paper"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableContainer from "@mui/material/TableContainer"

import { formatToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

export default function EarningInfo({ data }) {
    return (
        <TableContainer component={Paper} sx={{ position: "relative" }}>
            <Table
                aria-label="spanning table"
                sx={{ bgcolor: "#fff", border: 1, borderColor: "#f2f2f2" }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            align="center"
                            colSpan={6}
                            size="small"
                            sx={{
                                fontWeight: 900,
                                bgcolor: "#f2f2f2",
                                height: 10,
                            }}
                        >
                            Income Details
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell size="small" sx={{ fontWeight: 900 }}>
                            Driver
                        </TableCell>
                        <TableCell
                            size="small"
                            align="left"
                            sx={{ fontWeight: 900 }}
                        >
                            Plate No.
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            RIDE
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            Street Pick-up
                        </TableCell>
                        <TableCell
                            size="small"
                            align="right"
                            sx={{ fontWeight: 900 }}
                        >
                            Total
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell size="small">
                                {row.driver_name}
                            </TableCell>
                            <TableCell size="small" align="left">
                                {row.plate_number}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {row.ride_trip_earning === ""
                                    ? 0
                                    : formatToTwoDecimalPlaces(
                                          row.ride_trip_earning
                                      )}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {row.street_pickup_trips_earning === ""
                                    ? 0
                                    : formatToTwoDecimalPlaces(
                                          row.street_pickup_trips_earning
                                      )}
                            </TableCell>
                            <TableCell size="small" align="right">
                                {formatToTwoDecimalPlaces(row?.total_earning)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
