/* eslint-disable react/jsx-filename-extension */
import React from "react"
import ReactDOM from "react-dom/client"
import { ThemeProvider } from "@mui/system"
import { CssBaseline } from "@mui/material"

import App from "./App.jsx"
import "leaflet/dist/leaflet.css"
import theme from "./Utils/Theme/theme.js"
import reportWebVitals from "./reportWebVitals.js"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
