/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import { handleLoyaltyTransactionsExport } from "../../Utils/File Export Formatter/layaltiesExportFormatter.js"

// List Company Program Transactions Component
function LoyaltyTransactions() {
    const { state } = useLocation()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        updated_at: "is",
        balance: "=",
        customer_type: "is",
        type: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const defaultFilter = {
        column_field: "customer_type",
        operator_value: "is",
        value: state?.loyaltyData?.loyalty_balance?.customer_type,
    }
    const model = filterModel(filterFns, columnFilters)
    model.push(defaultFilter)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "layalty_transactions",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `system/users/${state?.loyaltyData?.id}/loyalties`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        filter:
                            filterModel(filterFns, columnFilters).length === 0
                                ? JSON.stringify([defaultFilter])
                                : JSON.stringify(model),
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error.response.data.error.message ||
                        error.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getLoyaltyType = useCallback(
        ({ row }) =>
            row?.original?.loyalty?.type !== "" &&
            row?.original?.loyalty?.type !== undefined && (
                <Button
                    key={row?.original?.id}
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#f5f5f5",
                        color:
                            row?.original?.loyalty?.type === "Ride Trip"
                                ? "#fbcf3b"
                                : "black",
                        "&: hover": {
                            backgroundColor: "#f5f5f5",
                            color:
                                row?.original?.loyalty?.type === "Ride Trip"
                                    ? "#fbcf3b"
                                    : "black",
                        },
                        fontWeight: "bold",
                    }}
                >
                    {row?.original?.loyalty?.type}
                </Button>
            ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 240,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    DateTime.fromISO(
                        row?.original?.loyalty?.created_at
                    ).toLocaleString(DateTime.DATETIME_MED),
                Filter: datePicker,
            },
            {
                size: 240,
                accessorKey: "type",
                header: "Loyalty Type",
                filterVariant: "select",
                filterSelectOptions: ["Ride Trip", "Ride Plus Trip"],
                Cell: getLoyaltyType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "balance",
                header: "Points",
                filterVariant: "number",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.loyalty?.balance
                    )}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    DateTime.fromISO(
                        row?.original?.loyalty?.updated_at
                    ).toLocaleString(DateTime.DATETIME_MED),
                Filter: datePicker,
            },
        ],
        [getLoyaltyType]
    )
    // RENDERING
    if (!HasPermission("get all loyalties")) {
        return <Unauthorized />
    }

    // RENDER
    if (!HasPermission("get user loyalties")) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ flex: 1, height: "100%" }}>
            <MaterialReactTable
                columns={columns}
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    disabled:
                        filterFns[column?.id] === "notEmpty" ||
                        filterFns[column?.id] === "empty",
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={
                                filterModel(filterFns, columnFilters)
                                    ?.length === 0
                                    ? [defaultFilter]
                                    : model
                            }
                            page={`system/users/${state?.loyaltyData?.id}/loyalties`}
                            formatter={handleLoyaltyTransactionsExport}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                }}
            />
        </Box>
    )
}

export default LoyaltyTransactions
