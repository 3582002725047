import React, { useEffect } from "react"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"
import { roundToTwoDecimalPlaces } from "../Utils/dataFormat.js"
import HasPermission from "../Utils/access.js"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"

function RetryTransactions(props) {
    const { transactionData, open, setOpenDialog, refetch } = props

    const axiosPrivate = useAxiosPrivate()

    const { enqueueSnackbar } = useSnackbar()

    const {
        mutate: retry,
        data: retryResponse,
        isLoading: retryIsLoading,
        error: retryError,
    } = useMutation((requestData) =>
        axiosPrivate.post(
            `/system/retry/transactions/${requestData?.table_name}/records/${requestData?.id}`
        )
    )

    useEffect(() => {
        if (retryResponse) {
            enqueueSnackbar(`Your request is succesfully sent.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
            setOpenDialog(false)
        }
    }, [retryResponse, enqueueSnackbar, refetch, setOpenDialog])
    useEffect(() => {
        if (retryError) {
            enqueueSnackbar(
                retryError?.response?.data?.error?.message ||
                    retryError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
            setOpenDialog(false)
        }
    }, [retryError, enqueueSnackbar, setOpenDialog])

    return (
        <Dialog maxWidth="xs" open={open} onClose={() => setOpenDialog(false)}>
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                Confirm Retry Transaction Operation
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ mt: 2 }}>
                    Make sure that you are going to retry failed transaction
                    with the following details:
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        ml: 3,
                        mt: 1,
                    }}
                >
                    <Typography>
                        Amount:{" "}
                        {roundToTwoDecimalPlaces(transactionData?.amount)} ETB
                    </Typography>
                    <Typography>Method: {transactionData?.method}</Typography>
                    <Typography>
                        Request:{" "}
                        {transactionData?.table_name
                            ?.replaceAll("_", " ")
                            ?.toUpperCase()}
                    </Typography>
                    <Typography>
                        Type: {transactionData?.type?.replaceAll("_", " ")}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    onClick={() => setOpenDialog(false)}
                    sx={{
                        ":hover": {
                            bgcolor: "#e4e4e4",
                            color: "black",
                        },
                        backgroundColor: "#e4e4e4",
                        color: "black",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                >
                    Cancel
                </Button>
                <Button
                    sx={{
                        bgcolor: "primary.main",
                        color: "secondary.main",
                        ":hover": {
                            bgcolor: "primary.main",
                            color: "secondary.main",
                        },
                        textTransform: "none",
                        width: 120,
                    }}
                    type="submit"
                    onClick={() => retry(transactionData)}
                    disabled={
                        retryIsLoading ||
                        !HasPermission("retry failed transactions")
                    }
                >
                    Retry
                    {retryIsLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#fff",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RetryTransactions
