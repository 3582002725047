import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { Box, Hidden, Slide } from "@mui/material"
import { SnackbarProvider } from "notistack"

import PrivateRoute from "./Component/private_route.jsx"
import { AuthProvider } from "./Context/authProvider.jsx"
import PersistLogin from "./Component/persistLogin.jsx"
import Authorize from "./Component/authorize.js"
import IPIframe from "./Component/ipIframe.jsx"
import Listener from "./Component/listener.jsx"
import SignIn from "./Pages/signIn.jsx"
import Main from "./Container/main.jsx"
import Companies from "./Pages/Companies/companies.jsx"
import Roles from "./Pages/Roles/roles.jsx"
import Users from "./Pages/Users/users.jsx"
import CompanyTransactions from "./Pages/Companies/companyTransactions.jsx"
import CompanyPrograms from "./Pages/Companies/Programs/companyPrograms.jsx"
import RidePlusCredit from "./Pages/Ride Plus Credit/ridePlusCredit.jsx"
import RideCredit from "./Pages/Ride Credit/rideCredit.jsx"
import StreetPickup from "./Pages/Street Pickup/streetPickup.jsx"
import ViewTripDetails from "./Pages/Street Pickup/viewTripDetails.jsx"
import Drivers from "./Pages/Drivers/drivers.jsx"
import CompanyDetails from "./Pages/Companies/companyDetails.jsx"
import DriverDetails from "./Pages/Drivers/driverDetails.jsx"
import FamilyPackage from "./Pages/Family Package/familyPackage.jsx"
import PageNotFound from "./Component/pageNotFound.jsx"
import ChartOfAccounts from "./Pages/COA Setting/chartOfAccounts.jsx"
import { ADMIN_BASE_URL, SSO_BASE_URL } from "./Utils/config.js"
import SubAccounts from "./Pages/COA Setting/subAccounts.jsx"
import CompanySystemUsers from "./Pages/Companies/System Users/companySystemUsers.jsx"
import CompanyInvoices from "./Pages/Companies/Billing/companyInvoices.jsx"
import DriverTransactions from "./Pages/Drivers/driverTransactions.jsx"
import CompanyUsers from "./Pages/Companies/Users/companyUsers.jsx"
import FamilyPackageTransactions from "./Pages/Family Package/familyPackageTransactions.jsx"
import ViewInvoice from "./Pages/Companies/Billing/companyInvoiceDetails.jsx"
import FamilyPackageMembers from "./Pages/Family Package/familyPackageMembers.jsx"
import FamilyPackagePrograms from "./Pages/Family Package/familyPackagePrograms.jsx"
import Notifications from "./Pages/Notifications/notifications.jsx"
import Personals from "./Pages/Personals/personals.jsx"
import PersonalUserDetails from "./Pages/Personals/personalUsersDetails.jsx"
import UserDetails from "./Pages/Users/userDetails.jsx"
import Receipts from "./Pages/Companies/Billing/receipts.jsx"
import TransactionsAlert from "./Pages/Transactions Alert/transactionsAlert.jsx"
import PersonalTransactions from "./Pages/Personals/personalTransactions.jsx"
import IncompletePayments from "./Pages/Drivers/incompletePayments.jsx"
import CompanyProgramDetails from "./Pages/Companies/Programs/companyProgramDetails.jsx"
import Reconcilation from "./Pages/Drivers/reconcilations.jsx"
import CompanyProgramTransactions from "./Pages/Companies/Programs/companyProgramTransactions.jsx"
import CompanyProgramUsers from "./Pages/Companies/Programs/companyProgramUsers.jsx"
import Loyalties from "./Pages/Loyalties/loyalties.jsx"
import Trip from "./Pages/trip/trip.jsx"
import CompanyUserTransactions from "./Pages/Companies/Users/companyUserTransactions.jsx"
import CompanyProgramUserTransactions from "./Pages/Companies/Programs/companyProgramUserTransactions.jsx"
import LoyaltyTransactions from "./Pages/Loyalties/loyaltyTransactions.jsx"
import GenerateTransactionReport from "./Pages/Reports/generateTransactionReport.jsx"
import DownloadReceipt from "./Pages/Receipts/downloadReceipt.jsx"
import TopUpReceipts from "./Pages/Receipts/receipts.jsx"
import DriverReceipts from "./Pages/Drivers/driverReceipts.jsx"
import Packages from "./Pages/E-Float/Packages/packages.jsx"
import TINFilteredReceipts from "./Pages/TIN Filtered Receipts/tinFilteredreceipts.jsx"
import Agents from "./Pages/E-Float/Agents/agents.jsx"
import TaxReport from "./Pages/Drivers/taxReport.jsx"
import AgentTransactions from "./Pages/E-Float/Agents/agentTransactions.jsx"
import EfloatTopUpTransactions from "./Pages/E-Float/efloatTransactions.jsx"
import RedeemPoints from "./Pages/Loyalties/redeemPoints.jsx"
import DynamicMeta from "./DynamicMeta.jsx"
import DownloadCorporateReceipt from "./Pages/Companies/Receipts/downloadCorporateReceipt.jsx"
import CorporateReceipts from "./Pages/Companies/Receipts/coporateReceipts.jsx"

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // Disable refetch when window regains focus
            keepPreviousData: true,
            retry: false,
        },
    },
})

function App() {
    return (
        <Box>
            {/* Single Sign out Iframe */}
            {window.parent === window && (
                <iframe
                    id="ipIframe"
                    src={`${ADMIN_BASE_URL}/IPIFrame`}
                    title="ipIframe"
                    style={{ display: "none" }}
                />
            )}
            {window.parent === window && (
                <iframe
                    id="opIframe"
                    src={`${SSO_BASE_URL}/opIframe`}
                    title="opIframe"
                    style={{ display: "none" }}
                />
            )}
            <QueryClientProvider client={queryClient}>
                <SnackbarProvider
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    TransitionComponent={Slide}
                >
                    <BrowserRouter>
                        <AuthProvider>
                            {window.parent === window && (
                                <Hidden>
                                    <Listener id="listener" />
                                </Hidden>
                            )}
                            <DynamicMeta />
                            <Routes>
                                <Route path="*" element={<PageNotFound />} />
                                <Route path="login" element={<SignIn />} />
                                <Route
                                    path="authorize"
                                    element={<Authorize />}
                                />
                                <Route path="IPIFrame" element={<IPIframe />} />
                                <Route
                                    path="receipts/:receiptId"
                                    element={<DownloadReceipt />}
                                />
                                <Route
                                    path="TIN-filtered-receipts/:receiptId"
                                    element={<DownloadReceipt />}
                                />
                                <Route
                                    path="drivers/:phone/tax"
                                    element={<TaxReport />}
                                />
                                <Route
                                    path="redeemPoints/:phone"
                                    element={<RedeemPoints />}
                                />
                                <Route element={<PersistLogin />}>
                                    <Route
                                        path="/corporates/:corporateName/invoices/:invoiceNumber/details/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadCorporateReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/corporates/:corporateName/corporateReceipts/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadCorporateReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/corporates/:corporateName/receipts/:transactionId"
                                        element={
                                            <PrivateRoute>
                                                <DownloadCorporateReceipt />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path="/"
                                        element={
                                            <PrivateRoute>
                                                <Main />
                                            </PrivateRoute>
                                        }
                                    >
                                        <Route
                                            index
                                            path="/corporates"
                                            element={<Companies />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/users"
                                            element={<CompanySystemUsers />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/peoples"
                                            element={<CompanyUsers />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/peoples/:user_id/transactions"
                                            element={
                                                <CompanyUserTransactions />
                                            }
                                        />
                                        <Route
                                            path="/corporates/:corporateName/transactions"
                                            element={<CompanyTransactions />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/programs"
                                            element={<CompanyPrograms />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/programs/:pId/transactions"
                                            element={
                                                <CompanyProgramTransactions />
                                            }
                                        />
                                        <Route
                                            path="/corporates/:corporateName/programs/:pId/members"
                                            element={<CompanyProgramUsers />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/programs/:pId/members/:uId/transactions"
                                            element={
                                                <CompanyProgramUserTransactions />
                                            }
                                        />
                                        <Route
                                            path="/corporates/:corporateName/programs/:pId/details"
                                            element={<CompanyProgramDetails />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/invoices"
                                            element={<CompanyInvoices />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/invoices/:invoiceNumber/details"
                                            element={<ViewInvoice />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/receipts"
                                            element={<Receipts />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/corporateReceipts"
                                            element={<CorporateReceipts />}
                                        />
                                        <Route
                                            path="/corporates/:corporateName/details"
                                            element={<CompanyDetails />}
                                        />
                                        <Route
                                            path="/drivers"
                                            element={<Drivers />}
                                        />
                                        <Route
                                            path="/drivers/:driverId/transactions"
                                            element={<DriverTransactions />}
                                        />
                                        <Route
                                            path="/drivers/:driverId/incomplete-payments"
                                            element={<IncompletePayments />}
                                        />
                                        <Route
                                            path="/drivers/:driverId/reconcilations"
                                            element={<Reconcilation />}
                                        />
                                        <Route
                                            path="/drivers/:driverId/receipts"
                                            element={<DriverReceipts />}
                                        />
                                        <Route
                                            path="/drivers/:driverId/details"
                                            element={<DriverDetails />}
                                        />
                                        <Route
                                            path="/personals"
                                            element={<Personals />}
                                        />
                                        <Route
                                            path="/personals/:userId/transactions"
                                            element={<PersonalTransactions />}
                                        />
                                        <Route
                                            path="/personals/:userId/details"
                                            element={<PersonalUserDetails />}
                                        />
                                        <Route
                                            path="/roles"
                                            element={<Roles />}
                                        />
                                        <Route
                                            path="/users"
                                            element={<Users />}
                                        />
                                        <Route
                                            path="/users/:userId/details"
                                            element={<UserDetails />}
                                        />
                                        <Route
                                            path="/coa"
                                            element={<ChartOfAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId/:headId/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId/:headId/:headId/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="/coa/:headId/:headId/:headId/:headId/:headId/:headId/:headId"
                                            element={<SubAccounts />}
                                        />
                                        <Route
                                            path="plus-commission"
                                            element={<RidePlusCredit />}
                                        />
                                        <Route
                                            path="efloat-topup-transactions"
                                            element={
                                                <EfloatTopUpTransactions />
                                            }
                                        />
                                        <Route
                                            path="trips"
                                            element={<Trip />}
                                        />
                                        <Route
                                            path="agents"
                                            element={<Agents />}
                                        />
                                        <Route
                                            path="agents/:userId/transactions"
                                            element={<AgentTransactions />}
                                        />
                                        <Route
                                            path="packages"
                                            element={<Packages />}
                                        />
                                        <Route
                                            path="receipts"
                                            element={<TopUpReceipts />}
                                        />
                                        <Route
                                            path="TIN-filtered-receipts"
                                            element={<TINFilteredReceipts />}
                                        />
                                        <Route
                                            path="ride-commission"
                                            element={<RideCredit />}
                                        />
                                        <Route
                                            path="street-pickup"
                                            element={<StreetPickup />}
                                        />
                                        <Route
                                            path="/street-pickup/:tripId/tripDetails"
                                            element={<ViewTripDetails />}
                                        />
                                        <Route
                                            path="family-package"
                                            element={<FamilyPackage />}
                                        />
                                        <Route
                                            path="/family-package/:ownerId/programs"
                                            element={<FamilyPackagePrograms />}
                                        />
                                        <Route
                                            path="/family-package/:ownerId/transactions"
                                            element={
                                                <FamilyPackageTransactions />
                                            }
                                        />
                                        <Route
                                            path="/family-package/:ownerId/members"
                                            element={<FamilyPackageMembers />}
                                        />
                                        <Route
                                            path="/loyalties"
                                            element={<Loyalties />}
                                        />
                                        <Route
                                            path="/loyalties/:loyaltyId/transactions"
                                            element={<LoyaltyTransactions />}
                                        />
                                        <Route
                                            path="/transactions-report"
                                            element={
                                                <GenerateTransactionReport />
                                            }
                                        />
                                        <Route
                                            path="/transactions-alert"
                                            element={<TransactionsAlert />}
                                        />
                                        <Route
                                            path="/notifications"
                                            element={<Notifications />}
                                        />
                                    </Route>
                                </Route>
                            </Routes>
                        </AuthProvider>
                    </BrowserRouter>
                </SnackbarProvider>
            </QueryClientProvider>
        </Box>
    )
}

export default App
