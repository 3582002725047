import { DateTime } from "luxon";

export const handleSystemUsersExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        updated_at: item.updated_at? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
        roles: `${item?.roles?.map(element=> element.role_name)}`,
    }))
export const handleCopmanyInvitedUsersExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        programs: `${item?.programs?.map(element=> element.name)}`,
    }))
const handleCopmanyUsersExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        updated_at: item.updated_at? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
        expire_date: item.expire_date? DateTime.fromISO(item.expire_date).toFormat("ff") : "",
        programs: `${item?.programs?.map(element=> element.name)}`
    }))
export default handleCopmanyUsersExport

