import React from "react"
import { Avatar, Badge, Box, IconButton, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"
import { useDropzone } from "react-dropzone"

function Dropzone({ files, setFiles, errors }) {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles?.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Box sx={{ margin: 2 }} {...getRootProps()}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                        <IconButton onClick={() => {}}>
                            <Add
                                sx={{
                                    backgroundColor: "primary.main",
                                    color: "secondary.main",
                                    borderRadius: "50%",
                                }}
                            />
                        </IconButton>
                    }
                >
                    <Avatar
                        sx={{
                            width: 144,
                            height: 144,
                            borderWidth: 40,
                            border: "black",
                        }}
                        alt="Company Logo"
                        src={files}
                    />
                    <input {...getInputProps()} />
                </Badge>
            </Box>
            <Typography
                        sx={{
                            fontWeight: "bold",
                            fontSize: 14,
                            color: "red"
                        }}
                    >
                       {errors?? ""}
                    </Typography>
            {isDragActive ? (
                <Typography
                    sx={{
                        mt: 1,
                        mb: 2,
                        fontWeight: "bold",
                        fontSize: 12,
                    }}
                >
                    Drop the files here ...
                </Typography>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    {...getRootProps()}
                >
                    <Typography
                        sx={{
                            mt: 1,
                            mb: 2,
                            fontWeight: "bold",
                            fontSize: 12,
                        }}
                    >
                        Click the icon to upload file or drag and drop here
                    </Typography>
                </Box>
            )}
        </Box>
    )
}
export default Dropzone
