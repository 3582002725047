const Request = (method) => {
    switch (method) {
        case "POST":
            return "Added"
        case "PATCH":
            return "Updated"
        case "PUT":
            return "Updated"
        case "DELETE":
            return "Deleted"
        default:
            return ""
    }
}
export const specialRequests = (request) => {
    if (request.includes("invite")) {
        return "Invited a user"
    }
    if (request.includes("assign")) {
        return "Assigned a user to a"
    }
    if (request.includes("confirm") && request.includes("topup")) {
        return "Approved topup"
    }
    if (request.includes("cashout") || request.includes("topup")) {
        return "Made"
    }
    if (request.includes("start")) {
        return "Started"
    }
    if (request.includes("end")) {
        return "Ended"
    }
    if (request.includes("verify")) {
        return "Verify account"
    }
    if (request.includes("payment") && request.includes("confirm")) {
        return "Confirmed Pin"
    }
    if (request.includes("payment") && request.includes("trip")) {
        return "Confirmed Pin"
    }
    if (request.includes("generate") && request.includes("invoice")) {
        return "Generated invoice"
    }
    return ""
}

export default Request