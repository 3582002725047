import { useQuery } from "react-query";
import { axiosPrivate } from "../Utils/Api/axios.js";

function useConfigs() {
    return useQuery(["configs"], () => axiosPrivate
        .get(`/system/configs`, {
            params: {
                per_page: -1,
            },
        }).then((resp) => resp.data))
}
export default useConfigs