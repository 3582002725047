import React from "react"
import { Helmet } from "react-helmet"
import { useLocation, matchPath } from "react-router-dom"
import metaConfig from "./Utils/metaConfig.js"

export default function DynamicMeta() {
    const location = useLocation()

    const meta = Object.keys(metaConfig).find((path) =>
        matchPath({ path, exact: true }, location.pathname)
    )

    const currentMeta = metaConfig[meta] || {}

    return (
        <Helmet>
            <title>{currentMeta.title || "RIDE Plus Admin"}</title>
            <meta
                name="description"
                content={
                    currentMeta.description ||
                    "Admin UI for managing billing and street pickup activities."
                }
            />
        </Helmet>
    )
}
