import React, { useEffect } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormLabel,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { Lightbulb } from "@mui/icons-material"

import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"

import { TimelineDot } from "@mui/lab"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CustomMapContainer from "../../Component/mapContainer.jsx"

// Family Package Transaction Details View Component
function ViewFamilyPackageTransactionDetails({ detail, setDetail }) {
    const axiosPrivate = useAxiosPrivate()
    const { enqueueSnackbar } = useSnackbar()

    const tripDetail = (tripId) => axiosPrivate.get(`/system/trips/${tripId}`)

    const {
        data: tripInfo,
        isLoading,
        error,
    } = useQuery(
        ["trip-detail", detail?.trip_id],
        () => tripDetail(detail?.trip_id),
        {
            enabled: !!detail,
        }
    )

    const startTime = DateTime.fromISO(tripInfo?.data?.data?.start_time)
    const endTime = DateTime.fromISO(tripInfo?.data?.data?.end_time)

    const tripDuration = endTime
        .diff(startTime, ["days", "hours", "minute"])
        .toObject()

    useEffect(() => {
        if (error) {
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
            setDetail(null)
        }
    }, [enqueueSnackbar, error, setDetail])

    return (
        !error && (
            <Dialog
                maxWidth="md"
                onClose={() => {
                    setDetail(null)
                }}
                open={!!detail}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            Trip History
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {isLoading ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "200px",
                                width: "100%",
                            }}
                        >
                            <CircularProgress
                                size={40}
                                sx={{
                                    color: "secondary.main",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1em",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    backgroundColor: "#fff",
                                    px: "2em",
                                    py: "1em",
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1.5,
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "3em",
                                        paddingTop: "1.5em",
                                        paddingBottom: "1.5em",
                                    }}
                                >
                                    <Box sx={{ flex: 1 }}>
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Trip time
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {(() => {
                                                const hours = Math.floor(
                                                    tripDuration.hours
                                                )
                                                const mins = Math.floor(
                                                    tripDuration.minutes
                                                )

                                                const minsRounded =
                                                    Math.round(mins)

                                                return hours > 0
                                                    ? ` ${hours} hr ${minsRounded} minutes`
                                                    : `${minsRounded} minutes`
                                            })()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Amount
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {(() => {
                                                const amount =
                                                    tripInfo?.data?.data?.cost
                                                const rounded =
                                                    Math.round(amount * 10) / 10

                                                return rounded
                                                    ? `${rounded} ETB`
                                                    : "No Data"
                                            })()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Program
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {detail?.program_name ?? ""}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ flex: 1 }}>
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Total KM
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {(() => {
                                                const distance =
                                                    tripInfo?.data?.data
                                                        ?.distance
                                                if (distance) {
                                                    const dKm = distance / 1000
                                                    const rounded =
                                                        Math.round(dKm * 100) /
                                                        100
                                                    return rounded
                                                }
                                                return "No Data"
                                            })()}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Timeline sx={{ flex: 3, gap: "15px" }}>
                                    <TimelineItem
                                        sx={{
                                            "&.MuiTimelineItem-root": {
                                                ":before": {
                                                    padding: "0px, 0px",
                                                    flex: "none",
                                                    "&:-webkit-flex": "none",
                                                },
                                            },
                                        }}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: "1px solid #08C139",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            "#08C139",
                                                        width: "14px",
                                                        height: "14px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </TimelineDot>
                                            <TimelineConnector
                                                sx={{
                                                    backgroundColor: "#08C139",
                                                    mt: -1.5,
                                                    mb: -3.5,
                                                }}
                                            />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {DateTime.fromISO(
                                                        startTime
                                                    ).toFormat("ff")}
                                                </Typography>
                                                <FormLabel
                                                    sx={{ fontSize: 13 }}
                                                >
                                                    {
                                                        tripInfo?.data?.data?.start_point_name?.split(
                                                            "/"
                                                        )[0]
                                                    }
                                                </FormLabel>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem
                                        sx={{
                                            "&.MuiTimelineItem-root": {
                                                ":before": {
                                                    padding: "0px, 0px",
                                                    flex: "none",
                                                    "&:-webkit-flex": "none",
                                                },
                                            },
                                        }}
                                    >
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: "1px solid #FFDC00",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            "primary",
                                                        width: "14px",
                                                        height: "14px",
                                                    }}
                                                >
                                                    <Lightbulb
                                                        fontSize="inherit"
                                                        color="primary"
                                                    />
                                                </Box>
                                            </TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {DateTime.fromISO(
                                                        endTime
                                                    ).toFormat("ff")}
                                                </Typography>
                                                <FormLabel
                                                    sx={{ fontSize: 13 }}
                                                >
                                                    {
                                                        tripInfo?.data?.data?.end_point_name.split(
                                                            "/"
                                                        )[0]
                                                    }
                                                </FormLabel>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    height: "200px",
                                }}
                            >
                                <CustomMapContainer
                                    polyline={
                                        tripInfo?.data?.data?.poly_line === ""
                                            ? "????"
                                            : tripInfo?.data?.data?.poly_line
                                    }
                                    startingPoint={
                                        tripInfo?.data?.data?.start_point_name?.split(
                                            "/"
                                        )[0]
                                    }
                                    endPoint={
                                        tripInfo?.data?.data?.end_point_name?.split(
                                            "/"
                                        )[0]
                                    }
                                />
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        mt: 1,
                        pr: 3,
                        py: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        bgcolor: "#fafafa",
                    }}
                >
                    <Button
                        onClick={() => {
                            setDetail(null)
                        }}
                        variant="contained"
                        sx={{
                            ":hover": { bgcolor: "#e4e4e4" },
                            backgroundColor: "#e4e4e4",
                            textTransform: "none",
                            fontWeight: "bold",
                            width: 120,
                        }}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        )
    )
}

export default ViewFamilyPackageTransactionDetails
