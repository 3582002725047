/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, Tooltip } from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { Refresh } from "@mui/icons-material"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"
import RetryTransactions from "../../Component/retryTransactions.jsx"

// Transactions Alert List Component
function TransactionsAlert() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        user_id: "contains",
        full_name: "contains",
        driver_phone: "contains",
        amount: "=",
        method: "contains",
        type: "is",
        table_name: "is",
        approved_by: "contains",
        note: "contains",
        reason_failed: "contains",
        updated_at: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [columnVisibility, setColumnVisibility] = useState({
        user_id: false,
        approved_by: false,
        reason_failed: false,
    })
    const [openDialog, setOpenDialog] = useState(false)
    const transactionRef = useRef()

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id,
                sort: item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "transaction_alerts",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/transactions/failed`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters)
                    ),
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get all failed status"),
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            refetchOnMount: true,
        }
    )

    // HELPERS and EVENT HANDLERS
    const handleClick = (transaction) => {
        setOpenDialog(true)
        transactionRef.current = transaction
    }
    const getRequestType = useCallback(
        ({ row }) => (
            <Box>
                {row?.original?.table_name !== "" && (
                    <Button
                        key={row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "#fbcf3b",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "#fbcf3b",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {row?.original?.table_name
                            ?.replaceAll("_", " ")
                            ?.toUpperCase()}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getType = useCallback(
        ({ row }) => (
            <Box>
                {row?.original?.type !== "" && (
                    <Button
                        key={row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "black",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {row?.original?.type
                            ?.replaceAll("_", " ")
                            ?.toUpperCase()}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <Tooltip title="Retry Transaction" arrow>
                    <Button
                        disabled={!HasPermission("retry failed transactions")}
                        sx={{ minWidth: 24, p: 0 }}
                        onClick={() => {
                            handleClick(row?.original)
                        }}
                    >
                        <Refresh />
                    </Button>
                </Tooltip>
                {openDialog && (
                    <RetryTransactions
                        open={openDialog}
                        setOpenDialog={setOpenDialog}
                        transactionData={transactionRef.current}
                        refetch={refetch}
                    />
                )}
            </Box>
        ),
        [openDialog, refetch]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "user_id",
                header: "Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "full_name",
                header: "Driver Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "driver_phone",
                header: "Driver Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "first_name",
                header: "User Name",
                Cell: ({ row }) =>
                    `${row?.original?.first_name} ${row?.original?.middle_name} ${row?.original?.last_name}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "user_phone",
                header: "User Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "amount",
                header: "Amount (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "method",
                header: "Method",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 330,
                accessorKey: "table_name",
                header: "Request",
                filterVariant: "select",
                filterSelectOptions: [
                    "ride_plus_credit",
                    "topups",
                    "incomplete_credit_requests",
                    "corporate_top_up",
                ],
                Cell: getRequestType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "type",
                header: "Type",
                filterVariant: "select",
                filterSelectOptions: ["RIDE_CREDIT", "RIDE_PLUS_CREDIT"],
                Cell: getType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "approved_by",
                header: "Approved By",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "note",
                header: "Note",
                enableSorting: false,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "reason_failed",
                header: "Failure Reason",
                enableSorting: false,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getRequestType, getType]
    )

    // RENDER
    if (!HasPermission("get all failed status")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    onColumnVisibilityChange={setColumnVisibility}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/transactions/failed"
                                formatter={handleDateExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                        columnVisibility,
                    }}
                />
            </Box>
        </Box>
    )
}

export default TransactionsAlert
