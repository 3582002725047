import React from "react"
import { ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material"
import { NavLink, useLocation } from "react-router-dom"

function Link({ to, icon, label, open, sx, handleSelect }) {
    const location = useLocation()

    return (
        <ListItem
            component={NavLink}
            to={to}
            button
            style={{
                color: location.pathname === to ? "#FFAA00" : "",
            }}
            sx={sx}
            onClick={handleSelect}
        >
            <ListItemIcon sx={{ color: "inherit" }}>
                <Tooltip
                    title={label}
                    arrow
                    placement={open ? "bottom" : "right"}
                >
                    {icon}
                </Tooltip>
            </ListItemIcon>
            <ListItemText sx={{ color: "inherit" }} primary={label} />
        </ListItem>
    )
}
export default Link
