import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Typography,
} from "@mui/material"
import React, { useState } from "react"
import { CSVLink } from "react-csv"
import useBreakPoints from "../../../Hooks/useBreakPoints.js"
import CSVUploader from "../../../Component/csvUploader.jsx"
import FullTextSearch from "../../../Component/fullTextSearch.jsx"
import AddBulkAgent from "./addBulkAgent.jsx"
import AddSingleAgent from "./assignSingleAgent.jsx"

function AddAgentCode({ open, setOpen }) {
    const [file, setFile] = useState(null)
    const [search, setSearch] = useState(null)

    const { xs, md } = useBreakPoints()

    const handleClose = () => {
        setSearch(null)
        setFile(null)
        setOpen(false)
    }

    const handleCsv = (data) => {
        setFile(data)
    }

    const handleChange = (val) => setSearch(val)

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            onClose={handleClose}
            open={open}
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Add Agent Code
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ p: "1em" }}>
                    {!file && (
                        <Box sx={{ mb: "1em" }}>
                            <FullTextSearch onChange={handleChange} />
                        </Box>
                    )}
                    {search ? (
                        <AddSingleAgent search={search} close={handleClose} />
                    ) : null}
                    {file && (
                        <AddBulkAgent
                            file={file}
                            setFile={setFile}
                            close={handleClose}
                        />
                    )}
                    {!!search ||
                        (!file && (
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "1em",
                                    }}
                                >
                                    <CSVUploader onChange={handleCsv} />
                                    <Button
                                        sx={{
                                            textDecoration: "underline",
                                            textTransform: "none",
                                            color: "secondary.main",
                                            mt: "1em",
                                            px: "1em",
                                            width: 240,
                                            fontSize: 16,
                                        }}
                                    >
                                        <CSVLink
                                            data={[
                                                {
                                                    phone: "251910222324",
                                                    short_code: "0011223344",
                                                },
                                                {
                                                    phone: "251985764312",
                                                    short_code: "5566778899",
                                                },
                                            ]}
                                            style={{
                                                textDecoration: "inherit",
                                                color: "#000000",
                                            }}
                                            filename="bulk add csv sample format.csv"
                                        >
                                            DownLoad Sample CSV
                                        </CSVLink>
                                    </Button>
                                </Box>
                                <Divider sx={{ my: "1em" }} />
                            </Box>
                        ))}
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    px: 4,
                    py: 2,
                    bgcolor: "#fafafa",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleClose}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddAgentCode
