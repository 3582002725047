const isPhone = (phone1, phone2) => {
    // accepts string of phones
    if (phone1 && phone2 && phone1[0] === phone2[0]) return phone1 === phone2

    const p1 = phone1[0] === "2"

    if (p1) return 0 + phone1.slice(3, 12) === phone2
    if (phone2[0] === "2") return phone1 === 0 + phone2.slice(3, 12);
    if (phone2[0] === "9") return phone1 === 0 + phone2.slice(1, 9);
    if (phone2[0] === "0") return phone1 === phone2;

    throw new Error("Invalid Phone")
}

export default isPhone
