import axios from '../Utils/Api/axios.js';
import useAuth from './useAuth.js';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.post('/auth/token', {
            refresh_token: window.localStorage.getItem("refresh token"),
            grant_type: "refresh_token"
        });

        if (response) {
            window.localStorage.setItem("refresh token", response?.data?.data?.refresh_token)
        }

        setAuth(prev => {
            // eslint-disable-next-line no-param-reassign
            prev = response.data.data;
            return prev;
        });

        return response.data.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;
