import React, { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import { useMutation, useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import HasPermission from "../../Utils/access.js"

// Edit/Update Roles Component
function EditRoles({ handleEditModalClose, roleData, refetch, update }) {
    // USESTATE
    const [selectedPermissions, setSelecetdPermissions] = useState([])
    const [expanded, setExpanded] = useState(false)
    const [selectedAll, setSelectedAll] = useState(false)
    const permissionRef = useRef()

    // HOOK FORM
    const { control, handleSubmit, register, setValue, watch } = useForm({
        mode: "onChange",
    })

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERIES $ MUTATIONS
    const { data: permissions, isLoading: permissionsIsLoading } = useQuery(
        ["edit_system_permissions"],
        () =>
            axiosPrivate.get(`/system/permissions`, {
                params: {
                    per_page: -1,
                },
            }),
        {
            enabled: HasPermission("get all permissions"),
        }
    )
    const { data: role, isLoading: roleIsLoading } = useQuery(
        ["edit_role"],
        () =>
            axiosPrivate.get(`/system/roles/${roleData?.id}`, {
                params: {
                    per_page: -1,
                },
            })
    )
    const {
        mutate,
        isLoading,
        data: roles,
        error,
    } = useMutation((formData) =>
        axiosPrivate.put(`/system/roles/${roleData?.id}`, formData)
    )

    // DATA STRUCTURE for Permissions Category By Name
    const permissionCategories = permissions?.data?.data?.map(
        (element) => element.statement.resource.split(":")[1]
    )
    const uniquePermissionCategories = [...new Set(permissionCategories)]

    permissionRef.current = watch("permissions_id")

    // HELPERS & EVENT HANDLERS
    const onSubmit = (formData) => {
        mutate(formData)
    }
    const handleSelectAll = (checked) => {
        const allPermissions = permissions?.data?.data?.map((item) => item.id)
        if (checked) setSelecetdPermissions(allPermissions)
        if (!checked) setSelecetdPermissions([])
    }
    const handleSelect = (checked, category) => {
        const selectedPerm = permissions?.data?.data?.filter(
            (element) => element.statement.resource.split(":")[1] === category
        )
        const perm = selectedPerm?.map((item) => item.id)
        const unchecked = selectedPermissions?.filter(
            (value) => !perm.includes(value)
        )
        const permIntersect = Array.from(
            new Set([...selectedPermissions, ...perm])
        )
        if (checked) setSelecetdPermissions(permIntersect)
        if (!checked) setSelecetdPermissions(unchecked)
    }
    const handleItemSelect = (checked, permissionId) => {
        const unchecked = selectedPermissions?.filter(
            (value) => value !== permissionId
        )

        if (checked)
            setSelecetdPermissions(
                Array.from(new Set([...selectedPermissions, permissionId]))
            )
        if (!checked) setSelecetdPermissions(unchecked)
    }
    const viewSelectedCategory = (category) => {
        const categoryItems = permissions?.data?.data
            ?.filter(
                (permission) =>
                    permission?.statement?.resource.split(":")[1] === category
            )
            .map((item) => item.id)
        const selectedCategoryItems = permissions?.data?.data?.filter(
            (permission) => selectedPermissions?.includes(permission.id)
        )
        const selected = selectedCategoryItems
            ?.filter(
                (permission) =>
                    permission?.statement?.resource.split(":")[1] === category
            )
            .map((item) => item.id)

        return (
            selected?.length === categoryItems.length &&
            selected.every((value, index) => value === categoryItems[index])
        )
    }

    // USEFFECTS
    useEffect(() => {
        if (selectedPermissions?.length === permissions?.data?.data?.length) {
            setSelectedAll(true)
        } else setSelectedAll(false)
    }, [permissions?.data?.data?.length, selectedPermissions?.length])

    useEffect(() => {
        setValue("name", role?.data?.data?.name)
        setSelecetdPermissions(
            permissions?.data?.data
                ?.filter((item) =>
                    role?.data?.data?.permissions?.includes(item.name)
                )
                ?.map((element) => element.id)
        )
    }, [setValue, role, permissions?.data?.data])

    useEffect(() => {
        setValue("permissions_id", selectedPermissions)
    }, [setValue, selectedPermissions])

    useEffect(() => {
        if (roles) {
            enqueueSnackbar(`Succesfully updated.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleEditModalClose()
            refetch()
        }
    }, [roles, enqueueSnackbar, handleEditModalClose, refetch])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])

    // RENDER
    return (
        <Dialog
            maxWidth="md"
            open={update}
            onClose={handleEditModalClose}
            scroll="paper"
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        Edit Role
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                {permissionsIsLoading || roleIsLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            minHeight: "200px",
                            minWidth: "250px",
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "black",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            mx: 2,
                            mt: 2,
                        }}
                    >
                        <Box>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Name"
                                        autoComplete="name"
                                        fullWidth
                                        size="small"
                                        disabled
                                        sx={{
                                            mb: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                            <Divider />
                        </Box>
                        <Box
                            sx={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox
                                    checked={selectedAll}
                                    onChange={(e) =>
                                        handleSelectAll(e.target.checked)
                                    }
                                    sx={{
                                        "&.Mui-checked": {
                                            color: "#fbcf3b",
                                        },
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}
                                >
                                    Select All
                                </Typography>
                            </Box>
                            <FormControl>
                                {uniquePermissionCategories.map((category) => (
                                    <FormGroup
                                        key={
                                            category === undefined
                                                ? "Uncategorised"
                                                : category
                                        }
                                        sx={{
                                            mt: 1,
                                        }}
                                    >
                                        <Accordion
                                            expanded={
                                                expanded === category
                                            }
                                        >
                                            <AccordionSummary
                                                expandIcon={
                                                    <IconButton
                                                        onClick={() => {
                                                            if (
                                                                expanded ===
                                                                category
                                                            ) {
                                                                setExpanded(false)
                                                            } else {
                                                                setExpanded(
                                                                    category
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <GridExpandMoreIcon color="black" />
                                                    </IconButton>
                                                }
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={viewSelectedCategory(
                                                            category
                                                        )}
                                                        onChange={(e) =>
                                                            handleSelect(
                                                                e.target
                                                                    .checked,
                                                                category
                                                            )
                                                        }
                                                        sx={{
                                                            "&.Mui-checked": {
                                                                color: "#fbcf3b",
                                                            },
                                                        }}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            fontSize: 18,
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {category === undefined
                                                            ? "UNCATEGORISED"
                                                            : category.toUpperCase()}
                                                    </Typography>
                                                </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box
                                                    sx={{
                                                        display: "inline-block",
                                                        ml: 4,
                                                    }}
                                                >
                                                    {permissions?.data?.data?.map(
                                                        (item) =>
                                                            item.statement.resource.split(
                                                                ":"
                                                            )[1] ===
                                                                category && (
                                                                <Box
                                                                    component="span"
                                                                    sx={{
                                                                        visibility:
                                                                            "visible",
                                                                        mt: 2,
                                                                        mx: 1,
                                                                        p: 0.7,
                                                                        bgcolor:
                                                                            "grey.100",
                                                                        color: "grey.800",
                                                                        border: "1px solid",
                                                                        borderColor:
                                                                            "grey.300",
                                                                        borderRadius: 2,
                                                                        fontSize: 14,
                                                                        fontWeight:
                                                                            "700",
                                                                    }}
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            item.description
                                                                        }
                                                                        arrow
                                                                    >
                                                                        <FormControlLabel
                                                                            key={
                                                                                item.id
                                                                            }
                                                                            value={
                                                                                item.id
                                                                            }
                                                                            control={
                                                                                <Checkbox
                                                                                    key={
                                                                                        item.id
                                                                                    }
                                                                                    checked={
                                                                                        permissionRef.current
                                                                                            ? permissionRef.current?.includes(
                                                                                                  item.id
                                                                                              )
                                                                                            : false
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        handleItemSelect(
                                                                                            e
                                                                                                .target
                                                                                                .checked,
                                                                                            item.id
                                                                                        )
                                                                                    }
                                                                                    sx={{
                                                                                        "&.Mui-checked":
                                                                                            {
                                                                                                color: "#fbcf3b",
                                                                                            },
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label={
                                                                                item.name
                                                                            }
                                                                            name={`${item.id}`}
                                                                            {...register(
                                                                                "permissions_id"
                                                                            )}
                                                                        />
                                                                    </Tooltip>
                                                                </Box>
                                                            )
                                                    )}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </FormGroup>
                                ))}
                            </FormControl>
                        </Box>
                    </Box>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleEditModalClose}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "#fdbc3d",
                        ":hover": { bgcolor: "#fdbc3d" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                        permissionsIsLoading || roleIsLoading || isLoading
                    }
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Update
                    </Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "#0a0a0a",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default EditRoles
