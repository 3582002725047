/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import {
    Alert,
    Avatar,
    Box,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { DateTime } from "luxon"
import React, { useState, useMemo, useCallback } from "react"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { Link, useLocation } from "react-router-dom"
import {
    AttachMoney,
    HighlightOff,
    Refresh,
    TaskAlt,
} from "@mui/icons-material"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import filterModel from "../../../Utils/filterModel.js"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import CustomExportOptions from "../../../Component/customExport.jsx"
import CustomeTabs from "../../../Component/customTabs.jsx"
import HasPermission from "../../../Utils/access.js"
import handleCopmanyUsersExport, {
    handleCopmanyInvitedUsersExport,
} from "../../../Utils/File Export Formatter/companyUsersExport.js"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"

function CompanyUsers() {
    const { state } = useLocation()
    // USESTATE
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        phone: "contains",
        programs: "contains",
        created_at: "is",
        monthly_spent_amount: "is",
        total_spent_amount: "is",
        monthly_trips_used: "is",
        total_trips_used: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [isGuest, setIsGuest] = useState(false)

    const tabs = [
        { value: "members", label: "Members" },
        { value: "guest", label: "Guest" },
        { value: "invited", label: "Invited" },
    ]

    // FILTER MODEL WITH SELECTED TAB
    const model = filterModel(filterFns, columnFilters)
    let filterModelWithTab

    if (isGuest !== "invited") {
        filterModelWithTab = [
            ...model,
            {
                column_field: "guest",
                operator_value: "is",
                value: isGuest ? "true" : "false",
            },
        ]
    } else {
        filterModelWithTab = model
    }
    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY & MUTATION
    const {
        isFetching,
        data,
        refetch: refetchMembers,
    } = useQuery(
        [
            "company_users",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
            filterModelWithTab,
        ],
        () =>
            axiosPrivate.get(`/system/corporates/${state?.corporateId}/users`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(filterModelWithTab ?? undefined),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled:
                Boolean(isGuest !== "invited") &&
                HasPermission("list company users"),
        }
    )
    const {
        isFetching: invitedIsFetching,
        data: invitedUsers,
        refetch: refetchInvitedUsers,
    } = useQuery(
        [
            "company_invited_users",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `/system/corporates/${state?.corporateId}/users/invite`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(
                            filterModel(filterFns, columnFilters) ?? undefined
                        ),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (invitedError) =>
                enqueueSnackbar(
                    invitedError?.response?.data?.error?.message ||
                        invitedError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled:
                Boolean(isGuest === "invited") &&
                HasPermission("list corporate program invited users"),
        }
    )

    // EVENT HANDLERS & HELPERS
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={cell?.row?.original?.profile_picture}
            />
        ),
        []
    )
    const getStatus = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    width: "130px",
                }}
            >
                {row?.original?.status !== "" && (
                    <Alert
                        severity="info"
                        icon={false}
                        sx={{
                            justifyContent: "center",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            backgroundColor:
                                row?.original?.status === "ACTIVE"
                                    ? "#e5ffea"
                                    : "#ffd6d6",
                            color:
                                row?.original?.status === "ACTIVE"
                                    ? "#33ca51"
                                    : "#f86049b5",
                            fontWeight: "bold",
                        }}
                    >
                        {row?.original?.status?.toLowerCase()}
                    </Alert>
                )}
            </Box>
        ),
        []
    )
    const viewProgram = useCallback(
        ({ cell }) => (
            <Box>
                {cell.getValue()?.map((program) => (
                    <Box
                        sx={{
                            display: "inline-block",
                            justifyContent: "start",
                            alignItems: "flex-start",
                            fontWeight: "bold",
                            overflow: "hidden",
                            m: ".05em",
                            backgroundColor: program?.user_program_status
                                ? "success.main"
                                : "error.main",
                            color: "white",
                            borderRadius: "20px",
                            pr: "1em",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    color: "white",
                                }}
                            >
                                {program?.user_program_status ? (
                                    <TaskAlt fontSize=".1em" />
                                ) : (
                                    <HighlightOff fontSize=".1em" />
                                )}
                            </IconButton>
                            <Typography variant="subtitle2" noWrap>
                                {program?.name}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />
    const action = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    pr: ".5em",
                }}
            >
                <Link
                    to={`${row.original.user_id}/transactions`}
                    state={row.original}
                >
                    <Tooltip title="Transactions">
                        <IconButton size="small" sx={{ color: "primary.main" }}>
                            <AttachMoney />
                        </IconButton>
                    </Tooltip>
                </Link>
            </Box>
        ),
        []
    )

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "profile_picture",
                header: "Photo",
                enableSorting: false,
                enableColumnFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "middle_name",
                header: "Middle Name",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "last_name",
                header: "Last Name",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "phone",
                header: "Phone",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "monthly_spent_amount",
                header: "Monthly Spent Amount",
                size: 220,
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "total_spent_amount",
                header: "Total Spent Amount",
                size: 220,
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "monthly_trips_used",
                header: "Monthly Trips Used",
                size: 220,
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "total_trips_used",
                header: "Total Trips Used",
                size: 220,
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "programs",
                header: "Programs",
                size: 300,
                filterVariant: "json",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: viewProgram,
            },
            {
                accessorKey: "created_at",
                header: "Created At",
                size: 200,
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    `${DateTime.fromISO(cell.getValue()).toFormat("ff")}`,
                Filter: datePicker,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 200,
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: action,
            },
        ],
        [action, getAvatar, viewProgram, getStatus]
    )

    const handleTabChange = (selectedTab) =>
        selectedTab === "invited"
            ? setIsGuest("invited")
            : setIsGuest(selectedTab === "guest")

    return (
        <Box>
            <Box sx={{ backgroundColor: "white" }}>
                <MaterialReactTable
                    columns={columns}
                    data={
                        isGuest === "invited"
                            ? invitedUsers?.data?.data || []
                            : data?.data?.data || []
                    }
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select", "profile_picture"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <CustomeTabs tabs={tabs} onChange={handleTabChange} />
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton
                                    onClick={() => {
                                        if (isGuest === "invited") {
                                            refetchInvitedUsers()
                                        } else refetchMembers()
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={
                                    isGuest === "invited"
                                        ? filterModel(filterFns, columnFilters)
                                        : filterModelWithTab
                                }
                                page={
                                    isGuest === "invited"
                                        ? `/system/corporates/${state?.corporateId}/users/invite`
                                        : `/system/corporates/${state?.corporateId}/users`
                                }
                                formatter={
                                    isGuest === "invited"
                                        ? handleCopmanyInvitedUsersExport
                                        : handleCopmanyUsersExport
                                }
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={
                        (invitedUsers?.data?.meta_data?.total ||
                            data?.data?.meta_data?.total) ??
                        0
                    }
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        invitedIsFetching,
                        pagination,
                        showSkeletons: isFetching || invitedIsFetching,
                        sorting,
                        columnVisibility: {
                            profile_picture: isGuest !== "invited",
                            first_name: isGuest !== "invited",
                            middle_name: isGuest !== "invited",
                            last_name: isGuest !== "invited",
                            credit_used: isGuest !== "invited",
                            monthly_spent_amount: isGuest !== "invited",
                            total_spent_amount: isGuest !== "invited",
                            monthly_trips_used: isGuest !== "invited",
                            total_trips_used: isGuest !== "invited",
                            status: isGuest !== "invited",
                            action: isGuest !== "invited",
                        },
                    }}
                />
            </Box>
        </Box>
    )
}

export default CompanyUsers
