import React, { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import useRefreshToken from "../Hooks/useRefreshToken.js"
import useAuth from "../Hooks/useAuth.js"
import ErrorPage from "./errorPage.jsx"

function PersistLogin() {
    const [isLoading, setIsLoading] = useState(true)
    const [isMounted, setIsMounted] = useState(true)
    const refresh = useRefreshToken()
    const { auth, persist } = useAuth()

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                <ErrorPage error={err} />
            } finally {
                if (isMounted) setIsLoading(false)
            }
        }

        if (auth?.access_token === undefined && persist) {
            verifyRefreshToken()
        } else setIsLoading(false)

        return () => setIsMounted(false)
    }, [auth?.access_token, isMounted, persist, refresh])

    return !persist || !isLoading ? (
        <Outlet />
    ) : (
        isLoading && (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                    minHeight: "40vh",
                }}
            >
                <CircularProgress
                    size={50}
                    sx={{
                        color: "secondary.main",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            </Box>
        )
    )
}

export default PersistLogin
