import React from "react";
import { Card, CardMedia, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import unauthorized from '../Assets/access_denied.png'

// Unathorized Page Component
function Unauthorized() {
    return (
        <Grid
            container
            sx={{
                minHeight: "80vh",
                justifyContent: "center",
                alignItems: "center",
                bgcolor: "#fff"
            }}
        >
            <Container component="main" maxWidth="md" >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Card elevation={0} sx={{ width: "60%", }}>
                        <CardMedia
                            component="img"
                            image={unauthorized}
                            alt="page not found"
                        />
                    </Card>
                </Box>
            </Container>
        </Grid>
    )
}
export default Unauthorized;