/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import { MoreVert, Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"

// List Personals Component
function Personals() {
    const navigate = useNavigate()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        enmail: "contains",
        phone: "contains",
        email: "contains",
        gender: "contains",
        balance: "=",
        created_at: "is",
        updated_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [user, setUser] = useState({})

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "personals",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/personnel`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.profile_picture}`}
            />
        ),
        []
    )
    const getStatus = useCallback(
        ({ row }) => (
            <Box>
                {(row?.original?.status !== "" ||
                    row?.original?.status !== undefined) && (
                    <Button
                        key={row?.original?.id}
                        disableElevation
                        variant="contained"
                        sx={{
                            borderRadius: "10px",
                            fontWeight: "bold",
                            backgroundColor:
                                row?.original?.status === "ACTIVE"
                                    ? "#E5FFEA"
                                    : "#ffd6d6",
                            color:
                                row?.original?.status === "ACTIVE"
                                    ? "#16C138"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    row?.original?.status === "ACTIVE"
                                        ? "#E5FFEA"
                                        : "#ffd6d6",
                                color:
                                    row?.original?.status === "ACTIVE"
                                        ? "#16C138"
                                        : "red",
                            },
                        }}
                    >
                        {row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const actions = useCallback(
        ({ cell }) => (
            <Box sx={{ flex: 1 }}>
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setUser(cell?.row?.original)
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    key={cell?.row?.original.id}
                    id={cell?.row?.original.id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        key={cell?.row?.original.id}
                        onClick={() => {
                            navigate(`${user?.id}/transactions`, {
                                state: { userData: user },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Transactions
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("get user activities")}
                        onClick={() => {
                            navigate(`${user?.id}/details`, {
                                state: { userData: user },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Details
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [anchorEl, user, navigate]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "profile_picture",
                header: "Photo",
                enableSorting: false,
                enableColumnFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 220,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "middle_name",
                header: "Middle Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "last_name",
                header: "Last Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "gender",
                header: "Gender",
                filterVariant: "select",
                filterSelectOptions: ["Male", "Female"],
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "balance",
                header: "Balance (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getAvatar, getStatus]
    )
    // RENDER
    if (!HasPermission("list all users")) {
        return <Unauthorized />
    }
    return (
        <Box sx={{ height: "100%" }}>
            <MaterialReactTable
                columns={columns}
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select", "profile_picture"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={filterModel(filterFns, columnFilters)}
                            page="/system/personnel"
                            formatter={handleDateExport}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                }}
            />
        </Box>
    )
}

export default Personals
