import { Search } from "@mui/icons-material"
import { TextField } from "@mui/material"
import React, { useEffect, useState } from "react"

function FullTextSearch({ onChange }) {
    const [search, setSearch] = useState(null)

    const handleChange = (e) => {
        const val = e.target.value
        setSearch(val)
    }

    useEffect(() => {
        if (search?.length === 10) {
            setTimeout(() => onChange(search), 100)
        }

        return () => setSearch(null)
    }, [search, onChange])

    return (
        <TextField
            fullWidth
            size="small"
            variant="outlined"
            placeholder="0911223344"
            onChange={handleChange}
            InputProps={{
                endAdornment: <Search sx={{ color: "darkorange" }} />,
            }}
            sx={{
                borderRadius: "5px",
                mt: "1em",
                backgroundColor: "white",
            }}
            label="Driver's Phone"
        />
    )
}

export default FullTextSearch
