import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import useAxiosPrivate from "./useAxiosPrivate.js";

const useUpdateEntity = (path, successMessage) => {
    const axiosPrivate = useAxiosPrivate()
    const { enqueueSnackbar } = useSnackbar();

    return useMutation((formData) => axiosPrivate.patch(`${path}/${formData?.id}`, formData), {
        onSuccess: () =>
            enqueueSnackbar(`${successMessage}`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            }),
        onError: (error) =>
            enqueueSnackbar(
                error?.response?.data?.message ||
                error?.message || error ||
                "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            ),
    }
    );
};

export default useUpdateEntity;
