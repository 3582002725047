import React from "react"
import { TextField } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { merge } from "lodash"

function CustomeDatePicker(props) {
    const {
        InputAdornmentProps,
        InputProps: userInputProps,
        value,
        label,
        date1,
        date2,
        onChange,
        ...otherUserProps
    } = props
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
                disableMaskedInput
                label={label}
                value={value}
                disableFuture
                minDate={date2 ? new Date(date2) || new Date() : undefined}
                maxDate={date1 ? new Date(date1) || new Date() : undefined}
                onChange={() => true}
                {...InputAdornmentProps}
                onAccept={(newValue) => {
                    onChange(newValue?.toString())
                }}
                renderInput={({ InputProps, ...rest }) => (
                    <TextField
                        InputProps={merge(InputProps, userInputProps)}
                        {...otherUserProps}
                        {...rest}
                    />
                )}
            />
        </LocalizationProvider>
    )
}

export default CustomeDatePicker
