/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useMemo, useState } from "react"
import { DateTime } from "luxon"
import { useLocation } from "react-router-dom"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Refresh } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import { useQuery } from "react-query"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import Unauthorized from "../../../Component/unauthorized.jsx"
import HasPermission from "../../../Utils/access.js"
import filterModel from "../../../Utils/filterModel.js"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import CustomExportOptions from "../../../Component/customExport.jsx"

const transactions = [
    {
        accessorKey: "created_at",
        header: "Date",
        size: 200,
        filterVariant: "date",
        enableSorting: false,
        renderColumnFilterModeMenuItems: customFilterPanel,
        Cell: ({ cell }) => DateTime.fromISO(cell.getValue()).toFormat("ff"),
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        size: 230,
        accessorKey: "pickup_name",
        header: "From",
        Cell: ({ row }) => row?.original?.detail?.pickup_name ?? "",
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        size: 220,
        accessorKey: "dropoff_name",
        header: "To",
        Cell: ({ row }) => row?.original?.detail?.dropoff_name ?? "",
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        size: 220,
        accessorKey: "pickup_time",
        header: "Start Time",
        filterVariant: "date",
        Cell: ({ row }) =>
            row?.original?.detail?.pickup_time
                ? DateTime.fromISO(
                      row?.original?.detail?.pickup_time
                  ).toLocaleString(DateTime.DATETIME_MED)
                : "",
        renderColumnFilterModeMenuItems: customFilterPanel,
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        size: 220,
        accessorKey: "dropoff_time",
        header: "End Time",
        filterVariant: "date",
        Cell: ({ row }) =>
            row?.original?.detail?.dropoff_time
                ? DateTime.fromISO(
                      row?.original?.detail?.dropoff_time
                  ).toLocaleString(DateTime.DATETIME_MED)
                : "",
        renderColumnFilterModeMenuItems: customFilterPanel,
        Filter: (props) => <CustomeDateFilter {...props} />,
    },
    {
        accessorKey: "driver_name",
        header: "Driver Name",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => row.original?.detail?.driver_name ?? "",
    },
    {
        accessorKey: "driver_phone",
        header: "Driver Phone",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => row.original?.detail?.driver_phone ?? "",
    },
    {
        accessorKey: "amount",
        header: "Amount",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => row.original?.detail?.amount ?? "",
    },
    {
        accessorKey: "tip",
        header: "Tip",
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => row.original?.detail?.tip ?? "",
    },
    {
        accessorKey: "rating",
        header: "Rating",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => row.original?.detail?.rating ?? "",
    },
    {
        size: 230,
        accessorKey: "trip_type",
        header: "Trip Type",
        Cell: ({ row }) => row?.original?.detail?.trip_type ?? "",
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        size: 300,
        accessorKey: "note",
        header: "Note",
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "program_name",
        header: "Programs",
        renderColumnFilterModeMenuItems: customFilterPanel,
        size: 200,
        Cell: ({ row }) => (
            <Button
                disableElevation
                variant="contained"
                color="common"
                sx={{ py: ".5em", px: ".5em", borderRadius: "1em" }}
            >
                {row.original?.detail?.program_name ?? ""}
            </Button>
        ),
    },
    {
        accessorKey: "action",
        header: "Action",
        size: 200,
    },
]

function CompanyUserTransactions() {
    // USESTATE
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        driver_name: "contains",
        driver_phone: "contains",
        amount: "=",
        created_at: "is",
        status: "is",
        type: "contains",
        note: "contains",
        rating: "contains",
        tip: "=",
        program_name: "contains",
        dropoff_name: "contains",
        pickup_name: "contains",
        dropoff_time: "is",
        pickup_time: "is",
        trip_type: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    const { state: userInfo } = useLocation()

    // DEFAULT TRANSACTIONS FILTER WITH THE USER ID
    const defaultFilter = {
        column_field: "passenger_id",
        operator_value: "contains",
        value: userInfo.user_id,
    }

    const model = filterModel(filterFns, columnFilters)?.map((item) => ({
        column_field: item.column_field,
        operator_value: item.operator_value,
        value:
            item.operator_value === "empty" ||
            item.operator_value === "notEmpty"
                ? ""
                : item.value,
    }))
    model.push(defaultFilter)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "company_user_transactions",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/transactions`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter:
                        filterModel(filterFns, columnFilters)?.length === 0
                            ? JSON.stringify([defaultFilter])
                            : JSON.stringify(model),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("list all transactions"),
        }
    )

    const exportProcessor = (item) =>
        item?.map((el) => ({
            created_at: DateTime.fromISO(el.created_at).toFormat("ff"),
            driver_name: el.detail?.driver_name,
            driver_phone: el.detail?.driver_phone,
            amount: el.detail?.amount,
            note: el?.note,
            status: el?.status,
            type: el?.type,
            rating: el.detail?.rating,
            tip: el.detail?.tip,
            program: el.detail?.program_name,
            dropoff_name: el.detail?.dropoff_name,
            pickup_name: el.detail?.pickup_name,
            dropoff_time: el.detail?.dropoff_time,
            pickup_time: el.detail?.pickup_time,
            trip_type: el.detail?.trip_type,
        }))

    if (!HasPermission("list all transactions")) return <Unauthorized />

    return (
        <Box sx={{ backgroundColor: "white" }}>
            <MaterialReactTable
                columns={transactions}
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={
                                filterModel(filterFns, columnFilters)
                                    ?.length === 0
                                    ? [defaultFilter]
                                    : model
                            }
                            page="/system/transactions"
                            formatter={exportProcessor}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                }}
            />
        </Box>
    )
}

export default CompanyUserTransactions
