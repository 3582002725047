import React from "react"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from "@mui/material"

function CancelTrip(props) {
    const {
        data,
        handleClick,
        openDialog,
        setOpenDialog,
        trip,
        cancelTrip,
        cancelTripIsLoading,
    } = props

    return (
        <>
            <Button
                disabled={data?.status !== "STARTED"}
                sx={{
                    minWidth: 24,
                    px: 2,
                    bgcolor: data?.status !== "STARTED" ? "#e4e4e4" : "#FFDC00",
                    ":hover": {
                        bgcolor:
                            data?.status !== "STARTED" ? "#e4e4e4" : "#FFDC00",
                    },
                    color: "black",
                    fontSize: 14,
                    fontWeight: "bold",
                    textTransform: "none",
                }}
                onClick={() => (data ? handleClick(data) : handleClick())}
            >
                Cancel Trip
            </Button>
            <Dialog
                id="cancel-trips-by-id"
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                sx={{
                    "& .MuiDialog-paper": {
                        boxShadow: "0px 1px 1px 1px grey",
                    },
                }}
                hideBackdrop
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fff",
                    }}
                >
                    Confirm cancel trip operation
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Divider sx={{ mb: 2 }} />
                        Are you sure you want cancel this trip?
                        <Divider sx={{ mt: 2, mb: -1 }} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ px: 2 }}>
                    <Button
                        onClick={() => setOpenDialog(false)}
                        sx={{
                            bgcolor: "black",
                            color: "#fff",
                            ":hover": {
                                bgcolor: "black",
                                color: "#fff",
                            },
                            textTransform: "none",
                        }}
                        size="small"
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            bgcolor: "red",
                            color: "#fff",
                            ":hover": {
                                bgcolor: "red",
                                color: "#fff",
                            },
                            textTransform: "none",
                        }}
                        onClick={() =>
                            trip.current
                                ? cancelTrip(trip.current)
                                : cancelTrip(trip)
                        }
                        size="small"
                        disabled={cancelTripIsLoading}
                    >
                        Confirm
                        {cancelTripIsLoading && (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CancelTrip
