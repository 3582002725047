/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useQuery, useQueryClient } from "react-query"
import { useReactToPrint } from "react-to-print"
import { Close } from "@mui/icons-material"
import { Box } from "@mui/system"
import { TabContext, TabPanel } from "@mui/lab"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"

import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CustomDatePicker from "../../Component/customDatePicker.jsx"
import TransactionsReport from "./transactionReport.jsx"
import Unauthorized from "../../Component/unauthorized.jsx"
import HasPermission from "../../Utils/access.js"

// Transaction report generating dialog component
function GenerateTransactionReport() {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    // USESTATE & USEREF
    const [open, setOpen] = useState(true)
    const [openGenerated, setOpenGenerated] = useState(false)
    const [fromDate, setFrom] = useState(null)
    const [toDate, setTo] = useState(null)
    const componentRef = useRef()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data } = useQuery(
        ["transaction_report"],
        () =>
            axiosPrivate.get(`/system/report`, {
                params: {
                    filter: JSON.stringify([
                        {
                            column_field: "created_at",
                            operator_value: "between",
                            value: `${DateTime.fromISO(
                                fromDate?.toISOString()
                            ).toISODate()}|${DateTime.fromISO(
                                toDate?.toISOString()
                            ).toISODate()}`,
                        },
                    ]),
                },
            }),
        {
            onError: (error) => {
                setOpenGenerated(false)
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: !!fromDate && !!toDate && openGenerated,
        }
    )

    // EVENT HANDLERS & HELPERS
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
        documentTitle:
            fromDate?.length > 10 && toDate?.length > 10
                ? `Transaction report  from ${DateTime.fromISO(
                      fromDate?.toISOString()
                  ).toLocaleString(DateTime.DATE_MED)} to ${DateTime.fromISO(
                      toDate?.toISOString()
                  )
                      .plus({ hours: 23, minutes: 59 })
                      .toLocaleString(DateTime.DATE_MED)}`
                : "",
    })

    // USEEFFECTS
    useEffect(() => {
        if (!open) {
            setFrom(null)
            setTo(null)
        }
    }, [data, isFetching, open, openGenerated])

    useEffect(() => {
        if (!!fromDate && !!toDate && openGenerated) {
            queryClient.invalidateQueries("transaction_report")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate, openGenerated])

    // RENDER
    if (!HasPermission("report")) return <Unauthorized />

    return (
        <Dialog
            fullWidth
            maxWidth={
                data?.data?.data !== null && openGenerated
                    ? { lg: "lg", xs: "xs", sm: "sm", md: "md" }
                    : "sm"
            }
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    boxShadow: "0px 1px 7px #0000001A",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid container>
                    <Grid xs={11} lg={11} sm={11} xl={11} md={11}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            {isFetching && openGenerated && "Generating report"}
                            {data?.data?.data !== null &&
                                openGenerated &&
                                "Generated report"}
                            {data?.data?.data === null &&
                                !openGenerated &&
                                "Generate Report"}
                        </Typography>
                    </Grid>
                    <Grid
                        sx={{
                            ml:
                                data?.data?.data !== null && openGenerated
                                    ? 8
                                    : 0,
                        }}
                    >
                        {data ? (
                            <Tooltip title="Close Modal" arrow enterDelay={50}>
                                <IconButton
                                    onClick={() => {
                                        setOpen(false)
                                        setOpenGenerated(false)
                                        navigate("/")
                                    }}
                                    sx={{ color: "#000" }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    my: 1,
                }}
                ref={componentRef}
            >
                {isFetching ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress
                            sx={{
                                m: 5,
                                color: "#000000",
                            }}
                        />
                    </Box>
                ) : data?.data?.data !== null && openGenerated ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            mt: 2,
                        }}
                    >
                        <Typography>
                            Transactions between{" "}
                            <span
                                style={{
                                    fontWeight: "bold",
                                    alignItems: "flex-start",
                                    textAlign: "start",
                                }}
                            >
                                {DateTime.fromISO(
                                    fromDate?.toISOString()
                                ).toLocaleString(DateTime.DATE_MED)}{" "}
                                -{" "}
                                {DateTime.fromISO(toDate?.toISOString())
                                    .plus({ hours: 23, minutes: 59 })
                                    .toLocaleString(DateTime.DATE_MED)}{" "}
                            </span>
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: 2,
                                width: "100%",
                                py: 2,
                            }}
                        >
                            <TransactionsReport data={data?.data?.data} />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ width: "100%" }}>
                        <TabContext>
                            <TabPanel
                                sx={{
                                    m: "2em",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                >
                                    Choose date and generate report
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "100%",
                                            mb: 4,
                                            mt: 3,
                                        }}
                                    >
                                        <CustomDatePicker
                                            text="From"
                                            value={fromDate}
                                            date1={toDate}
                                            onChange={(newValue) => {
                                                setFrom(newValue?._d)
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <CustomDatePicker
                                            text="To"
                                            value={toDate}
                                            date2={fromDate}
                                            onChange={(newValue) => {
                                                setTo(newValue?._d)
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </TabPanel>
                        </TabContext>
                    </Box>
                )}
            </DialogContent>
            {data && openGenerated ? (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        color="inherit"
                        onClick={() => {
                            setOpenGenerated(false)
                        }}
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            background: "#DFDFDF 0% 0% no-repeat padding-box",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                    >
                        back
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        color="inherit"
                        onClick={() => {
                            setOpen(false)
                            queryClient.invalidateQueries(
                                "street_pickup_report"
                            )
                            navigate("/")
                        }}
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            background: "#DFDFDF 0% 0% no-repeat padding-box",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching || !fromDate || !toDate}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={() => {
                            setOpenGenerated(true)
                        }}
                    >
                        Generate
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}
export default GenerateTransactionReport
