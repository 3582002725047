/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { MoreVert, Refresh } from "@mui/icons-material"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import filterModel from "../../../Utils/filterModel.js"
import CustomExportOptions from "../../../Component/customExport.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import handleCopmanyProgramsExport from "../../../Utils/File Export Formatter/programsExport.js"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"

// DATA STRUCTURE
const timeRanges = [
    "12-1 ቀን",
    "1-2 ቀን",
    "2-3 ቀን",
    "3-4 ቀን",
    "4-5 ቀን",
    "5-6 ቀን",
    "6-7 ቀን",
    "7-8 ቀን",
    "8-9 ቀን",
    "9-10 ቀን",
    "10-11 ቀን",
    "11-12 ቀን",
    "12-1 ማታ",
    "1-2 ማታ",
    "2-3 ማታ",
    "3-4 ማታ",
    "4-5 ማታ",
    "5-6 ማታ",
    "6-7 ማታ",
    "7-8 ማታ",
    "8-9 ማታ",
    "9-10 ማታ",
    "10-11 ማታ",
    "11-12 ማታ",
]

const columns = [
    {
        accessorKey: "name",
        header: "Name",
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "total_trips_taken",
        header: "Total Trips",
        filterVariant: "number",
        size: 200,
        Cell: ({ cell }) =>
            cell?.getValue() > 0
                ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                : `${cell?.getValue()}`,
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "total_spent_amount",
        header: "Spent Amount",
        filterVariant: "number",
        size: 200,
        Cell: ({ cell }) =>
            cell?.getValue() > 0
                ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                : `${cell?.getValue()}`,
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "number_of_users",
        header: "Number of Users",
        filterVariant: "number",
        size: 220,
        Cell: ({ cell }) =>
            cell?.getValue() > 0
                ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                : `${cell?.getValue()}`,
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "spending_limit_amount",
        header: "Spending Limit Amount",
        size: 260,
        filterVariant: "number",
        renderColumnFilterModeMenuItems: customFilterPanel,
        Cell: ({ cell, row }) => {
            const isUnlimited = !row.original.spending_limit
            return isUnlimited ? (
                <Typography>Unlimited</Typography>
            ) : (
                `${roundToTwoDecimalPlaces(cell?.getValue())}`
            )
        },
    },
    {
        accessorKey: "spending_limit_duration",
        header: "Spending Limit Duration",
        size: 275,
        filterVariant: "select",
        filterSelectOptions: ["weekly", "daily", "monthly"],
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "trip_limit_amount",
        header: "Trip Limit Amount",
        size: 260,
        filterVariant: "number",
        Cell: ({ cell }) => {
            const isUnlimited = cell.getValue() === 0
            return isUnlimited ? (
                <Typography>Unlimited</Typography>
            ) : (
                `${roundToTwoDecimalPlaces(cell?.getValue())}`
            )
        },
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "trip_limit_duration",
        header: "Trip Limit Duration",
        size: 260,
        filterVariant: "select",
        filterSelectOptions: ["weekly", "daily", "monthly"],
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "date_limit_weekdays",
        header: "Date Limit Weekdays",
        enableColumnSort: false,
        size: 260,
        filterVariant: "select",
        filterSelectOptions: ["M", "Tu", "W", "Th", "F", "Sat", "Sun"],
        Cell: ({ row }) => {
            if (row.original?.date_limit === false) return "No Date Limit"
            const days = row.original?.date_limit_weekdays
            return days?.map(
                (dayNumber) =>
                    `${["Sun", "M", "Tu", "W", "Th", "F", "Sat"][dayNumber]}, `
            )
        },
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "time_limit_times",
        header: "Trip Limit Times",
        size: 260,
        enableColumnSort: false,
        filterVariant: "select",
        filterSelectOptions: timeRanges,
        renderColumnFilterModeMenuItems: customFilterPanel,
        Cell: ({ cell }) => {
            if (cell?.row?.original?.time_limit === false)
                return "No Time Limit"
            const times = cell?.row?.original?.time_limit_times
            return times?.map((time) => {
                if (time === 1) return `12-1 ቀን `
                if (time <= 12) return `${time - 1}-${time} ቀን `
                if (time === 13) return `12-1 ማታ `
                return `${time - 13}-${time - 12} ማታ `
            })
        },
    },
    {
        accessorKey: "expire_date",
        header: "Expire Date",
        filterVariant: "date",
        size: 220,
        Cell: ({ cell, row }) => {
            const noExpireDate = row.original?.has_expire_date === false
            if (noExpireDate) return <Typography>No Expire Date</Typography>
            const expired =
                DateTime.now().startOf("day") >
                DateTime.fromISO(cell.getValue()).startOf("day")
            return expired ? (
                <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    sx={{
                        backgroundColor: "#ffd6d6",
                        color: "red",
                        "&: hover": {
                            backgroundColor: "#ffd6d6",
                            color: "red",
                        },
                    }}
                >
                    Expired
                </Button>
            ) : (
                DateTime.fromISO(cell.getValue()).toFormat("ff")
            )
        },
        Filter: (props) => <CustomeDateFilter {...props} />,
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "created_at",
        header: "Created At",
        filterVariant: "date",
        size: 220,
        Cell: ({ cell }) =>
            DateTime.fromISO(cell?.getValue()).toLocaleString(
                DateTime.DATETIME_MED
            ),
        Filter: (props) => <CustomeDateFilter {...props} />,
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        size: 220,
        accessorKey: "status",
        header: "Status",
        filterVariant: "select",
        filterSelectOptions: ["ACTIVE", "INACTIVE", "PENDING"],
        Cell: ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#e5faea"
                            : "#ffd6d6",
                    color:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#56d154"
                            : "red",
                    "&: hover": {
                        backgroundColor:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#e5faea"
                                : "#ffd6d6",
                        color:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#56d154"
                                : "red",
                    },
                }}
            >
                {cell?.row?.original?.status}
            </Button>
        ),
        renderColumnFilterModeMenuItems: customFilterPanel,
    },
    {
        accessorKey: "actions",
        header: "Actions",
        enableSorting: false,
        enableColumnFilter: false,
        enableGlobalFilter: false,
    },
]
function CompanyPrograms() {
    const { state } = useLocation()
    const navigate = useNavigate()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        name: "contains",
        total_trips_taken: "=",
        total_spent_amount: "=",
        number_of_users: "=",
        spending_limit_amount: "=",
        spending_limit_duration: "=",
        trip_limit_amount: "=",
        trip_limit_duration: "is",
        date_limit_weekdays: "is",
        time_limit_times: "is",
        expire_date: "is",
        created_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [program, setProgram] = useState({})

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // FILTER MODEL
    const Fmodel = filterModel(filterFns, columnFilters, columns)

    // FILTER HANDLER, TO MAP TIME-RANGES AND DAY-LIMITS TO THEIR RESPECTIVE NUMBER
    const handleFilter = (filterArray) => {
        const filter = filterArray?.map((element) => {
            const { value } = element
            if (element.column_field === "date_limit_weekdays") {
                const ind = ["M", "Tu", "W", "Th", "F", "Sat", "Sun"].indexOf(
                    value
                )
                return { ...element, value: ind + 1 }
            }
            if (element.column_field === "time_limit_times") {
                const ind = timeRanges?.indexOf(value)
                return { ...element, value: ind + 1 }
            }
            return element
        })

        return filter
    }
    const model = handleFilter(Fmodel)
    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "company_programs",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `system/corporates/${state?.corporateId}/programs`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(model ?? undefined),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("list corporate programs"),
        }
    )

    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setProgram(row?.original)
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id={row?.original.id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        disabled={!HasPermission("list all transactions")}
                        onClick={() => {
                            navigate(`${program?.name}/transactions`, {
                                state: { programData: program },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Transactions
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("list company program users")}
                        onClick={() => {
                            navigate(`${program?.name}/members`, {
                                state: {
                                    programId: program?.id,
                                    corporateId: state?.corporateId,
                                },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Members
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("get program activities")}
                        onClick={() => {
                            navigate(`${program?.name}/details`, {
                                state: {
                                    programData: program,
                                    corporateId: state?.corporateId,
                                },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Details
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [anchorEl, navigate, program, state?.corporateId]
    )

    const columnFields = useMemo(
        () =>
            columns.map((ele) => {
                if (ele.accessorKey === "actions")
                    return {
                        ...ele,
                        Cell: actions,
                    }
                return ele
            }),
        [actions]
    )
    // RENDER
    if (!HasPermission("list corporate programs")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columnFields}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                formatter={handleCopmanyProgramsExport}
                                page={`/corporates/${state?.corporateId}/programs`}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default CompanyPrograms
