import React, { useReducer, useState } from "react"
import { Box, CircularProgress, Tab } from "@mui/material"
import { DateRangeOutlined } from "@mui/icons-material"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { useQuery } from "react-query"
import { DateTime } from "luxon"

import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CustomDatePicker from "../../Component/customDatePicker.jsx"

const initialState = {
    value: "1",
    year: null,
    month: null,
    toDate: null,
    fromDate: null,
    monthDate: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_VALUE":
            return { ...state, value: action.payload }
        case "SET_YEAR":
            return { ...state, year: action.payload }
        case "SET_MONTH":
            return { ...state, month: action.payload }
        case "SET_TO_DATE":
            return { ...state, toDate: action.payload }
        case "SET_FROM_DATE":
            return { ...state, fromDate: action.payload }
        case "SET_MONTH_DATE":
            return { ...state, monthDate: action.payload }
        default:
            return state
    }
}

// Search Receipts by Time Component
function FilterReceiptsByTime({ tinValue, setFilteredData }) {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [value, setValue] = useState("1")

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()
    // QUERY
    const { isLoading } = useQuery(
        ["filter_receipt_with_date", state.year, state.month, tinValue],
        () =>
            axiosPrivate.get(`/system/receipts`, {
                params: {
                    per_page: -1,
                    filter: state.year
                        ? JSON.stringify([
                              {
                                  column_field: "created_at",
                                  operator_value: "between",
                                  value: `${state.year}`,
                              },
                              {
                                  column_field: "service_number",
                                  operator_value: "contains",
                                  value: tinValue,
                              },
                          ])
                        : !!state.month &&
                          JSON.stringify([
                              {
                                  column_field: "created_at",
                                  operator_value: "between",
                                  value: state.month ? `${state.month}` : "",
                              },
                              {
                                  column_field: "service_number",
                                  operator_value: "contains",
                                  value: tinValue,
                              },
                          ]),
                    link_operator: "AND",
                },
            }),
        {
            onError: () => setFilteredData({ data: null }),
            onSuccess: (res) =>
                setFilteredData({
                    data: res?.data?.data,
                    filter: res?.data?.meta_data?.filter,
                }),
            refetchOnWindowFocus: false,
            retry: false,
            enabled: !!state.month || !!state.year,
        }
    )
    // EVENT HANDLERS & HELPERS
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleMonthChange = (newValue) => {
        if (newValue) {
            const startOfMonth = DateTime.fromJSDate(newValue)
                .startOf("month")
                .toFormat("yyyy-MM-dd")
            const endOfMonth = DateTime.fromJSDate(newValue)
                .endOf("month")
                .toFormat("yyyy-MM-dd")
            dispatch({ type: "SET_MONTH_DATE", payload: newValue })
            dispatch({
                type: "SET_MONTH",
                payload: `${startOfMonth}|${endOfMonth}`,
            })
            dispatch({ type: "RESET_DATES" })
        }
        return newValue
    }

    const handleFromPicker = (date) => {
        const selectedDate = DateTime.fromJSDate(date)
        const toDate = selectedDate.plus({ years: 1 })
        dispatch({ type: "SET_FROM_DATE", payload: date })
        dispatch({ type: "SET_TO_DATE", payload: toDate.toJSDate() })
        dispatch({
            type: "SET_YEAR",
            payload: `${selectedDate.toFormat("yyyy-MM-dd")}|${toDate.toFormat(
                "yyyy-MM-dd"
            )}`,
        })
        dispatch({ type: "SET_MONTH_DATE", payload: null })
        dispatch({ type: "SET_MONTH", payload: null })
    }

    const handleToPicker = (date) => {
        const selectedDateTime = DateTime.fromJSDate(date)
        const fromDate = selectedDateTime.minus({ years: 1 })
        dispatch({ type: "SET_TO_DATE", payload: date })
        dispatch({ type: "SET_FROM_DATE", payload: fromDate.toJSDate() })
        dispatch({
            type: "SET_YEAR",
            payload: `${fromDate.toFormat(
                "yyyy-MM-dd"
            )}|${selectedDateTime.toFormat("yyyy-MM-dd")}`,
        })
        dispatch({ type: "SET_MONTH_DATE", payload: null })
        dispatch({ type: "SET_MONTH", payload: null })
    }

    // RENDER
    if (isLoading)
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                m: 2,
            }}
        >
            <CircularProgress size={40} sx={{ color: "black" }} />
        </Box>

    return (
        <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleChange} variant="fullWidth">
                        <Tab
                            icon={<DateRangeOutlined />}
                            iconPosition="start"
                            label="Monthly"
                            value="1"
                        />
                        <Tab
                            icon={<DateRangeOutlined />}
                            iconPosition="start"
                            label="Yearly"
                            value="2"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <CustomDatePicker
                                text="Month"
                                value={state.monthDate}
                                date1={undefined}
                                date2={undefined}
                                views={["month", "year"]}
                                formatDate={(date) =>
                                    DateTime.fromISO(
                                        date?.toISOString
                                    ).toLocaleString("DD")
                                }
                                onChange={(newValue) =>
                                    handleMonthChange(newValue?._d)
                                }
                            />
                        </Box>
                    </LocalizationProvider>
                </TabPanel>
                <TabPanel value="2">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            <CustomDatePicker
                                text="From"
                                value={state.fromDate}
                                date1={state.toDate}
                                onChange={(newValue) =>
                                    handleFromPicker(newValue?._d)
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                width: "100%",
                                mt: "1em",
                            }}
                        >
                            <CustomDatePicker
                                text="To"
                                value={state.toDate}
                                date2={state.fromDate}
                                onChange={(newValue) =>
                                    handleToPicker(newValue?._d)
                                }
                            />
                        </Box>
                    </LocalizationProvider>
                </TabPanel>
            </TabContext>
        </Box>
    )
}
export default FilterReceiptsByTime
