import React from "react";
import { SvgIcon } from "@mui/material";

function Driver(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20.006"
      height="20.006"
      viewBox="0 0 20.006 20.006"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.507"
        height="20.506"
        viewBox="0 0 20.507 20.506"
      >
        <g
          id="Multiple_Vehicle_Options"
          data-name="Multiple Vehicle Options"
          transform="translate(0.5 0.5)"
        >
          <g id="Group_9776" data-name="Group 9776" transform="translate(0 0)">
            <g
              id="Group_9773"
              data-name="Group 9773"
              transform="translate(8.699 10.386)"
            >
              <path
                id="Path_4591"
                data-name="Path 4591"
                d="M204.66,243.089a1.054,1.054,0,1,0,1.054,1.054A1.056,1.056,0,0,0,204.66,243.089Z"
                transform="translate(-203.605 -243.089)"
                fill="none"
              />
            </g>
            <g
              id="Group_9774"
              data-name="Group 9774"
              transform="translate(1.712 7.421)"
            >
              <path
                id="Path_4592"
                data-name="Path 4592"
                d="M50.683,178.186a7.009,7.009,0,0,1,5.486-2.18,8.005,8.005,0,0,0-.389-2.318,13.186,13.186,0,0,1-7.444,2.335,15.151,15.151,0,0,1-7.872-2.333,8.046,8.046,0,0,0-.382,2.226,7.089,7.089,0,0,1,5.657,2.186,8.334,8.334,0,0,1,1.579,6.077c.265.026.533.04.805.04a8.137,8.137,0,0,0,.969-.06A8.259,8.259,0,0,1,50.683,178.186Zm4.578-3.417.133.31-2.548,1.1-.134-.309Zm-11.684,1.406-2.548-1.1.133-.31,2.548,1.1Zm3.155,1.532a1.392,1.392,0,1,1,1.392,1.392A1.393,1.393,0,0,1,46.732,177.707Z"
                transform="translate(-40.082 -173.688)"
                fill="currentColor"
              />
            </g>
            <g id="Group_9775" data-name="Group 9775">
              <path
                id="Path_4593"
                data-name="Path 4593"
                d="M9.753,0a9.753,9.753,0,1,0,9.753,9.753A9.764,9.764,0,0,0,9.753,0Zm0,18.288A8.388,8.388,0,1,1,18.141,9.9,8.4,8.4,0,0,1,9.753,18.292Z"
                transform="translate(0 -0.004)"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Driver;
