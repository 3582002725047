import { HighlightOff, TaskAlt } from "@mui/icons-material"
import {
    Avatar,
    Box,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"
import Activities from "../../Component/activities.jsx"
import HasPermission from "../../Utils/access.js"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"

// User Details View Component
function UserDetails() {
    const { state } = useLocation()

    return (
        <Grid
            container
            sx={{
                bgcolor: "#fff",
            }}
        >
            <Grid
                item
                sm={HasPermission("get user activities") ? 7 : 12}
                xs={12}
                sx={{ mx: 2, mt: 2 }}
            >
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        ml: { xs: 0, sm: 2 },
                    }}
                >
                    Profile
                </Typography>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            my: 2,
                        }}
                    >
                        <Avatar
                            sx={{
                                width: { sm: 100, xs: 60 },
                                height: { sm: 100, xs: 60 },
                            }}
                            src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${state?.userData?.profile_picture}`}
                        />
                    </Box>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                First Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.userData?.first_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Middle Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.userData?.middle_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Last Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.userData?.last_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Phone
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.userData?.phone}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Email
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.userData?.email}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Role(s)
                            </Typography>
                            <Divider />
                            <Box
                                sx={{
                                    mt: "0.5em",
                                }}
                            >
                                {state.userData?.roles?.map((role) => (
                                    <Box
                                        sx={{
                                            display: "inline-block",
                                            justifyContent: "start",
                                            alignItems: "flex-start",
                                            fontWeight: "bold",
                                            overflow: "hidden",
                                            m: ".1em",
                                            backgroundColor:
                                                role?.status === "ACTIVE"
                                                    ? "success.main"
                                                    : "error.main",
                                            color: "white",
                                            borderRadius: "20px",
                                            pr: "1em",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    color: "white",
                                                }}
                                            >
                                                {role?.status === "ACTIVE" ? (
                                                    <TaskAlt fontSize=".1em" />
                                                ) : (
                                                    <HighlightOff fontSize=".1em" />
                                                )}
                                            </IconButton>
                                            <Typography
                                                variant="subtitle2"
                                                noWrap
                                            >
                                                {role?.role_name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {HasPermission("get user activities") && (
                <>
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ mt: { sm: 3 }, mb: { sm: 2 } }}
                    />
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        sx={{ mt: 2, ml: { xs: 0, md: 3, sm: 1 } }}
                    >
                        <Activities
                            key={`system_user_activities_${state.userData?.id}`}
                            url={`users/${state.userData?.id}/activities`}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    )
}
export default UserDetails
