import { ExportToCsv } from "export-to-csv"

const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
}

const csvExporter = new ExportToCsv(csvOptions)

const handleExportRows = (exportData) =>
    csvExporter.generateCsv(exportData)


export default handleExportRows