import React, { useCallback, useEffect, useRef, useState } from "react"
import {
    Avatar,
    Button,
    Box,
    Divider,
    FormLabel,
    Grid,
    Typography,
    CircularProgress,
} from "@mui/material"
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineOppositeContent,
    timelineOppositeContentClasses,
    TimelineSeparator,
} from "@mui/lab"
import { Lightbulb, TaskAlt } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CustomMapContainer from "../../Component/mapContainer.jsx"
import {
    roundToTwoDecimalPlaces,
    timeFormatter,
} from "../../Utils/dataFormat.js"
import { RP_ASSETS_URL, BASE_URL, VERSION } from "../../Utils/config.js"
import ImageActionButton from "../../Component/tripImageActions.jsx"
import EndTrip from "../../Component/endTripButton.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import CancelTrip from "../../Component/cancelTrip.jsx"
import StartedTripMapComponent from "../../Component/startedTripMap.jsx"

const statusBgColor = (status) => {
    switch (status) {
        case "STARTED":
            return "#F5F5F5"
        case "ENDED":
            return "#E5FFEA"
        case "CANCELLED":
            return "#FFE5E5"
        case "CREATED":
            return "#EFEFEF"
        default:
            return "white"
    }
}
const statusColor = (status) => {
    switch (status) {
        case "STARTED":
            return "#FFDC00"
        case "ENDED":
            return "#16C138"
        case "CANCELLED":
            return "#E11212"
        case "CREATED":
            return "#000000"
        default:
            return "text.primary"
    }
}

// View Trip Details Component
function ViewTripDetails() {
    const param = useParams()

    const [hover, setHover] = useState({
        id: null,
        state: false,
    })
    const [openEndTripDialog, setOpenEndTripDialog] = useState(false)
    const [openCancelTripDialog, setOpenCancelTripDialog] = useState(false)
    const passengerPhoneNumber = useRef()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const {
        isFetching: tripIsFetching,
        data,
        refetch: refetchTripDetails,
    } = useQuery(["trip_details"], () =>
        axiosPrivate.get(`/system/trips/${param?.tripId}`)
    )
    const {
        isFetching: imageIsFetching,
        data: images,
        refetch,
    } = useQuery(
        ["images"],
        () =>
            passengerPhoneNumber.current &&
            axiosPrivate.get(
                `/system/trips/passengers/${passengerPhoneNumber.current}/images`
            ),
        {
            enabled: HasPermission("list street pickup passenger trip images"),
        }
    )
    const { mutate, isLoading } = useMutation(
        (image) =>
            axiosPrivate.patch(
                `/system/trips/passengers/${passengerPhoneNumber.current}/image`,
                { image_id: image?.id }
            ),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully changed the default image.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
            },
            onError: (error) =>
                error?.response?.data?.error?.field_error?.length > 0
                    ? error?.response?.data?.error?.field_error?.map((msg) =>
                          enqueueSnackbar(msg.description || "Network Error!", {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          })
                      )
                    : enqueueSnackbar(
                          error?.response?.data?.error?.message ||
                              error?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )
    const { mutate: remove, isLoading: removeIsLoading } = useMutation(
        (image) =>
            axiosPrivate.delete(`/system/trips/passenger/images/${image?.id}`),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully removed the image.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
            },
            onError: (error) =>
                error?.response?.data?.error?.field_error?.length > 0
                    ? error?.response?.data?.error?.field_error?.map((msg) =>
                          enqueueSnackbar(msg.description || "Network Error!", {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          })
                      )
                    : enqueueSnackbar(
                          error?.response?.data?.error?.message ||
                              error?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )
    const { mutate: endTrip, isLoading: endTripIsLoading } = useMutation(
        (selectedTrip) =>
            axiosPrivate.patch(`/system/trips/${selectedTrip?.id}/end`),
        {
            onSuccess: () => {
                enqueueSnackbar(`Ended the selected trip.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
                refetchTripDetails()
                setOpenEndTripDialog(false)
            },
            onError: (endTripError) =>
                endTripError?.response?.data?.error?.field_error?.length > 0
                    ? endTripError?.response?.data?.error?.field_error?.map(
                          (msg) =>
                              enqueueSnackbar(
                                  msg.description || "Network Error!",
                                  {
                                      variant: "error",
                                      preventDuplicate: true,
                                      autoHideDuration: 2000,
                                  }
                              )
                      )
                    : enqueueSnackbar(
                          endTripError?.response?.data?.error?.message ||
                              endTripError?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )
    const { mutate: cancelTrip, isLoading: cancelTripIsLoading } = useMutation(
        (selectedTrip) =>
            axiosPrivate.patch(`/system/trips/${selectedTrip?.id}/cancel`),
        {
            onSuccess: () => {
                enqueueSnackbar(`Cancelled the selected trip.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetch()
                refetchTripDetails()
                setOpenCancelTripDialog(false)
            },
            onError: (cancelTripError) =>
                cancelTripError?.response?.data?.error?.field_error?.length > 0
                    ? cancelTripError?.response?.data?.error?.field_error?.map(
                          (msg) =>
                              enqueueSnackbar(
                                  msg.description || "Network Error!",
                                  {
                                      variant: "error",
                                      preventDuplicate: true,
                                      autoHideDuration: 2000,
                                  }
                              )
                      )
                    : enqueueSnackbar(
                          cancelTripError?.response?.data?.error?.message ||
                              cancelTripError?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )

    // HELPERS & EVENT HANDLERS
    const totalTime = useCallback(() => {
        const start = DateTime.fromISO(data?.data?.data?.start_time)
        const end = DateTime.fromISO(data?.data?.data?.end_time)
        const diff = end.diff(start, "seconds")
        return timeFormatter(diff?.values?.seconds)
    }, [data?.data?.data?.end_time, data?.data?.data?.start_time])
    const handleDefaultImage = (image) => {
        mutate(image)
    }
    const handleRemoveUnnecessaryImage = (image) => {
        remove(image)
    }
    const handleEnd = () => {
        setOpenEndTripDialog(true)
    }
    const handleCancel = () => {
        setOpenCancelTripDialog(true)
    }

    // USEEFECT
    useEffect(() => {
        if (data || !passengerPhoneNumber.current) {
            passengerPhoneNumber.current =
                data?.data?.data?.passenger_phone_number
            refetch()
        }
    }, [data, refetch])

    if (!HasPermission("view street pickup details")) {
        return <Unauthorized />
    }
    return (
        <Grid
            container
            sx={{
                p: 4,
                mt: 2,
                bgcolor: "#ffffff",
                justifyContent: "center",
            }}
        >
            {tripIsFetching || imageIsFetching || !images ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                        minHeight: "450px",
                    }}
                >
                    <CircularProgress
                        size={40}
                        sx={{
                            color: "black",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                </Box>
            ) : (
                <>
                    {images &&
                        images?.data?.data?.length !== 0 &&
                        HasPermission(
                            "list street pickup passenger trip images"
                        ) && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4.5}
                                    justifyContent="center"
                                    sx={{
                                        mt: -2,
                                        overflowY: "auto",
                                        maxHeight:
                                            data?.data?.data?.status ===
                                            "STARTED"
                                                ? "450px"
                                                : "525px",
                                    }}
                                >
                                    {images?.data?.data[0] && (
                                        <Timeline
                                            sx={{
                                                [`& .${timelineItemClasses.root}:before`]:
                                                    {
                                                        flex: 0,
                                                        padding: 0,
                                                    },
                                                borderRadius: 18,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: 1,
                                                borderColor: "#fdbc3d",
                                                backgroundColor: "whitesmoke",
                                            }}
                                        >
                                            <TimelineItem>
                                                <TimelineContent>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            borderRadius: 18,
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            py: 1,
                                                            ml: -2,
                                                        }}
                                                    >
                                                        <TaskAlt
                                                            sx={{
                                                                ml: -2,
                                                                mr: 1.5,
                                                                color: "#fdbc3d",
                                                            }}
                                                        />
                                                        <Avatar
                                                            variant="rounded"
                                                            sx={{
                                                                width: 80,
                                                                height: 80,
                                                                mr: 2,
                                                            }}
                                                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/passenger_image/${images?.data?.data[0]?.image_url}`}
                                                        />
                                                    </Box>
                                                </TimelineContent>
                                                <TimelineSeparator
                                                    sx={{
                                                        ml: -1,
                                                    }}
                                                >
                                                    <TimelineConnector />
                                                    <TimelineDot
                                                        sx={{
                                                            backgroundColor:
                                                                "primary.main",
                                                        }}
                                                    />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            mr: -4,
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                                mt: 0.5,
                                                            }}
                                                        >
                                                            {" "}
                                                            {DateTime.fromISO(
                                                                images?.data
                                                                    ?.data[0]
                                                                    ?.created_at
                                                            ).toLocaleString(
                                                                DateTime.DATETIME_MED
                                                            )}
                                                        </Typography>
                                                        <FormLabel
                                                            sx={{
                                                                fontSize: 10,
                                                                mt: 2,
                                                                mb: 0.5,
                                                            }}
                                                        >
                                                            Taken By
                                                        </FormLabel>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {" "}
                                                            {`
                                                    ${images?.data?.data[0]?.driver_info?.first_name} 
                                                    ${images?.data?.data[0]?.driver_info?.middle_name}
                                                    ${images?.data?.data[0]?.driver_info?.last_name}
                                                `}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {" "}
                                                            {images?.data?.data[0]?.driver_info?.phone?.replace(
                                                                "2",
                                                                "+2"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </Timeline>
                                    )}
                                    {images?.data?.data?.map(
                                        (image, index) =>
                                            index !== 0 &&
                                            (index % 2 !== 0 ? (
                                                <>
                                                    <Box
                                                        sx={{
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            display: "flex",
                                                            my: -1,
                                                        }}
                                                    >
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{
                                                                minHeight: 30,
                                                                backgroundColor:
                                                                    "#1c2526",
                                                                borderRightWidth: 2,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            ":hover": {
                                                                transform:
                                                                    "scale(1.1)",
                                                            },
                                                        }}
                                                        onMouseOver={() =>
                                                            setHover({
                                                                id: index,
                                                                state: true,
                                                            })
                                                        }
                                                        onMouseOut={() =>
                                                            setHover({
                                                                id: null,
                                                                state: false,
                                                            })
                                                        }
                                                    >
                                                        <Timeline
                                                            sx={{
                                                                [`& .${timelineItemClasses.root}:before`]:
                                                                    {
                                                                        flex: 0,
                                                                        padding: 0,
                                                                    },
                                                                borderRadius: 18,
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                border: 1,
                                                                borderColor:
                                                                    "whitesmoke",
                                                                backgroundColor:
                                                                    "whitesmoke",
                                                            }}
                                                        >
                                                            <TimelineItem>
                                                                <TimelineContent>
                                                                    <Box
                                                                        sx={{
                                                                            ml: -6,
                                                                            justifyContent:
                                                                                "flex-end",
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            textAlign="end"
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                mt: 0.5,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {DateTime.fromISO(
                                                                                image?.created_at
                                                                            ).toLocaleString(
                                                                                DateTime.DATETIME_MED
                                                                            )}
                                                                        </Typography>
                                                                        <FormLabel>
                                                                            <Typography
                                                                                textAlign="end"
                                                                                sx={{
                                                                                    fontSize: 10,
                                                                                    mt: 2,
                                                                                    mb: 0.5,
                                                                                }}
                                                                            >
                                                                                Taken
                                                                                By
                                                                            </Typography>
                                                                        </FormLabel>
                                                                        <Typography
                                                                            textAlign="end"
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                fontWeight:
                                                                                    "bold",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {`
                                                                                ${image?.driver_info?.first_name} 
                                                                                ${image?.driver_info?.middle_name}
                                                                                ${image?.driver_info?.last_name}
                                                                            `}
                                                                        </Typography>
                                                                        <Typography
                                                                            textAlign="end"
                                                                            sx={{
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {image?.driver_info?.phone?.replace(
                                                                                "2",
                                                                                "+2"
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                </TimelineContent>
                                                                <TimelineSeparator
                                                                    sx={{
                                                                        mr:
                                                                            hover.id ===
                                                                                index &&
                                                                            hover.state
                                                                                ? 0
                                                                                : -1,
                                                                    }}
                                                                >
                                                                    <TimelineConnector />
                                                                    <TimelineDot
                                                                        sx={{
                                                                            backgroundColor:
                                                                                "black",
                                                                        }}
                                                                    />
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            borderRadius: 18,
                                                                            justifyContent:
                                                                                "center",
                                                                            alignItems:
                                                                                "center",
                                                                            py: 1,
                                                                            mr: -1,
                                                                        }}
                                                                    >
                                                                        <Avatar
                                                                            variant="rounded"
                                                                            sx={{
                                                                                width: 80,
                                                                                height: 80,
                                                                                ml:
                                                                                    hover.id ===
                                                                                        index &&
                                                                                    hover.state
                                                                                        ? 0.5
                                                                                        : 2,
                                                                            }}
                                                                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/passenger_image/${image?.image_url}`}
                                                                        />
                                                                        {hover.id ===
                                                                            index &&
                                                                            hover.state &&
                                                                            images
                                                                                ?.data
                                                                                ?.data
                                                                                ?.length >
                                                                                3 && (
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        justifyContent:
                                                                                            "flex-end",
                                                                                        minWidth:
                                                                                            "50%",
                                                                                    }}
                                                                                >
                                                                                    <ImageActionButton
                                                                                        id={
                                                                                            index
                                                                                        }
                                                                                        text="Make Default"
                                                                                        bgcolor="primary.main"
                                                                                        disabled={
                                                                                            !HasPermission(
                                                                                                "set passenger default image"
                                                                                            )
                                                                                        }
                                                                                        isLoading={
                                                                                            isLoading &&
                                                                                            hover.id ===
                                                                                                index
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleDefaultImage(
                                                                                                image
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <ImageActionButton
                                                                                        id={
                                                                                            index
                                                                                        }
                                                                                        text="Remove"
                                                                                        bgcolor="red"
                                                                                        disabled={
                                                                                            !HasPermission(
                                                                                                "delete street pickup passenger trip images"
                                                                                            )
                                                                                        }
                                                                                        isLoading={
                                                                                            removeIsLoading &&
                                                                                            hover.id ===
                                                                                                index
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleRemoveUnnecessaryImage(
                                                                                                image
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )}
                                                                    </Box>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        </Timeline>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Box
                                                        sx={{
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            display: "flex",
                                                            my: -1,
                                                        }}
                                                    >
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{
                                                                minHeight: 30,
                                                                backgroundColor:
                                                                    "#1c2526",
                                                                borderRightWidth: 2,
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            ":hover": {
                                                                transform:
                                                                    "scale(1.1)",
                                                            },
                                                        }}
                                                        onMouseOver={() =>
                                                            setHover({
                                                                id: index,
                                                                state: true,
                                                            })
                                                        }
                                                        onMouseOut={() =>
                                                            setHover({
                                                                id: null,
                                                                state: false,
                                                            })
                                                        }
                                                    >
                                                        <Timeline
                                                            sx={{
                                                                [`& .${timelineItemClasses.root}:before`]:
                                                                    {
                                                                        flex: 0,
                                                                        padding: 0,
                                                                    },
                                                                borderRadius: 18,
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                border: 1,
                                                                borderColor:
                                                                    "whitesmoke",
                                                                backgroundColor:
                                                                    "whitesmoke",
                                                            }}
                                                        >
                                                            <TimelineItem>
                                                                <TimelineContent>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            borderRadius: 18,
                                                                            justifyContent:
                                                                                "center",
                                                                            alignItems:
                                                                                "center",
                                                                            py: 1,
                                                                        }}
                                                                    >
                                                                        {hover.id ===
                                                                            index &&
                                                                            hover.state &&
                                                                            images
                                                                                ?.data
                                                                                ?.data
                                                                                ?.length >
                                                                                3 && (
                                                                                <Box
                                                                                    sx={{
                                                                                        display:
                                                                                            "flex",
                                                                                        flexDirection:
                                                                                            "column",
                                                                                        justifyContent:
                                                                                            "center",
                                                                                        minWidth:
                                                                                            "50%",
                                                                                        ml: -1,
                                                                                    }}
                                                                                >
                                                                                    <ImageActionButton
                                                                                        id={
                                                                                            index
                                                                                        }
                                                                                        text="Make Default"
                                                                                        bgcolor="primary.main"
                                                                                        isLoading={
                                                                                            hover.id ===
                                                                                                index &&
                                                                                            isLoading
                                                                                        }
                                                                                        disabled={
                                                                                            !HasPermission(
                                                                                                "set passenger default image"
                                                                                            )
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleDefaultImage(
                                                                                                image
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    <ImageActionButton
                                                                                        id={
                                                                                            index
                                                                                        }
                                                                                        text="Remove"
                                                                                        bgcolor="red"
                                                                                        disabled={
                                                                                            !HasPermission(
                                                                                                "delete street pickup passenger trip images"
                                                                                            )
                                                                                        }
                                                                                        isLoading={
                                                                                            removeIsLoading &&
                                                                                            hover.id ===
                                                                                                index
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleRemoveUnnecessaryImage(
                                                                                                image
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )}
                                                                        <Avatar
                                                                            variant="rounded"
                                                                            sx={{
                                                                                width: 80,
                                                                                height: 80,
                                                                                mr:
                                                                                    hover.id ===
                                                                                        index &&
                                                                                    hover.state
                                                                                        ? 0
                                                                                        : 2,
                                                                            }}
                                                                            src={`${BASE_URL}/${VERSION}/${RP_ASSETS_URL}/passenger_image/${image?.image_url}`}
                                                                        />
                                                                    </Box>
                                                                </TimelineContent>
                                                                <TimelineSeparator>
                                                                    <TimelineConnector />
                                                                    <TimelineDot
                                                                        sx={{
                                                                            backgroundColor:
                                                                                "black",
                                                                        }}
                                                                    />
                                                                    <TimelineConnector />
                                                                </TimelineSeparator>
                                                                <TimelineContent>
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                            mr: -3.5,
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                mt: 0.5,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {DateTime.fromISO(
                                                                                image.created_at
                                                                            ).toLocaleString(
                                                                                DateTime.DATETIME_MED
                                                                            )}
                                                                        </Typography>
                                                                        <FormLabel
                                                                            sx={{
                                                                                fontSize: 10,
                                                                                mt: 2,
                                                                                mb: 0.5,
                                                                            }}
                                                                        >
                                                                            Taken
                                                                            By
                                                                        </FormLabel>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                                fontWeight:
                                                                                    "bold",
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {`
                                                    ${image?.driver_info?.first_name} 
                                                    ${image?.driver_info?.middle_name}
                                                    ${image?.driver_info?.last_name}
                                                `}
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            {" "}
                                                                            {image?.phone?.replace(
                                                                                "2",
                                                                                "+2"
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                </TimelineContent>
                                                            </TimelineItem>
                                                        </Timeline>
                                                    </Box>
                                                </>
                                            ))
                                    )}
                                </Grid>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ mx: 4 }}
                                />
                            </>
                        )}
                    <Grid
                        item
                        xs={12}
                        sm={
                            images?.data?.data?.length !== 0 &&
                            HasPermission(
                                "list street pickup passenger trip images"
                            )
                                ? 6.5
                                : 12
                        }
                    >
                        <Grid container justifyContent={{ sm: "flex-end" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        mb: 1,
                                    }}
                                >
                                    <FormLabel
                                        sx={{
                                            fontSize: 12,
                                            mb: 1,
                                        }}
                                    >
                                        Status
                                    </FormLabel>
                                    <Button
                                        sx={{
                                            bgcolor: `${statusBgColor(
                                                data?.data?.data?.status
                                            )}`,
                                            color: `${statusColor(
                                                data?.data?.data?.status
                                            )}`,
                                            ":hover": {
                                                bgcolor: `${statusBgColor(
                                                    data?.data?.data?.status
                                                )}`,
                                            },
                                            textTransform: "none",
                                            width: 100,
                                            mr: 3,
                                        }}
                                        size="small"
                                    >
                                        {data?.data?.data?.status}
                                    </Button>
                                </Box>
                                {data?.data?.data?.status === "STARTED" &&
                                    (HasPermission(
                                        "end a street pickup service from system"
                                    ) ||
                                        HasPermission(
                                            "cancel a street pickup service"
                                        )) && (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <FormLabel
                                                sx={{ fontSize: 12, mb: 1 }}
                                            >
                                                Action
                                            </FormLabel>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: {
                                                        xs: "column",
                                                        sm: "row",
                                                    },
                                                    gap: 2,
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {HasPermission(
                                                    "end a street pickup service from system"
                                                ) && (
                                                    <EndTrip
                                                        data={data?.data?.data}
                                                        handleClick={handleEnd}
                                                        openDialog={
                                                            openEndTripDialog
                                                        }
                                                        setOpenDialog={
                                                            setOpenEndTripDialog
                                                        }
                                                        trip={data?.data?.data}
                                                        endTrip={endTrip}
                                                        endTripIsLoading={
                                                            endTripIsLoading
                                                        }
                                                    />
                                                )}
                                                {HasPermission(
                                                    "cancel a street pickup service"
                                                ) && (
                                                    <CancelTrip
                                                        data={data?.data?.data}
                                                        handleClick={
                                                            handleCancel
                                                        }
                                                        openDialog={
                                                            openCancelTripDialog
                                                        }
                                                        setOpenDialog={
                                                            setOpenCancelTripDialog
                                                        }
                                                        trip={data?.data?.data}
                                                        cancelTrip={cancelTrip}
                                                        cancelTripIsLoading={
                                                            cancelTripIsLoading
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                            </Box>
                        </Grid>
                        <Grid container sx={{ my: { sm: 6, xs: 2 } }}>
                            <Grid item xs={12} sm={2.5}>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            mb: 2,
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Driver Name
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb:
                                                    data?.data?.data
                                                        ?.driver_info
                                                        ?.first_name ===
                                                    undefined
                                                        ? 3
                                                        : 0,
                                            }}
                                        >
                                            {data?.data?.data?.driver_info
                                                ?.first_name ?? " "}{" "}
                                            {data?.data?.data?.driver_info
                                                ?.middle_name ?? " "}{" "}
                                            {data?.data?.data?.driver_info
                                                ?.last_name ?? " "}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            mb: 2,
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Driver No
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb:
                                                    data?.data?.data
                                                        ?.driver_info?.phone ===
                                                    undefined
                                                        ? 3
                                                        : 0,
                                            }}
                                        >
                                            {data?.data?.data?.driver_info
                                                ?.phone ?? " "}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Passenger No
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                                mb:
                                                    data?.data?.data
                                                        ?.passenger_phone_number ===
                                                    undefined
                                                        ? 3
                                                        : 0,
                                            }}
                                        >
                                            +
                                            {data?.data?.data
                                                ?.passenger_phone_number ?? " "}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mr: 3, ml: 1 }}
                            />
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            mb: 2,
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Waiting Time
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {timeFormatter(
                                                Number(
                                                    data?.data?.data
                                                        ?.waiting_time
                                                ) * 60
                                            ) ?? timeFormatter(0)}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Total Time
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {totalTime() ?? timeFormatter(0)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mr: 3, ml: 1 }}
                            />
                            <Grid item xs={12} sm={2}>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            mb: 2,
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Estimated Price
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {roundToTwoDecimalPlaces(
                                                data?.data?.data?.estimated_cost
                                            ) ??
                                                roundToTwoDecimalPlaces(
                                                    0.0
                                                )}{" "}
                                            ETB
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Total Price
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {roundToTwoDecimalPlaces(
                                                data?.data?.data?.cost
                                            ) ??
                                                roundToTwoDecimalPlaces(
                                                    0.0
                                                )}{" "}
                                            ETB
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mr: 3, ml: 1 }}
                            />
                            <Grid item xs={12} sm={3}>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            mb: 2,
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Commission Percent
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {roundToTwoDecimalPlaces(
                                                data?.data?.data
                                                    ?.commission_percent ?? 0
                                            )}
                                            %
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <FormLabel sx={{ fontSize: 12 }}>
                                            Commission
                                        </FormLabel>
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {roundToTwoDecimalPlaces(
                                                data?.data?.data
                                                    ?.commission_value ?? 0
                                            )}{" "}
                                            ETB
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ my: { sm: 6, xs: 2 } }}>
                            <Box
                                sx={{
                                    backgroundColor: "whitesmoke",
                                    width: "100%",
                                }}
                            >
                                <Timeline
                                    sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]:
                                            {
                                                flex: 0.4,
                                            },
                                    }}
                                >
                                    <TimelineItem>
                                        <TimelineOppositeContent
                                            sx={{ textAlign: "left" }}
                                        >
                                            <FormLabel sx={{ fontSize: 12 }}>
                                                Estimated KM
                                            </FormLabel>
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {data?.data?.data
                                                    ?.estimated_km > 1000
                                                    ? `${roundToTwoDecimalPlaces(
                                                          Number(
                                                              data?.data?.data
                                                                  ?.estimated_km
                                                          ) / 1000
                                                      )} km`
                                                    : `${roundToTwoDecimalPlaces(
                                                          Number(
                                                              data?.data?.data
                                                                  ?.estimated_km ??
                                                                  0
                                                          )
                                                      )} m`}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot
                                                sx={{
                                                    backgroundColor: "white",
                                                    border: "1px solid #08C139",
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        backgroundColor:
                                                            "#08C139",
                                                        width: "14px",
                                                        height: "14px",
                                                        borderRadius: "50%",
                                                    }}
                                                />
                                            </TimelineDot>
                                            <TimelineConnector
                                                sx={{
                                                    backgroundColor: "#08C139",
                                                    my: -1.5,
                                                }}
                                            />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Box
                                                sx={{
                                                    ml: 1,
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {" "}
                                                    {DateTime.fromISO(
                                                        data?.data?.data
                                                            ?.start_time
                                                    ).toLocaleString(
                                                        DateTime.DATETIME_MED
                                                    )}
                                                </Typography>
                                                <FormLabel
                                                    sx={{ fontSize: 12 }}
                                                >
                                                    {data?.data?.data
                                                        ?.start_point_name ??
                                                        ""}
                                                </FormLabel>
                                            </Box>
                                        </TimelineContent>
                                    </TimelineItem>
                                    {data?.data?.data?.status === "ENDED" && (
                                        <TimelineItem>
                                            <TimelineOppositeContent
                                                sx={{ textAlign: "left" }}
                                            >
                                                <FormLabel
                                                    sx={{ fontSize: 12 }}
                                                >
                                                    Total KM
                                                </FormLabel>
                                                <Typography
                                                    sx={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {data?.data?.data
                                                        ?.distance > 1000
                                                        ? `${roundToTwoDecimalPlaces(
                                                              Number(
                                                                  data?.data
                                                                      ?.data
                                                                      ?.distance
                                                              ) / 1000
                                                          )} km`
                                                        : `${roundToTwoDecimalPlaces(
                                                              Number(
                                                                  data?.data
                                                                      ?.data
                                                                      ?.distance ??
                                                                      0
                                                              )
                                                          )}m`}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot
                                                    sx={{
                                                        backgroundColor:
                                                            "white",
                                                        border: "1px solid #FFDC00",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            backgroundColor:
                                                                "primary",
                                                            width: "14px",
                                                            height: "14px",
                                                        }}
                                                    >
                                                        <Lightbulb
                                                            fontSize="inherit"
                                                            color="primary"
                                                        />
                                                    </Box>
                                                </TimelineDot>
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Box
                                                    sx={{
                                                        ml: 2,
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 13,
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {DateTime.fromISO(
                                                            data?.data?.data
                                                                ?.end_time
                                                        ).toLocaleString(
                                                            DateTime.DATETIME_MED
                                                        )}
                                                    </Typography>
                                                    <FormLabel
                                                        sx={{
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        {data?.data?.data
                                                            ?.end_point_name ??
                                                            ""}
                                                    </FormLabel>
                                                </Box>
                                            </TimelineContent>
                                        </TimelineItem>
                                    )}
                                </Timeline>
                            </Box>
                        </Grid>
                    </Grid>
                    {data?.data?.data?.poly_line &&
                        data?.data?.data?.poly_line !== "" && (
                            <Grid container justifyContent="center">
                                <Box
                                    sx={{
                                        display: "flex",
                                        height: "450px",
                                        width: "100%",
                                        mt: 2,
                                    }}
                                >
                                    <CustomMapContainer
                                        polyline={
                                            data?.data?.data?.poly_line === ""
                                                ? "????"
                                                : data?.data?.data?.poly_line
                                        }
                                        startingPoint={
                                            data?.data?.data?.start_point_name?.split(
                                                "/"
                                            )[0]
                                        }
                                        endPoint={
                                            data?.data?.data?.end_point_name?.split(
                                                "/"
                                            )[0]
                                        }
                                    />
                                </Box>
                            </Grid>
                        )}
                    {data?.data?.data?.status === "STARTED" && (
                        <Grid container justifyContent="center">
                            <Box
                                sx={{
                                    display: "flex",
                                    height: "450px",
                                    width: "100%",
                                    mt: 2,
                                }}
                            >
                                <StartedTripMapComponent
                                    id={param?.tripId}
                                    refetch={refetchTripDetails}
                                />
                            </Box>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}
export default ViewTripDetails
