import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

function Listener() {
    const navigate = useNavigate()
    const listener = (e) => {
        if (typeof e.data !== "string") return
        if (e.data === "login-success") {
            navigate("/")
        } else if (e.data === "login-fail") navigate("/login")
    }
    useEffect(
        () => () => {
            window.removeEventListener("message", listener)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    window.addEventListener("message", listener)
    return <div />
}

export default Listener
