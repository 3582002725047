/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Alert,
    Avatar,
    Box,
    Chip,
    IconButton,
    Tooltip,
} from "@mui/material"
import { DateTime } from "luxon"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { useLocation } from "react-router-dom"
import { Refresh } from "@mui/icons-material"
import CustomExportOptions from "../../Component/customExport.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import CustomeTabs from "../../Component/customTabs.jsx"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

// Family Package Members List View Component
function FamilyPackageMembers() {
    const { state } = useLocation()

    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        first_name: "contains",
        phone: "contains",
        spent_amount: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [isGuest, setIsGuest] = useState(false)

    const tabs = [
        { value: "members", label: "Members" },
        { value: "guest", label: "Guest" },
        { value: "invited", label: "Invited" },
    ]

    // FILTER MODEL WITH SELECTED TAB
    const model = filterModel(filterFns, columnFilters)
    let filterModelWithTab

    if (isGuest !== "invited") {
        filterModelWithTab = [
            ...model,
            {
                column_field: "guest",
                operator_value: "is",
                value: isGuest ? "true" : "false",
            },
        ]
    } else {
        filterModelWithTab = model
    }

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const {
        isFetching,
        data,
        refetch: refetchMembers,
    } = useQuery(
        [
            "family_package_members",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterModelWithTab,
        ],
        () =>
            axiosPrivate.get(`/system/family-packages/${state?.id}/members`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(filterModelWithTab ?? undefined),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled:
                Boolean(isGuest !== "invited") &&
                HasPermission("list family program members"),
        }
    )
    const {
        isFetching: invitedIsFetching,
        data: invitedUsers,
        refetch: refetchInvitedUsers,
    } = useQuery(
        [
            "family_package_invited_users",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `/system/family-packages/${state?.id}/users/invite`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(filterModelWithTab ?? undefined),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (invitedError) =>
                enqueueSnackbar(
                    invitedError?.response?.data?.error?.message ||
                        invitedError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled:
                Boolean(isGuest === "invited") ||
                HasPermission("list family program invited users"),
        }
    )

    // EVENT HANDLERS & HELPERS
    const handleTabChange = (selectedTab) =>
        selectedTab === "invited"
            ? setIsGuest("invited")
            : setIsGuest(selectedTab === "guest")
    const getStatus = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    width: "120px",
                }}
            >
                <Alert
                    severity="info"
                    icon={false}
                    sx={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        backgroundColor:
                            row?.original?.status === "ACTIVE"
                                ? "#e5ffea"
                                : "#ffd6d6",
                        color:
                            row?.original?.status === "ACTIVE"
                                ? "#33ca51"
                                : "#f86049b5",
                        fontWeight: "bold",
                    }}
                >
                    {row?.original?.status.toLowerCase()}
                </Alert>
            </Box>
        ),
        []
    )
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.profile_picture}`}
            />
        ),
        []
    )
    const viewProgram = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    display: "inline-block",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    fontWeight: "bold",
                }}
            >
                {cell.getValue()?.map((program) => (
                    <Chip
                        key={program?.id}
                        label={program?.name}
                        sx={{
                            my: 0.5,
                            mr: 0.5,
                            color: "primary.main",
                        }}
                    />
                ))}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "photo",
                header: "Photo",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 220,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "first_name",
                header: "Name",
                Cell: ({ row }) =>
                    `${row?.original?.first_name ?? ""} ${
                        row?.original?.middle_name ?? ""
                    }`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "spent_amount",
                header: "Amount Spent (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 400,
                accessorKey: "programs",
                header: "Programs",
                enableColumnFilter: false,
                Cell: viewProgram,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getAvatar, getStatus, viewProgram]
    )

    // RENDERING
    if (!HasPermission("list family program members")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={
                        isGuest === "invited"
                            ? invitedUsers?.data?.data || []
                            : data?.data?.data || []
                    }
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select", "photo"],
                            right: ["status"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <CustomeTabs tabs={tabs} onChange={handleTabChange} />
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton
                                    onClick={() => {
                                        if (isGuest === "invited") {
                                            refetchInvitedUsers()
                                        } else refetchMembers()
                                    }}
                                >
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={
                                    isGuest === "invited"
                                        ? filterModel(filterFns, columnFilters)
                                        : filterModelWithTab
                                }
                                page={
                                    isGuest === "invited"
                                        ? `/system/family-packages/${state?.id}/users/invite`
                                        : `/system/family-packages/${state?.id}/memebrs`
                                }
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        invitedIsFetching,
                        pagination,
                        filterModelWithTab,
                        showSkeletons: isFetching || invitedIsFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default FamilyPackageMembers
