import React, { createContext, useMemo, useState } from "react"

const AuthContext = createContext({})

export function AuthProvider({ children }) {
    const [auth, setAuth] = useState({})
    const [sessionState, setSessionState] = useState(
        localStorage.getItem("session_state") || ""
    )
    const [persist, setPersist] = useState(
        JSON.parse(localStorage.getItem("persist")) || false
    )
    const [permissions, setPermissions] = useState([])

    const value = useMemo(
        () => ({
            auth,
            setAuth,
            persist,
            setPersist,
            permissions,
            setPermissions,
            sessionState,
            setSessionState,
        }),
        [auth, permissions, persist, sessionState]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
