import React, { useState, useEffect, useRef } from "react"
import {
    TextField,
    Grid,
    Box,
    Typography,
    useMediaQuery,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { useMutation } from "react-query"

import axios from "../../Utils/Api/axios.js"

function OtpInputForm({
    id,
    open,
    countdown,
    setCountdown,
    handleClose,
    refetch,
}) {
    const [otp, setOtp] = useState(Array(6).fill(""))
    const [resend, setResend] = useState(false)
    const firstInputRef = useRef(null)
    const isMobile = useMediaQuery("(max-width:600px)")

    const { enqueueSnackbar } = useSnackbar()
    const { mutate, isLoading } = useMutation(
        () => axios.post(`/readme_points/${id}/confirm`, { pin: otp.join("") }),
        {
            onSuccess: () => {
                refetch()
                handleClose()
                setCountdown(59)
            },
            onError: (error) => {
                if (error?.response?.data?.error?.message) {
                    enqueueSnackbar(
                        error?.response?.data?.error?.message ||
                            "Network Error!",
                        {
                            variant: "error",
                            preventDuplicate: true,
                            autoHideDuration: 2000,
                        }
                    )
                }
                setResend(true)
            },
        }
    )
    const { mutate: resendOTP, isLoading: resending } = useMutation(
        () => axios.post(`/resend/${id}/otp`),
        {
            onSuccess: () => {
                setResend(false)
                setCountdown(59)
            },
            onError: (error) => {
                if (error?.response?.data?.error?.message) {
                    enqueueSnackbar(
                        error?.response?.data?.error?.message ||
                            "Network Error!",
                        {
                            variant: "error",
                            preventDuplicate: true,
                            autoHideDuration: 2000,
                        }
                    )
                }
                setResend(true)
                setCountdown(59)
            },
        }
    )
    const isOtpComplete = () => otp.every((digit) => digit !== "")
    const handleChange = (e, index) => {
        const { value } = e.target
        if (value.length > 1) {
            const newValue = value.replace(/\D/g, "").split("").slice(0, 6)
            setOtp(newValue)
            const nextEmptyIndex = newValue.findIndex((val) => val === "")
            const indexToFocus = nextEmptyIndex === -1 ? 5 : nextEmptyIndex
            document.getElementById(`otp-${indexToFocus}`)?.focus()
        } else {
            const newOtp = [...otp]
            if (index < 6) {
                newOtp[index] = value.replace(/\D/g, "").slice(0, 1)
                setOtp(newOtp)
                if (value && index < 5) {
                    const nextInput = document.getElementById(
                        `otp-${index + 1}`
                    )
                    nextInput?.focus()
                }
            }
        }
        setCountdown(0)
    }
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-${index - 1}`)
            prevInput?.focus()
        }
        if (e.key === "ArrowLeft" && index > 0) {
            const prevInput = document.getElementById(`otp-${index - 1}`)
            prevInput?.focus()
        }
        if (e.key === "ArrowRight" && index < 5) {
            const nextInput = document.getElementById(`otp-${index + 1}`)
            nextInput?.focus()
        }
    }
    const handlePaste = (e) => {
        e.preventDefault()
        const pastedData = e.clipboardData?.getData("text") || ""
        const cleanedValue = pastedData.replace(/\D/g, "").slice(0, 6)
        const newOtp = Array(6).fill("")
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cleanedValue.length; i++) {
            newOtp[i] = cleanedValue[i]
        }
        setOtp(newOtp)
        const nextEmptyIndex = newOtp.findIndex((val) => val === "")
        const indexToFocus = nextEmptyIndex === -1 ? 5 : nextEmptyIndex
        document.getElementById(`otp-${indexToFocus}`)?.focus()
        setCountdown(0)
    }
    const handleConfirm = () => {
        mutate()
    }
    const handleResend = () => {
        firstInputRef.current?.focus()
        setOtp(Array(6).fill(""))
        resendOTP()
    }
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000)
            return () => clearTimeout(timer)
        }
    }, [countdown, setCountdown])
    useEffect(() => {
        if (open) {
            if (open) {
                setTimeout(() => {
                    firstInputRef.current?.focus()
                }, 0)
            }
        }
    }, [open])
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsKeyboardVisible(window.innerHeight < 600)
        }

        window.addEventListener("resize", handleResize)
        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
            fullWidth
            fullScreen={isMobile}
            PaperProps={{
                sx: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: isKeyboardVisible ? "flex-start" : "center",
                    height: isKeyboardVisible ? `calc(100vh - 300px)` : "auto",
                    borderRadius: 2,
                    margin: isMobile ? 0 : undefined,
                    maxHeight: "100%",
                    position: isMobile ? "absolute" : undefined,
                    top: isMobile ? "5em" : undefined,
                },
            }}
        >
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h5" gutterBottom fontWeight={700}>
                        Enter OTP
                    </Typography>
                    <Grid
                        container
                        spacing={isMobile ? 0.5 : 2}
                        justifyContent="center"
                        mt={0.5}
                        px="0.5em"
                    >
                        {otp?.length <= 6 &&
                            otp?.map((code, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Grid item key={index} xs={2}>
                                    <TextField
                                        id={`otp-${index}`}
                                        inputProps={{
                                            maxLength: index === 0 ? 6 : 1,
                                            style: {
                                                textAlign: "center",
                                                fontSize: "20px",
                                            },
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                        }}
                                        value={code}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) =>
                                            handleKeyDown(e, index)
                                        }
                                        onPaste={handlePaste}
                                        variant="outlined"
                                        size="small"
                                        type="text"
                                        inputRef={
                                            index === 0 ? firstInputRef : null
                                        }
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </Box>
                <Box sx={{ justifyContent: "start", mt: 1, mb: -1 }}>
                    {countdown > 0 ? (
                        <Typography ml={1} variant="body1" fontWeight={700}>
                            Retry in 0:{countdown} seconds
                        </Typography>
                    ) : (
                        resend && (
                            <Button
                                onClick={() => handleResend()}
                                sx={{
                                    textTransform: "none",
                                    fontSize: 18,
                                    fontWeight: 700,
                                }}
                            >
                                Resend OTP
                                {resending && (
                                    <CircularProgress
                                        size="1em"
                                        sx={{ ml: "1em" }}
                                    />
                                )}
                            </Button>
                        )
                    )}
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 4,
                    gap: 1,
                    mb: 1,
                    width: "100%",
                }}
            >
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleConfirm}
                    disabled={!isOtpComplete() || isLoading || resending}
                    sx={{
                        color: "white",
                        bgcolor: "black",
                        ":hover": {
                            color: "white",
                            bgcolor: "black",
                        },
                        textTransform: "none",
                        fontWeight: 700,
                        p: 1.5,
                        borderRadius: 2,
                    }}
                >
                    Confirm
                    {isLoading && (
                        <CircularProgress
                            size="1em"
                            sx={{ color: "#fff", ml: "1em" }}
                        />
                    )}
                </Button>
                <Button
                    onClick={() => {
                        setCountdown(0)
                        handleClose()
                        refetch()
                    }}
                    color="primary"
                    sx={{
                        textTransform: "none",
                        color: "black",
                        fontWeight: 900,
                        fontSize: 16,
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default OtpInputForm
