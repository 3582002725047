import React, {  } from "react"
import {
    MenuItem,
} from "@mui/material"

// Operators with respect to column data types
const string = [
    { name: "contains", value: "contains" },
    { name: "equals", value: "equals" },
    { name: "starts with", value: "startsWith" },
    { name: "ends with", value: "endsWith" },
    { name: "is empty", value: "empty" },
    { name: "is not empty", value: "notEmpty" },
    // TO DO
    // { name: "is any of", value: "anyOf" },
]
const number = [
    { name: "=", value: "=" },
    { name: "!=", value: "!=" },
    { name: ">", value: ">" },
    { name: ">=", value: ">=" },
    { name: "<", value: "<" },
    { name: "<=", value: "<=" },
    { name: "is empty", value: "empty" },
    { name: "is not empty", value: "notEmpty" },
    // TO DO
    // { name: "is any of", value: "anyOf" }, 
]
const date = [
    { name: "is", value: "is" },
    { name: "is not", value: "not" },
    { name: "is after", value: "after" },
    { name: "is on or after", value: "onOrAfter" },
    { name: "is before", value: "before" },
    { name: "is on or before", value: "onOrBefore" },
    { name: "between", value: "between" },
    { name: "is empty", value: "empty" },
    { name: "is not empty", value: "notEmpty" },
]
const boolean = [{ name: "is", value: "is" }]
const singleSelect = [
    { name: "is", value: "is" },
    { name: "is not", value: "not" },
    // TO DO
    // { name: "is any of", value: "anyOf" },
]

const json = [
    { name: "contains", value: "contains" },
    { name: "equals", value: "equals" },
    { name: "is empty", value: "empty" },
    { name: "is not empty", value: "notEmpty" },
]

const insertOperators = (columnType) => {
    switch (columnType) {
        case "number":
            return number
        case "date":
            return date
        case "datetime-local":
            return date
        case "boolean":
            return boolean
        case "json":
            return json
        case "select":
            return singleSelect
        case "multi-select":
            return singleSelect
        case "string":
        default:
            return string
    }
}

function customFilterPanel({
    column, onSelectFilterMode
}) {
    const columnType = column?.columnDef?.filterVariant
    const operatorValues = insertOperators(columnType)

    // RENDER
    return operatorValues?.map((item)=> (
            <MenuItem
              key={item?.value}
              onClick={() => onSelectFilterMode(`${item?.value}`)}
            >
              {item?.name}
            </MenuItem>
        ))

}

export default customFilterPanel
