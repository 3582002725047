import {
    Avatar,
    Box,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"
import Activities from "../../Component/activities.jsx"
import HasPermission from "../../Utils/access.js"

// Driver Details View Component
function DriverDetails() {
    const { state } = useLocation()

    return (
        <Grid
            container
            sx={{
                bgcolor: "#fff",
            }}
        >
            <Grid item sm={HasPermission("get user activities")? 7 : 12} xs={12} sx={{ mx: 2, mt: 2 }}>
                <Typography
                    sx={{
                        fontWeight: "bold",
                        fontSize: 20,
                        ml: { xs: 0, sm: 2 },
                    }}
                >
                    Profile
                </Typography>
                <Grid item>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            my: 2,
                        }}
                    >
                        <Avatar
                            sx={{
                                width: { sm: 100, xs: 60 },
                                height: { sm: 100, xs: 60 },
                            }}
                            src={state.driverData?.image_url}
                        />
                    </Box>
                </Grid>
                <Grid container>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Driver Name
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.full_name}
                                disabled
                                multiline
                                fullWidth
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Phone
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.phone}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Email
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.email}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                License Number
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.license_number}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Vehicle Model & Type
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={ state.driverData?.model ? `${state.driverData?.model}, ${state.driverData?.vehicle_type}` : state.driverData?.vehicle_type}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Vehicle Color
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.color}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Plate Number
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.plate_number}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Board Number
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.board_number}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={5.25}
                        sm={12}
                        xs={12}
                        sx={{ mx: { xs: 0, sm: 2 } }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mb: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    color: "#9f9b94",
                                }}
                            >
                                Vehicle Number Seats
                            </Typography>
                            <TextField
                                size="small"
                                variant="outlined"
                                defaultValue={state.driverData?.number_of_seat}
                                disabled
                                multiline
                                sx={{
                                    fontSize: 14,
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                        fontWeight: "bold",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {HasPermission("get user activities") && (
            <>
            <Divider
                orientation="vertical"
                flexItem
                sx={{ mt: { sm: 3 }, mb: { sm: 2 } }}
            />
            <Grid
                item
                sm={4}
                xs={12}
                sx={{ mt: 2, ml: { xs: 0, md: 3, sm: 1 } }}
            >
                <Activities
                    key={`driver_activities_${state.driverData?.user_id}`}
                    url={`users/${state.driverData?.user_id}/activities`}
                />
            </Grid>
            </>
            )}
        </Grid>
    )
}
export default DriverDetails
