import React from "react"
import { Navigate } from "react-router-dom"
import useAuth from "../Hooks/useAuth.js"

function PrivateRoute({ children }) {
    const { auth } = useAuth()

    if (!auth.access_token) {
        return <Navigate to="/login" />
    }
    return children
}

export default PrivateRoute
