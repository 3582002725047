/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useRef, useCallback, useEffect, useMemo } from "react"
import { useMutation, useQuery } from "react-query"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Delete, Edit, Refresh, Visibility } from "@mui/icons-material"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CreateRoles from "./createRoles.jsx"
import StatusButton from "../../Component/statusButton.jsx"
import EditRoles from "./editRoles.jsx"
import ViewRoleDetails from "./viewRoleDetails.jsx"
import filterModel from "../../Utils/filterModel.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"

// Roles List View Component
function Roles() {
    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        name: "contains",
        created_at: "is",
        updated_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const [view, setView] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const roleName = useRef()
    const roleData = useRef({})

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "system_role",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/roles`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get all roles"),
        }
    )
    const {
        mutate,
        data: statusUpdate,
        isLoading: updateStatusIsLoading,
        error: statusError,
    } = useMutation((role) =>
        axiosPrivate.patch(`/system/roles/${role.id}`, {
            id: role.id,
            status: role?.status,
        })
    )
    const {
        mutate: deleteRole,
        data: deleteRoleResponse,
        isLoading: deleteIsLoading,
        error: deleteError,
    } = useMutation((id) => axiosPrivate.delete(`/system/roles/${id}`))

    // EVENT HANDLERS & HELPERS
    const handelView = (role) => {
        roleData.current = role
        setView(true)
        return roleData.current
    }
    const handleUpdate = (role) => {
        roleData.current = role
        setUpdate(true)
        return roleData.current
    }
    const handleClick = (role) => {
        setOpenDialog(true)
        roleName.current = role.name
    }
    const handleSwitch = useCallback(
        (role) => {
            roleName.current = role.id
            roleData.current = {
                ...roleData.current,
                id: role.id,
                status: role.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            }
            mutate(roleData.current)
        },
        [mutate]
    )
    const handleModalClose = () => {
        setOpen(false)
    }
    const handleEditModalClose = () => {
        setUpdate(false)
    }
    const handleViewModalClose = () => {
        setView(false)
    }
    const getStatus = useCallback(
        ({ cell }) => (
            <StatusButton
                loading={updateStatusIsLoading}
                status={cell.getValue()}
                unauthorized={
                    cell.row.original.name === "service-admin" ||
                    !HasPermission("update role status")
                }
                onChange={() => handleSwitch(cell?.row?.original)}
            />
        ),
        [handleSwitch, updateStatusIsLoading]
    )
    const actions = useCallback(
        ({ cell }) => (
            <Box sx={{ flex: 1 }}>
                <Button
                    disabled={
                        !(
                            HasPermission("get role detail") &&
                            HasPermission("get all permissions")
                        )
                    }
                    sx={{
                        minWidth: 24,
                        p: 1,
                        color: "primary.main",
                    }}
                    onClick={() => handelView(cell?.row?.original)}
                >
                    <Visibility />
                </Button>
                <Button
                    disabled={
                        !(
                            HasPermission("get role detail") &&
                            HasPermission("update role") &&
                            HasPermission("get all permissions")
                        )
                    }
                    sx={{ minWidth: 24, p: 0, color: "primary.main" }}
                    onClick={() => handleUpdate(cell?.row?.original)}
                >
                    <Edit />
                </Button>
                <Button
                    disabled={!HasPermission("delete role")}
                    sx={{ minWidth: 24, p: 0, color: "red" }}
                    onClick={() => {
                        handleClick(cell?.row?.original)
                        roleData.current = cell?.row?.original
                    }}
                >
                    <Delete />
                </Button>
                <div>
                    <Dialog
                        id="delete-roles-by-id"
                        open={openDialog}
                        onClose={() => setOpenDialog(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        BackdropProps={{ invisible: true }}
                        sx={{
                            "& .MuiDialog-paper": {
                                boxShadow: "0px 1px 1px 1px grey",
                            },
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            Confirm delete operation
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want delete role: `
                                {roleName.current}`?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpenDialog(false)}
                                sx={{
                                    bgcolor: "black",
                                    color: "secondary.main",
                                    ":hover": {
                                        bgcolor: "black",
                                        color: "secondary.main",
                                    },
                                    textTransform: "none",
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: "red",
                                    color: "secondary.main",
                                    ":hover": {
                                        bgcolor: "red",
                                        color: "secondary.main",
                                    },
                                    textTransform: "none",
                                }}
                                type="submit"
                                onClick={() => deleteRole(roleData.current?.id)}
                                disabled={
                                    deleteIsLoading ||
                                    !HasPermission("delete role")
                                }
                            >
                                Delete
                                {deleteIsLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "#fff",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Box>
        ),
        [deleteIsLoading, deleteRole, openDialog]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // USEEFFECT
    useEffect(() => {
        if (statusUpdate) {
            enqueueSnackbar(`Succesfully changed status.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
        }
    }, [statusUpdate, enqueueSnackbar, refetch])
    useEffect(() => {
        if (statusError) {
            enqueueSnackbar(
                statusError?.response?.data?.error?.message ||
                    statusError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
        }
    }, [statusError, enqueueSnackbar])
    useEffect(() => {
        if (deleteRoleResponse) {
            enqueueSnackbar(`Succesfully deleted.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
            setOpenDialog(false)
        }
    }, [deleteRoleResponse, enqueueSnackbar, refetch])
    useEffect(() => {
        if (deleteError) {
            enqueueSnackbar(
                deleteError?.response?.data?.error?.message ||
                    deleteError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
            setOpenDialog(false)
        }
    }, [deleteError, enqueueSnackbar])

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                accessorKey: "name",
                header: "Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "created_at",
                header: "Created At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Updated At",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getStatus]
    )

    // RENDERING
    if (!HasPermission("get all roles")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <CreateRoles
                    handleModalClose={handleModalClose}
                    refetch={refetch}
                    open={open}
                />
            )}
            {update && (
                <EditRoles
                    handleEditModalClose={handleEditModalClose}
                    roleData={roleData.current}
                    refetch={refetch}
                    update={update}
                />
            )}
            {view && (
                <ViewRoleDetails
                    handleViewModalClose={handleViewModalClose}
                    roleData={roleData.current}
                    view={view}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, id, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("create role") && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Add Role
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                formatter={handleDateExport}
                                page="/system/roles"
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default Roles
