const metaConfig = {
    "/receipts/:receiptId": {
        title: "Receipt",
        description: "View detailed information about your transaction",
    },
    "/TIN-filtered-receipts/:receiptId": {
        title: "Receipt",
        description: "View detailed information about your transaction",
    },
    "/redeemPoints/:phone": {
        title: "Redeem Points",
        description: "Redeem loyalty points for exclusive rewards",
    }
}

export default metaConfig;