export const BASE_URL = process.env.REACT_APP_BASE_URL
export const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL
export const SSO_BASE_URL = process.env.REACT_APP_SSO_BASE_URL
export const SSO_API_BASE_URL = process.env.REACT_APP_SSO_API_BASE_URL
export const RP_ASSETS_URL = "assets"
export const SSO_ASSETS_URL = "assets/profile_picture"
export const VERSION = "v2";
export const SSO_VERSION = "v1";
export const PLUS_COMMISSION_ACCOUNT = "2000-1d22c995-def5-4c68-bb9b-2e5c06748ee8"
export const EARNED_BALANCE_ACCOUNT = "2000-c95e7f80-adb3-4f0d-980f-de43f800131f"
export const RIDE_COMMISSION_ACCOUNT = "2000-9463049e-b8c9-4deb-b923-01f922baadec"
