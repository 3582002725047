

import { DateTime } from "luxon";
import { SSO_API_BASE_URL, SSO_ASSETS_URL, SSO_VERSION } from "../config.js";
import { roundToThreeDecimalPlaces, roundToTwoDecimalPlaces } from "../dataFormat.js";

export const handleInvoicesExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        due_date: DateTime.fromISO(item.due_date).toFormat("ff"),
    }))
const handleDistance = (distance) => distance > 1000
    ? `${roundToThreeDecimalPlaces(Number(distance) / 1000)
    } km`
    : `${roundToTwoDecimalPlaces(distance)} m`
export const handleCommissionTransactionsExport = (data) =>
    data?.map((item) => ({
        ...item.transaction,
        created_at: DateTime.fromISO(
            item?.transaction?.created_at
        ).toLocaleString(DateTime.DATETIME_MED),
        updated_at: item?.transaction?.updated_at
            ? DateTime.fromISO(item?.transaction?.updated_at).toLocaleString(
                DateTime.DATETIME_MED
            )
            : "",
        driver_profile_picture: item?.transaction?.driver_profile_picture ? `${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${item?.transaction?.detail?.driver_profile_picture}` : "",
        driver_name: item?.transaction?.detail?.driver_name,
        driver_phone: `${item?.transaction?.detail?.driver_phone}`,
        amount: `${item?.transaction?.detail?.amount}`,
        method: item?.transaction?.detail?.method ? `${item?.transaction?.detail?.method}` : "",
        program_name: item?.transaction?.detail?.program_name ? item?.transaction?.detail?.program_name : "",
        program_type: item?.transaction?.detail?.package_type ? item?.transaction?.detail?.package_type : "",
        program_owner_name: item?.transaction?.detail?.program_owner_name ? item?.transaction?.detail?.program_owner_name : "",
        program_owner_phone: item?.transaction?.detail?.program_owner_phone ? item?.transaction?.detail?.program_owner_phone : "",
        passenger_name: item?.transaction?.detail?.passenger_name ? item?.transaction?.detail?.passenger_name : "",
        passenger_phone: item?.transaction?.detail?.passenger_phone ? item?.transaction?.detail?.passenger_phone : "",
        rating: item?.transaction?.detail?.rating ? item?.transaction?.detail?.rating : "",
        tip: item?.transaction?.detail?.tip ? item?.transaction?.detail?.tip : "",
        detail: "",
    }))
export const handleProgramTransactionsExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(
            item?.created_at
        ).toLocaleString(DateTime.DATETIME_MED),
        updated_at: item?.updated_at
            ? DateTime.fromISO(item?.updated_at).toLocaleString(
                DateTime.DATETIME_MED
            )
            : "",
        driver_profile_picture: item?.driver_profile_picture ? `${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${item?.detail?.driver_profile_picture}` : "",
        driver_name: item?.detail?.driver_name,
        driver_phone: `${item?.detail?.driver_phone}`,
        amount: `${item?.detail?.amount}`,
        method: item?.detail?.method ? `${item?.detail?.method}` : "",
        program_name: item?.detail?.program_name ? item?.detail?.program_name : "",
        program_type: item?.detail?.package_type ? item?.detail?.package_type : "",
        program_owner_name: item?.detail?.program_owner_name ? item?.detail?.program_owner_name : "",
        program_owner_phone: item?.detail?.program_owner_phone ? item?.detail?.program_owner_phone : "",
        passenger_name: item?.detail?.passenger_name ? item?.detail?.passenger_name : "",
        passenger_phone: item?.detail?.passenger_phone ? item?.detail?.passenger_phone : "",
        rating: item?.detail?.rating ? item?.detail?.rating : "",
        tip: item?.detail?.tip ? item?.detail?.tip : "",
        dropoff_name: item.detail?.dropoff_name ? item?.detail?.dropoff_name : "",
        pickup_name: item.detail?.pickup_name ? item?.detail?.pickup_name : "",
        dropoff_time: item.detail?.dropoff_time ? item?.detail?.dropoff_time : "",
        pickup_time: item.detail?.pickup_time ? item?.detail?.pickup_time : "",
        trip_type: item.detail?.trip_type ? item?.detail?.trip_type : "",
        detail: "",
    }))
const handleTransactionsExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(
            item?.created_at
        ).toLocaleString(DateTime.DATETIME_MED),
        updated_at: item?.updated_at
            ? DateTime.fromISO(item?.updated_at).toLocaleString(
                DateTime.DATETIME_MED
            )
            : "",
        driver_profile_picture: item?.driver_profile_picture ? `${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${item?.detail?.driver_profile_picture}` : "",
        driver_name: item?.detail?.driver_name,
        driver_phone: `${item?.detail?.driver_phone}`,
        passenger_name: item?.detail?.passenger_name,
        passenger_phone: `${item?.detail?.passenger_phone}`,
        distance: item?.detail?.trip_type === "Ride Trip" ? `${roundToThreeDecimalPlaces(
            Number(item?.detail?.distance)
        )} km` : handleDistance(item?.detail?.distance),
        amount: roundToTwoDecimalPlaces(
            Number(item.detail?.amount ?? 0)
        ),
        tip: roundToTwoDecimalPlaces(Number(item.detail?.tip ?? 0)
        ),
        program: item?.detail?.program_name,
        payment_type: item?.detail?.type,
        dropoff_name: item.detail?.dropoff_name ? item?.detail?.dropoff_name : "",
        pickup_name: item.detail?.pickup_name ? item?.detail?.pickup_name : "",
        dropoff_time: item.detail?.dropoff_time ? item?.detail?.dropoff_time : "",
        pickup_time: item.detail?.pickup_time ? item?.detail?.pickup_time : "",
        trip_type: item.detail?.trip_type ? item?.detail?.trip_type : "",
        detail: "",
    }))
export default handleTransactionsExport