import React from "react"
import { Box, IconButton, Link, Tooltip } from "@mui/material"
import PlayStoreIcon from "../Assets/playstore.svg"
import AppStoreIcon from "../Assets/appstore.svg"

export default function StoreLinks() {
    return (
        <Box display="flex" justifyContent="center" gap={1.5}>
            {/* Google Play Store Link */}
            <Tooltip title="RIDE Plus Play Store Link">
                <Link
                    href="https://play.google.com/store/apps/details?id=com.ridetm&hl=end"
                    target="_blank"
                >
                    <IconButton>
                        <img
                            src={PlayStoreIcon}
                            alt="Get it on Google Play"
                            style={{ width: 40, height: 40 }}
                        />
                    </IconButton>
                </Link>
            </Tooltip>
            {/* Apple App Store Link */}
            <Tooltip title="RIDE Plus App Store Link">
                <Link
                    href="https://apps.apple.com/us/app/ride-plus/id6443848297"
                    target="_blank"
                >
                    <IconButton>
                        <img
                            src={AppStoreIcon}
                            alt="Download on the App Store"
                            style={{ width: 40, height: 40 }}
                        />
                    </IconButton>
                </Link>
            </Tooltip>
        </Box>
    )
}
