import React from "react"
import useAuth from "../Hooks/useAuth.js"
import { SSO_BASE_URL } from "../Utils/config.js"
import utils from "../Utils/login_redirect.js"

function IPIframe() {
    const { sessionState } = useAuth()
    let stat = "unchanged"
    const mes = `6f64e789-1d24-4498-a089-838a7c021270 ${sessionState}`
    const targetOrigin = `${SSO_BASE_URL}` // Validates origin
    const opFrameId = "opIframe"
    let timerID = 0

    function checkSession() {
        const win = window.parent.frames[opFrameId].contentWindow
        win.postMessage(mes, targetOrigin)
    }

    function setTimer() {
        checkSession()
        timerID = setInterval(checkSession, 5 * 1000)
    }

    // eslint-disable-next-line no-use-before-define
    window.addEventListener("message", receiveMessage, false)

    function receiveMessage(e) {
        if (e.origin !== targetOrigin) {
            return
        }

        stat = e.data

        if (stat === "changed") {
            clearInterval(timerID)
            // ask for grant with prompt none
            utils.LoginRedirect("none")
            // if success update refresh and access tokens
            // if failed remove refresh and access tokens
        } else if (stat === "error") {
            clearInterval(timerID)
        } else {
            console.log("no change")
        }
    }

    setTimer()

    return <div>You can not see me</div>
}

export default IPIframe
