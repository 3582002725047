/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tab,
    Tooltip,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import { useQuery, useQueryClient } from "react-query"
import { useReactToPrint } from "react-to-print"
import { Close, DateRangeOutlined } from "@mui/icons-material"
import { Box } from "@mui/system"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import TripsCount from "../../Component/tripsCount.jsx"
import CustomDatePicker from "../../Component/customDatePicker.jsx"

// Street pickup report generating dialog component
function GenerateStreetPickupReport({ open, setOpen }) {
    const queryClient = useQueryClient()

    // USESTATE & USEREF
    const [openGenerated, setOpenGenerated] = useState(false)
    const [fromDate, setFrom] = useState(null)
    const [toDate, setTo] = useState(null)
    const [report, setReport] = useState(null)
    const [value, setValue] = useState("1")
    const componentRef = useRef()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data } = useQuery(
        ["street_pickup_report"],
        () =>
            axiosPrivate.get(`/system/trips/report`, {
                params: {
                    filter:
                        !!fromDate && !!toDate
                            ? JSON.stringify(
                                  [
                                      {
                                          column_field: "created_at",
                                          operator_value: "between",
                                          value: `${DateTime.fromISO(
                                              fromDate?.toISOString()
                                          ).toISODate()}|${DateTime.fromISO(
                                              toDate?.toISOString()
                                          ).toISODate()}`,
                                      },
                                  ] ?? undefined
                              )
                            : !!fromDate &&
                              JSON.stringify(
                                  [
                                      {
                                          column_field: "created_at",
                                          operator_value: "is",
                                          value: fromDate
                                              ? `${DateTime.fromISO(
                                                    fromDate?.toISOString()
                                                ).toISODate()}`
                                              : "",
                                      },
                                  ] ?? undefined
                              ),
                    link_operator:
                        toDate !== null || toDate !== undefined ? "and" : "or",
                },
            }),
        {
            onError: (error) => {
                setOpenGenerated(false)
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: (!!fromDate || (!!fromDate && !!toDate)) && openGenerated,
        }
    )

    // EVENT HANDLERS & HELPERS
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        removeAfterPrint: true,
        documentTitle:
            fromDate?.length > 10 && toDate?.length > 10
                ? `Trip report  from ${DateTime.fromISO(
                      fromDate?.toISOString()
                  ).toLocaleString(DateTime.DATE_MED)} to ${DateTime.fromISO(
                      toDate?.toISOString()
                  )
                      .plus({ hours: 23, minutes: 59 })
                      .toLocaleString(DateTime.DATE_MED)}`
                : "",
    })

    // USEEFFECTS
    useEffect(() => {
        if (data && openGenerated && !isFetching) {
            setReport(data?.data?.data?.map((item) => item))
        }

        if (!open) {
            setFrom(null)
            setTo(null)
        }
    }, [data, isFetching, open, openGenerated])

    useEffect(() => {
        setFrom(null)
        setTo(null)
    }, [value])

    useEffect(() => {
        if ((!!fromDate || (!!fromDate && !!toDate)) && openGenerated) {
            queryClient.invalidateQueries("street_pickup_report")
        }
    }, [fromDate, toDate, openGenerated, queryClient])

    // Trips Count Data
    const trips = useMemo(
        () => [
            {
                registered: report?.filter(
                    (item) => item?.status === "CREATED"
                )[0],
                onGoing: report?.filter(
                    (item) => item?.status === "STARTED"
                )[0],
                finished: report?.filter((item) => item?.status === "ENDED")[0],
                cancelled: report?.filter(
                    (item) => item?.status === "CANCELLED"
                )[0],
            },
        ],
        [report]
    )

    const tripsCount = [
        {
            status: { text: "Registered", color: "black" },
            amount: trips[0]?.registered?.count,
            average_amount: trips[0]?.registered?.average_amount,
            average_km: trips[0]?.registered?.average_km,
            total_amount: trips[0]?.registered?.total_amount,
            total_km: trips[0]?.registered?.total_km,
        },
        {
            status: { text: "Ongoing", color: "#FFDC00" },
            amount: trips[0]?.onGoing?.count,
            average_amount: trips[0]?.onGoing?.average_amount,
            average_km: trips[0]?.onGoing?.average_km,
            total_amount: trips[0]?.onGoing?.total_amount,
            total_km: trips[0]?.onGoing?.total_km,
        },
        {
            status: { text: "Finished", color: "#18D212" },
            amount: trips[0]?.finished?.count,
            average_amount: trips[0]?.finished?.average_amount,
            average_km: trips[0]?.finished?.average_km,
            total_amount: trips[0]?.finished?.total_amount,
            total_km: trips[0]?.finished?.total_km,
        },
        {
            status: { text: "Cancelled", color: "#F51212" },
            amount: trips[0]?.cancelled?.average_amount?.count,
            average_amount: trips[0]?.cancelled?.average_amount,
            average_km: trips[0]?.cancelled?.average_km,
            total_amount: trips[0]?.cancelled?.total_amount,
            total_km: trips[0]?.cancelled?.total_km,
        },
    ]

    // RENDER
    return (
        <Dialog
            fullWidth
            maxWidth={
                report !== null && openGenerated
                    ? { lg: "lg", xs: "xs", sm: "sm", md: "md" }
                    : "sm"
            }
            open={open}
            onClose={() => setOpen(false)}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    boxShadow: "0px 1px 7px #0000001A",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid container>
                    <Grid xs={11} lg={11} sm={11} xl={11} md={11}>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                            {isFetching && openGenerated && "Generating report"}
                            {report !== null &&
                                openGenerated &&
                                "Generated report"}
                            {report === null &&
                                !openGenerated &&
                                "Generate Report"}
                        </Typography>
                    </Grid>
                    <Grid sx={{ ml: report !== null && openGenerated ? 8 : 0 }}>
                        {data ? (
                            <Tooltip title="Close Modal" arrow enterDelay={50}>
                                <IconButton
                                    onClick={() => {
                                        setOpen(false)
                                        setOpenGenerated(false)
                                        setReport(null)
                                    }}
                                    sx={{ color: "#000" }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    my: 1,
                }}
                ref={componentRef}
            >
                {isFetching ? (
                    <Box
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress
                            sx={{
                                m: 5,
                                color: "#000000",
                            }}
                        />
                    </Box>
                ) : report !== null && openGenerated ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexDirection: "column",
                            width: "100%",
                            mt: 2,
                        }}
                    >
                        {value === "1" ? (
                            <Typography>
                                Trips on{" "}
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "flex-start",
                                        textAlign: "start",
                                    }}
                                >
                                    {DateTime.fromISO(
                                        fromDate?.toISOString()
                                    ).toLocaleString(DateTime.DATE_MED)}
                                </span>
                            </Typography>
                        ) : (
                            <Typography>
                                Trips between{" "}
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        alignItems: "flex-start",
                                        textAlign: "start",
                                    }}
                                >
                                    {DateTime.fromISO(
                                        fromDate?.toISOString()
                                    ).toLocaleString(DateTime.DATE_MED)}{" "}
                                    -{" "}
                                    {DateTime.fromISO(toDate?.toISOString())
                                        .plus({ hours: 23, minutes: 59 })
                                        .toLocaleString(DateTime.DATE_MED)}{" "}
                                </span>
                            </Typography>
                        )}

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                mb: 2,
                                width: "100%",
                                py: 2,
                            }}
                        >
                            <TripsCount data={tripsCount} />
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ width: "100%" }}>
                        <TabContext value={value}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <TabList
                                    onChange={handleChange}
                                    variant="fullWidth"
                                >
                                    <Tab
                                        icon={<DateRangeOutlined />}
                                        iconPosition="start"
                                        label="One day report"
                                        value="1"
                                    />
                                    <Tab
                                        icon={<DateRangeOutlined />}
                                        iconPosition="start"
                                        label="Multiple day report"
                                        value="2"
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="1" sx={{ m: 8 }}>
                                <Typography
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                >
                                    Select a date to generate one day report
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "100%",
                                            mb: 4,
                                            mt: 3,
                                        }}
                                    >
                                        <CustomDatePicker
                                            text="Date"
                                            value={fromDate}
                                            formatDate={(date) =>
                                                DateTime.fromISO(
                                                    date?.toISOString
                                                ).toLocaleString("DD")
                                            }
                                            onChange={(newValue) =>
                                                setFrom(newValue?._d)
                                            }
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </TabPanel>
                            <TabPanel
                                value="2"
                                sx={{
                                    m: 8,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                >
                                    Choose date and generate report
                                </Typography>
                                <LocalizationProvider
                                    dateAdapter={AdapterMoment}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "100%",
                                            mb: 4,
                                            mt: 3,
                                        }}
                                    >
                                        <CustomDatePicker
                                            text="From"
                                            value={fromDate}
                                            date1={toDate}
                                            onChange={(newValue) => {
                                                setFrom(newValue?._d)
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            width: "100%",
                                        }}
                                    >
                                        <CustomDatePicker
                                            text="To"
                                            value={toDate}
                                            date2={fromDate}
                                            onChange={(newValue) => {
                                                setTo(newValue?._d)
                                            }}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </TabPanel>
                        </TabContext>
                    </Box>
                )}
            </DialogContent>
            {data && openGenerated ? (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        color="inherit"
                        onClick={() => {
                            setOpenGenerated(false)
                            setReport(null)
                        }}
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            background: "#DFDFDF 0% 0% no-repeat padding-box",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                    >
                        back
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </DialogActions>
            ) : (
                <DialogActions
                    sx={{
                        backgroundColor: "#FAFAFA",
                        py: 3,
                        px: 4,
                        display: "flex",
                        justifyContent: "flex-end",
                        boxShadow: "0px 1px 7px #0000001A",
                        borderRadius: "6px",
                    }}
                >
                    <Button
                        color="inherit"
                        onClick={() => {
                            setOpen(false)
                            queryClient.invalidateQueries(
                                "street_pickup_report"
                            )
                            setReport(null)
                        }}
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            background: "#DFDFDF 0% 0% no-repeat padding-box",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ px: 1 }} />
                    <Button
                        disabled={isFetching}
                        variant="contained"
                        sx={{
                            width: 120,
                            fontWeight: "bold",
                            color: "#fff",
                            boxShadow: " 0px 2px 5px #00000033",
                            borderRadius: "8px",
                            textTransform: "capitalize",
                        }}
                        onClick={() => {
                            setOpenGenerated(true)
                        }}
                    >
                        Generate
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}
export default GenerateStreetPickupReport
