/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"
import { Refresh } from "@mui/icons-material"

import HasPermission from "../../../Utils/access.js"
import filterModel from "../../../Utils/filterModel.js"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"
import handleDateExport from "../../../Utils/File Export Formatter/dateFilterFormat.js"

import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomExportOptions from "../../../Component/customExport.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"

// Agent Transactions List Component
function AgentTransactions() {
    const { userId } = useParams()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        amount: "=",
        status: "is",
        created_at: "is",
        efloat_type: "is",
        trip_type: "contains",
        reciver_party: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, error, refetch } = useQuery(
        [
            "agent_transactions",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/efloat/${userId}`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    link_operator: "and",
                },
            }),
        {
            onError: () =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    // HELPERS and EVENT
    const getType = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor: "#f5f5f5",
                    color: "#fbcf3b",
                    "&: hover": {
                        backgroundColor: "#f5f5f5",
                        color: "#fbcf3b",
                    },
                    fontWeight: "bold",
                    width: 200,
                    py: 1,
                    textTransform: "none",
                }}
            >
                {cell?.row?.original?.type}
            </Button>
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {cell?.row?.original?.status !== "" && (
                    <Button
                        key={cell?.row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor:
                                cell?.row?.original?.status === "SUCCEEDED"
                                    ? "#e5faea"
                                    : cell?.row?.original?.status ===
                                      "PROCESSING"
                                    ? "#F5F5F5"
                                    : "#ffd6d6",
                            color:
                                cell?.row?.original?.status === "SUCCEEDED"
                                    ? "#33ca51"
                                    : cell?.row?.original?.status ===
                                      "PROCESSING"
                                    ? "#FFDC00"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    cell?.row?.original?.status === "SUCCEEDED"
                                        ? "#e5faea"
                                        : cell?.row?.original?.status ===
                                          "PROCESSING"
                                        ? "#F5F5F5"
                                        : "#ffd6d6",
                                color:
                                    cell?.row?.original?.status === "SUCCEEDED"
                                        ? "#33ca51"
                                        : cell?.row?.original?.status ===
                                          "PROCESSING"
                                        ? "#FFDC00"
                                        : "red",
                            },
                            width: 120,
                            py: 1,
                        }}
                    >
                        {cell?.row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue()
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 180,
                accessorKey: "amount",
                header: "Amount",
                align: "left",
                headerAlign: "left",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    `${roundToTwoDecimalPlaces(
                        cell?.row?.original?.amount
                    )} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 250,
                accessorKey: "reciver_party",
                header: "Receiver Phone",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.reciver_party ?? "-"}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "efloat_type",
                header: "EFloat Type",
                filterVariant: "select",
                filterSelectOptions: [
                    "AirTime",
                    "E-Float Top Up",
                    "Cash in E-float Account",
                    "Cash out E-float Account",
                ],
                Cell: getType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["SUCCEEDED", "PROCESSING", "FAILED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getStatus, getType]
    )

    // RENDER
    return !HasPermission("get e-float transaction") && error ? (
        <Unauthorized />
    ) : (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select", "driver_profile_picture"],
                            right: ["status"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/transactions"
                                formatter={handleDateExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default AgentTransactions
