import React, { useMemo } from "react"
import { Box, Typography } from "@mui/material"
import MaterialReactTable from "material-react-table"
import {
    formatString,
    roundToTwoDecimalPlaces,
} from "../../Utils/dataFormat.js"

const reportType = [
    {
        type: "corporate_payment",
        color: "#26befa",
    },
    {
        type: "corporate_top_up",
        color: "#86201e",
    },
    {
        type: "ride_credit",
        color: "#1f1e1d",
    },
    {
        type: "ride_plus_credit",
        color: "#FFDC00",
    },
]
function TransactionsReport({ data }) {
    const getType = ({ row }) => (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    backgroundColor: `${
                        reportType?.filter(
                            (item) => item.type === row?.original?.report_type
                        )[0]?.color
                    }`,
                    p: 1,
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    ml: 0.5,
                    mr: 1.5,
                }}
            />
            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                }}
            >
                {formatString(row?.original?.report_type)}
            </Typography>
        </Box>
    )
    const columns = useMemo(
        () => [
            {
                accessorKey: "report_type",
                header: "",
                Cell: getType,
            },
            {
                accessorKey: "total_count",
                header: "Total Count",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.total_count ?? 0
                    )}`,
            },
            {
                accessorKey: "total_amount",
                header: "Total Amount",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.total_amount ?? 0
                    )}`,
            },
        ],
        []
    )
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <MaterialReactTable
                columns={columns}
                data={data ?? []}
                enableTopToolbar={false}
                enableColumnActions={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnResizing
                initialState={{
                    columnPinning: {
                        left: ["status"],
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                enablePinning
            />
        </Box>
    )
}
export default TransactionsReport
