import { DateTime } from "luxon"

const handleReceiptsExport = (data) =>
    data?.map((item) => ({
        id: item?.corporate_top_up?.id,
        receipt_id: item?.corporate_top_up?.receipt_id,
        process_code: item?.corporate_top_up?.process_code,
        corporate_id: item?.corporate_top_up?.corporate_id,
        created_by: item?.corporate_top_up?.created_by,
        invoice_id: item?.corporate_top_up?.invoice_id,
        accounting_transaction_id: item?.corporate_top_up?.accounting_transaction_id,
        bank_name: item?.corporate_top_up?.bank_name,
        reference_number: item?.corporate_top_up?.reference_number,
        remark: item?.corporate_top_up?.remark,
        status: item?.corporate_top_up?.status,
        created_at: DateTime.fromISO(item.corporate_top_up.created_at).toFormat("ff"),
        updated_at: DateTime.fromISO(item.corporate_top_up.updated_at).toFormat("ff"),
    }))
export default handleReceiptsExport