/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import { Edit, MoreVert, Newspaper, Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { Link, useNavigate } from "react-router-dom"
import { useSnackbar } from "notistack"

import useConfigs from "../../Hooks/useConfigs.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

import HasPermission from "../../Utils/access.js"
import filterModel from "../../Utils/filterModel.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"

import UpdateTIN from "./updateTIN.jsx"
import AddTIN from "../../Component/addTIN.jsx"
import Unauthorized from "../../Component/unauthorized.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"

// List Drivers Component
function Drivers() {
    const navigate = useNavigate()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        full_name: "contains",
        phone: "contains",
        email: "contains",
        license_number: "contains",
        plate_number: "contains",
        tin_number: "contains",
        driver_earned_balance: "=",
        ride_commission_balance: "=",
        plus_commission_balance: "=",
        created_at: "is",
        updated_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [driver, setDriver] = useState({})
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "drivers",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/drivers`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get list of drivers"),
        }
    )
    const { data: configs } = useConfigs()

    // HELPERS and EVENT HANDLERS
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={cell?.row?.original?.image_url}
            />
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                disableElevation
                variant="contained"
                sx={{
                    borderRadius: "10px",
                    fontWeight: "bold",
                    backgroundColor:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#E5FFEA"
                            : "#ffd6d6",
                    color:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#16C138"
                            : "red",
                    "&: hover": {
                        backgroundColor:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#E5FFEA"
                                : "#ffd6d6",
                        color:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#16C138"
                                : "red",
                    },
                }}
            >
                {cell?.row?.original?.status}
            </Button>
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                {HasPermission("update tin number") && (
                    <Tooltip title="Update TIN">
                        <IconButton
                            size="small"
                            sx={{ color: "primary.main" }}
                            onClick={() => {
                                setUpdate(true)
                                setDriver(row?.original)
                            }}
                            disabled={row?.original?.tin_number === ""}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                {configs?.data?.driver_tax_for_admin && (
                    <Link to={`${row.original.phone}/tax`}>
                        <Tooltip title="Tax Report">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <Newspaper />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setDriver(row?.original)
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    id={row?.original.id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {
                            navigate(`${driver?.id}/transactions`, {
                                state: { driverData: driver },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Transactions
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("get all reconcile request")}
                        onClick={() => {
                            navigate(`${driver?.id}/reconcilations`, {
                                state: { driverData: driver },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Reconcilations
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={
                            !HasPermission("get incomplete credit requests")
                        }
                        onClick={() => {
                            navigate(`${driver?.id}/incomplete-payments`, {
                                state: { driverData: driver },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Incomplete Payments
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("get all driver receipt")}
                        onClick={() => {
                            navigate(`${driver?.id}/receipts`)
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Receipts
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={
                            !HasPermission("see driver detail information")
                        }
                        onClick={() => {
                            navigate(`${driver?.id}/details`, {
                                state: { driverData: driver },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Details
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [anchorEl, configs?.data?.driver_tax_for_admin, driver, navigate]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "image_url",
                header: "Photo",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                accessorKey: "full_name",
                header: "Name",
                Cell: (cell) => `${cell?.row?.original?.full_name ?? ""}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "license_number",
                header: "License No",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "plate_number",
                header: "Plate No",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "tin_number",
                header: "TIN",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 260,
                accessorKey: "driver_earned_balance",
                header: "Earned Balance (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) => roundToTwoDecimalPlaces(cell.getValue()),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 270,
                accessorKey: "ride_commission_balance",
                header: "Ride Commission (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) => roundToTwoDecimalPlaces(cell.getValue()),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 270,
                accessorKey: "plus_commission_balance",
                header: "Plus Commission (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) => roundToTwoDecimalPlaces(cell.getValue()),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 280,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 280,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getAvatar, getStatus]
    )

    // RENDER
    if (!HasPermission("get list of drivers")) {
        return <Unauthorized />
    }
    return (
        <Box>
            {open && <AddTIN open={open} setOpen={setOpen} />}
            {update && (
                <UpdateTIN
                    open={update}
                    setOpen={setUpdate}
                    driverData={driver}
                />
            )}
            <Box sx={{ width: "100%", height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box>
                            <Button
                                size="small"
                                sx={{
                                    bgcolor: "primary.main",
                                    color: "white",
                                    px: 2,
                                    mx: 1,
                                    boxShadow: 2,
                                    " :hover": {
                                        bgcolor: "primary.main",
                                        color: "white",
                                    },
                                }}
                                onClick={() => setOpen(true)}
                            >
                                Add TIN
                            </Button>
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/drivers"
                                formatter={handleDateExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default Drivers
