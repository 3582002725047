import React, { useMemo } from "react"
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
} from "@mui/material"
import { Cancel } from "@mui/icons-material"
import { DataGrid } from "@mui/x-data-grid"
import { useQuery } from "react-query"
import { find, reject } from "lodash"

import isPhone from "../Utils/isPhone.js"
import useAssignTIN from "../Hooks/useAssignTIN.js"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"

const driver = [
    {
        field: "image_url",
        headerName: "Photo",
        headerClassName: "white-header",
        flex: 1,
        sortable: false,
    },
    {
        field: "name",
        headerName: "Name",
        headerClassName: "white-header",
        flex: 3,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "phone",
        headerName: "Phone",
        headerClassName: "white-header",
        flex: 2,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "tin_number",
        headerName: "TIN",
        headerClassName: "white-header",
        flex: 2,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "action",
        headerName: "Action",
        headerClassName: "white-header",
        flex: 1,
        sortable: false,
        headerAlign: "center",
        align: "center",
    },
]

function AddDriverTIN({ file, setFile, close }) {
    const axiosPrivate = useAxiosPrivate()

    const {
        assignDriver,
        clearAssign,
        requestAssign,
        isLoading: assigning,
    } = useAssignTIN()

    const {
        data: drivers,
        isError,
        error,
    } = useQuery(["drivers_with_tin"], () =>
        axiosPrivate
            .post(`system/driver/phone`, {
                phones: file
                    .filter((ele) => !!ele?.phone)
                    .map((el) => el.phone),
            })
            .then((res) => res.data)
    )
    const aggregated = useMemo(
        () =>
            file?.map((item1) => {
                const matchingItem = drivers?.data?.find(
                    (item2) =>
                        item2.phone === item1.phone ||
                        item2.phone.includes(item1.phone)
                )
                assignDriver({
                    driver_id: matchingItem?.id,
                    tin_number: item1.tin_number ?? null,
                })

                return {
                    ...item1,
                    name: matchingItem?.full_name ?? "",
                    tin_number: item1?.tin_number,
                }
            }) || [],
        [drivers?.data, assignDriver, file]
    )

    const handleCancel = (data) => {
        clearAssign()
        const removed = reject(file, (ele) => ele.phone === data.phone)
        setFile(removed)
    }

    const colFields = driver.map((ele) => {
        if (ele.field === "action") {
            return {
                ...ele,
                renderCell: (params) => (
                    <IconButton onClick={() => handleCancel(params?.row)}>
                        <Cancel color="red" />
                    </IconButton>
                ),
            }
        }
        if (ele.field === "image_url") {
            return {
                ...ele,
                renderCell: (params) => (
                    <Avatar
                        key={params.row.id}
                        sx={{
                            height: "100%",
                            maxHeight: "40px",
                            borderRadius: "50%",
                            objectFit: "contain",
                        }}
                        src={
                            `${
                                find(drivers?.data, (el) =>
                                    isPhone(el.phone, params.row.phone)
                                )?.image_url
                            }` || ""
                        }
                    />
                ),
            }
        }
        return ele
    })

    if (isError) {
        return (
            <Alert
                severity="error"
                sx={{ py: 2, pl: 3 }}
                onClose={() => close()}
            >
                {error.response?.data?.error?.field_error?.map(
                    (err) =>
                        `${err.name} at line ${
                            err.description
                                ? Number(err.description.split(":")[0]) + 1
                                : null
                        } is invalid`
                )}
            </Alert>
        )
    }

    return (
        <Box
            sx={{
                marginTop: file ? "1em" : null,
                "& .white-header": {
                    backgroundColor: "white",
                },
            }}
        >
            <Box sx={{ height: "250px" }}>
                <DataGrid
                    columns={colFields}
                    rows={aggregated.filter((el) => el.phone) || []}
                    hideFooter
                    disableColumnMenu
                    disableSelectionOnClick
                    getRowId={(params) => params.phone}
                    getRowHeight={({ id, densityFactor }) => {
                        if (id % 2 === 0) {
                            return 50 * densityFactor
                        }

                        return null
                    }}
                    sx={{
                        backgroundColor: "#fff",
                        boxShadow: 2,
                        border: 2,
                        borderColor: "primary.main",
                        "& .MuiDataGrid-cell:hover": {
                            color: "primary.main",
                        },
                    }}
                />
            </Box>
            <Divider sx={{ my: "1em" }} />
            <Box
                sx={{
                    display: "flex",
                    gap: "1em",
                    pr: "2em",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => close()}
                >
                    Cancel
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "primary.main" },
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    startIcon={
                        assigning ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => requestAssign(() => close())}
                >
                    Confirm
                </Button>
            </Box>
        </Box>
    )
}

export default AddDriverTIN
