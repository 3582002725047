import { find } from "lodash"

const filterModel = (filterFns, columnFields, columns) =>
    columnFields?.map((item) => {
        const operatorValue = filterFns[item.id]
        const colType = find(
            columns,
            (el) => el.accessorKey === item.id || el.id === item.id
        )?.filterVariant

        if (colType === "json")
            return {
                column_field: "detail",
                operatorValue:
                    operatorValue === "contains" ? "@>" : operatorValue,
                value:
                    operatorValue === "empty" || operatorValue === "notEmpty"
                        ? ""
                        : `{"${item.id}":"${item.value}"}`,
            }
        return {
            column_field: item.id,
            operator_value: operatorValue,
            value:
                operatorValue === "empty" || operatorValue === "notEmpty"
                    ? ""
                    : item?.value,
        }
    })

export default filterModel