import React, { useEffect, useRef, useState } from "react"
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator,
} from "@mui/lab"
import { CircularProgress, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DateTime } from "luxon"
import URL from "../Utils/url.js"
import Request, { specialRequests } from "../Utils/requests.js"
import useInfinitQueries from "../Hooks/useInfiniteQueries.js"

const itemUrl = (item) => {
    if (item.includes("end") || item.includes("start")) {
        return "trip"
    }
    if (item.includes("generate")) {
        return ""
    }
    if (item.includes("topup") && item.includes("verify")) {
        return ""
    }
    if (item.includes("topup") && item.includes("corporate")) {
        return "corporate topup"
    }
    if (item.includes("companies")) {
        return "company"
    }
    if (item.includes("companies") && item.includes("program")) {
        return "company program"
    }
    if (item.includes("corporate_top_up") && item.includes("confirm")) {
        return "corporate top up"
    }
    if (item.includes("corporate") && item.includes("top_up")) {
        return "corporate top up"
    }
    if (item.includes("topup") && item.includes("confirm")) {
        return ""
    }
    if (item.includes("payment") && item.includes("confirm")) {
        return ""
    }
    if (item.includes("cashout") && item.includes("confirm")) {
        return "cashout"
    }
    if (item.includes("passenger") && item.includes("image")) {
        return "passanger image"
    }
    if (item.includes("payment_intents")) {
        return "payment intent"
    }
    if (item.includes("trip")) {
        return "trip"
    }
    if (item.includes("user") && item.includes("role")) {
        return "user role"
    }
    if (item.includes("role")) {
        return "role"
    }
    return URL(item)
}

function Activities(props) {
    const { key, url } = props

    const [observer, setObserver] = useState(null)
    const scrollArea = useRef(null)
    const targetElement = useRef(null)

    const {
        isLoading,
        data: activities,
        fetchNextPage,
        hasNextPage,
    } = useInfinitQueries({
        key: key,
        url: url,
    })

    useEffect(() => {
        if (scrollArea && targetElement) {
            const options = {
                root: scrollArea.current,
                rootMargin: "0px",
                threshold: 0,
            }
            setObserver(
                new IntersectionObserver((entries) => {
                    if (entries[0]?.isIntersecting) fetchNextPage()
                }, options)
            )
        }
    }, [fetchNextPage])

    useEffect(() => {
        if (observer && targetElement.current) {
            observer?.observe(targetElement.current)
        }
    }, [observer, targetElement])

    const activityPages = activities?.pages?.map((el) =>
        el?.data?.data[0]?.activity_logs
            ? el?.data?.data[0]?.activity_logs?.map((item) => ({
                  ...item,
                  user_info: el?.data?.data[0]?.user_info,
              }))
            : el?.data?.data
    )
    const activitiesList = activityPages?.reduce(
        (prev, current) => [...prev, ...current],
        []
    )

    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: "bold",
                    fontSize: 20,
                    ml: 2,
                }}
            >
                Activity
            </Typography>
            <Box
                sx={{
                    height: "500px",
                    overflowY: "scroll",
                }}
                ref={scrollArea}
            >
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    {activitiesList?.map((item) => (
                        <TimelineItem key={item.id}>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography
                                    sx={{ fontSize: 14, color: "#9f9b94" }}
                                >
                                    {DateTime.fromISO(
                                        item?.created_at
                                    )?.toLocaleString(DateTime.DATETIME_MED)}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {item?.user_info?.first_name ?? ""}{" "}
                                    {item?.user_info?.middle_name ?? ""}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        color: "#5bd833",
                                    }}
                                >
                                    {item?.response_object ? (
                                        <>
                                            {specialRequests(item?.url) !== ""
                                                ? specialRequests(item?.url)
                                                : Request(item?.action)}{" "}
                                            {item?.response_object?.name ?? ""}{" "}
                                            {itemUrl(item?.url)}
                                        </>
                                    ) : (
                                        <Box>
                                            {specialRequests(item?.url) !== ""
                                                ? specialRequests(item?.url)
                                                : Request(item?.action)}{" "}
                                            {itemUrl(item?.url)}
                                        </Box>
                                    )}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {item?.url?.includes("cashout") ||
                                    item?.url?.includes("topup")
                                        ? `${
                                              item?.response_object?.amount ??
                                              ""
                                          } ETB - ${
                                              item?.response_object
                                                  ?.bank_name ?? ""
                                          }`
                                        : " "}
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                    <TimelineItem
                        sx={{
                            "&.MuiTimelineItem-root": {
                                ":before": {
                                    padding: "0px, 0px",
                                    flex: "none",
                                    "&:-webkit-flex": "none",
                                },
                            },
                        }}
                    >
                        <TimelineContent>
                            <Box
                                ref={targetElement}
                                sx={{
                                    bottom: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {(hasNextPage || isLoading) && (
                                    <CircularProgress
                                        size="1.5em"
                                        sx={{ color: "lightgray" }}
                                    />
                                )}
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Box>
        </Box>
    )
}
export default Activities
