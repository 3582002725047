import { useContext } from "react";
import AuthContext from "../Context/authProvider.jsx";

// Permission Access Validator
function HasPermission(permission) {
    const { permissions } = useContext(AuthContext);

    return !(permissions?.includes("manage-all")) ?
        (
            permissions?.includes(permission)
        ) : (
            true
        );
}
export default HasPermission;