/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useMutation, useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { useLocation } from "react-router-dom"
import { Refresh } from "@mui/icons-material"
import { DateTime } from "luxon"
import CustomExportOptions from "../../Component/customExport.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import IncompletePaymentForm from "./incompletePaymentForm.jsx"
import HasPermission from "../../Utils/access.js"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

const receiptStatusBgColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#F5F5F5"
        case "APPROVED":
            return "#E5FFEA"
        case "FAILED":
            return "#FFE5E5"
        case "REJECTED":
            return "#EFEFEF"
        default:
            return "white"
    }
}
const receiptStatusColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#FFDC00"
        case "APPROVED":
            return "#16C138"
        case "FAILED":
            return "#E11212"
        case "REJECTED":
            return "#000000"
        default:
            return "text.primary"
    }
}

// IncompletePayments List View Component
function IncompletePayments() {
    const { state } = useLocation()
    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        bank_name: "contains",
        reference_number: "contains",
        amount: "=",
        remark: "contains",
        status: "is",
        approver_first_name: "contains",
        approver_phone: "contains",
        creator_first_name: "contains",
        creator_phone: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const request = useRef({
        type: "",
        id: "",
    })

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id,
                sort: item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "incomplete_payments",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `system/drivers/${state?.driverData?.id}/credits/request`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(
                            filterModel(filterFns, columnFilters) ?? undefined
                        ),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get incomplete credit requests"),
        }
    )
    const {
        mutate: approve,
        data: approveResponse,
        isLoading: approvalIsLoading,
        error: approvalError,
    } = useMutation((status) =>
        axiosPrivate.post(
            `/system/incomplete_credit/${request.current.id}/confirm`,
            status
        )
    )

    // EVENT HANDLERS & HELPERS
    const actions = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                }}
            >
                {row?.original?.status === "PENDING" && (
                    <>
                        <Button
                            key={row?.original?.id}
                            variant="contained"
                            disabled={
                                approvalIsLoading ||
                                !HasPermission(
                                    "approve incomplete credit request"
                                )
                            }
                            sx={{
                                backgroundColor: "#16C138",
                                "&: hover": {
                                    backgroundColor: "#16C138",
                                },
                                color: "#fff",
                                textTransform: "none",
                                fontSize: 14,
                                fontWeight: "bold",
                                width: "80px",
                                height: "35px",
                            }}
                            size="small"
                            onClick={() => {
                                approve({ status: "APPROVED" })
                                request.current = {
                                    type: "APPROVE",
                                    id: row?.original?.id,
                                }
                            }}
                        >
                            Approve
                            {approvalIsLoading &&
                                request.current === "APPROVED" &&
                                request.current.id === row?.original?.id && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "black",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                        </Button>
                        <Button
                            key={row?.original?.id}
                            variant="contained"
                            sx={{
                                backgroundColor: "#E11212",
                                "&: hover": {
                                    backgroundColor: "#E11212",
                                },
                                color: "#fff",
                                textTransform: "none",
                                fontSize: 14,
                                fontWeight: "bold",
                                ml: 1,
                                width: "80px",
                                height: "35px",
                            }}
                            size="small"
                            onClick={() => {
                                approve({ status: "REJECTED" })
                                request.current = {
                                    type: "REJECTED",
                                    id: row?.original?.id,
                                }
                            }}
                        >
                            Reject
                            {approvalIsLoading &&
                                request.current === "REJECTED" &&
                                request.current.id === row?.original?.id && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "black",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                        </Button>
                    </>
                )}
            </Box>
        ),
        [approvalIsLoading, approve]
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    width: "130px",
                }}
            >
                <Alert
                    severity="info"
                    icon={false}
                    sx={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        backgroundColor: `${receiptStatusBgColor(
                            cell?.row?.original?.status
                        )}`,
                        color: `${receiptStatusColor(
                            cell?.row?.original?.status
                        )}`,
                        fontWeight: "bold",
                    }}
                >
                    {cell?.row?.original?.status?.toLowerCase()}
                </Alert>
            </Box>
        ),
        []
    )

    useEffect(() => {
        if (approveResponse) {
            enqueueSnackbar(`Your request is sent succesfully.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
        }
    }, [approveResponse, enqueueSnackbar, refetch])

    useEffect(() => {
        if (approvalError) {
            if (approvalError?.response?.data?.error?.field_error?.length > 0) {
                approvalError?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    approvalError?.response?.data?.error?.message ||
                        approvalError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, approvalError])
    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                accessorKey: "created_at",
                header: "Date",
                size: 220,
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell.getValue()).toFormat("ff"),
            },
            {
                size: 260,
                accessorKey: "creator_first_name",
                header: "Requested By",
                Cell: ({ row }) =>
                    `${row?.original?.creator_first_name ?? ""} ${
                        row?.original?.creator_middle_name ?? ""
                    } ${row?.original?.creator_last_name ?? ""}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "creator_phone",
                header: "Requester's Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "bank_name",
                header: "Bank",
            },
            {
                size: 240,
                accessorKey: "reference_number",
                header: "Reference Number",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "amount",
                header: "Amount (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "remark",
                header: "Note",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 260,
                accessorKey: "approver_first_name",
                header: "Approved By",
                Cell: ({ row }) =>
                    `${row?.original?.approver_first_name ?? ""} ${
                        row?.original?.approver_middle_name ?? ""
                    } ${row?.original?.approver_last_name ?? ""}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "approver_phone",
                header: "Approver Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: [
                    "PENDING",
                    "APPROVED",
                    "FAILED",
                    "REJECTED",
                ],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: actions,
            },
        ],
        [actions, getStatus]
    )

    // RENDERING
    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <IncompletePaymentForm
                    open={open}
                    setOpen={setOpen}
                    id={state?.driverData?.id}
                    refetch={refetch}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission(
                                "process incomplete credit request"
                            ) && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Request Payment
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <>
                                <Tooltip arrow title="Refresh Data">
                                    <IconButton onClick={() => refetch()}>
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                                <MRT_ToggleGlobalFilterButton table={table} />
                                <CustomExportOptions
                                    table={table}
                                    model={filterModel(
                                        filterFns,
                                        columnFilters
                                    )}
                                    page={`system/drivers/${state?.driverData?.id}/credits/request`}
                                    formatter={handleDateExport}
                                />
                                <MRT_ToggleFiltersButton table={table} />
                            </>
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default IncompletePayments
