import React from "react"
import { Box, Container } from "@mui/system"
import { useLocation } from "react-router-dom"
import { Avatar, Grid, Typography } from "@mui/material"

import logo from "../Assets/ride_logo.png"
import useBreakPoints from "../Hooks/useBreakPoints.js"

// Error Page Component
function ErrorPage({ error }) {
    const { pathname } = useLocation()
    const { sm, md } = useBreakPoints()

    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
            }}
        >
            <Container component="main" maxWidth="lg">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        p: 2,
                    }}
                >
                    <Avatar
                        sx={{
                            width: sm ? 200 : 150,
                            height: sm ? 180 : 135,
                            borderRadius: 0,
                        }}
                        alt="logo"
                        src={logo}
                    />
                    {pathname?.includes("tax") &&
                    error?.response?.data?.error?.code === 404 ? (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: "inline",
                                    fontSize: sm ? 30 : 20,
                                    color: "#9f9b94",
                                }}
                            >
                                You do not have earnings with{" "}
                                <strong>RIDE</strong> in Tax Season 2015/2016
                                EC. {md && <br />}
                                Please visit our office after Tikimt 1 for any
                                other information. <br />
                                Thank you!
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    display: "inline",
                                    fontSize: 30,
                                    fontWeight: "bold",
                                    color: "red",
                                }}
                            >
                                Error.
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 0.5,
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    sx={{
                                        display: "inline",
                                        fontSize: 30,
                                        color: "#9f9b94",
                                        mb: 0.5,
                                    }}
                                >
                                    {error
                                        ? `${error?.response?.data?.error?.message}` ||
                                          `${error?.message}` ||
                                          "Network Error!"
                                        : ""}
                                </Typography>
                                <Typography
                                    sx={{
                                        display: "inline",
                                        fontSize: 30,
                                    }}
                                >
                                    That is all we know.
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Container>
        </Grid>
    )
}
export default ErrorPage
