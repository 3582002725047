/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    Chip,
    IconButton,
    Tooltip,
} from "@mui/material"
import { DateTime } from "luxon"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Refresh } from "@mui/icons-material"
import { useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useMutation, useQuery } from "react-query"
import CustomExportOptions from "../../../Component/customExport.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import filterModel from "../../../Utils/filterModel.js"
import AddCompanyAdmin from "./addCompanyAdmin.jsx"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../../Utils/config.js"
import StatusButton from "../../../Component/statusButton.jsx"
import { handleSystemUsersExport } from "../../../Utils/File Export Formatter/companyUsersExport.js"

// Company System Users List View Component
function CompanySystemUsers() {
    const { state } = useLocation()

    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        email: "contains",
        phone: "contains",
        update_at: "is",
        created_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const defaultFilter = {
        column_field: "role_name",
        operator_value: "equals",
        value: "admin",
    }
    const [open, setOpen] = useState(false)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )
    const model = filterModel(filterFns, columnFilters)
    model.push(defaultFilter)

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "company_system_users",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            model,
        ],
        () =>
            axiosPrivate.get(
                `system/corporates/${state?.corporateId}/system/users`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(model ?? undefined),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("list company system users"),
        }
    )
    const {
        mutate,
        data: statusData,
        error: statusError,
        isLoading: statusIsLoading,
    } = useMutation(({ id, status, roleId }) =>
        axiosPrivate.patch(
            `/system/corporate/${state?.corporateId}/users/${id}/roles/${roleId}/status`,
            {
                status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            }
        )
    )

    // EVENT HANDLERS & HELPERS
    const handleModalClose = () => {
        setOpen(false)
    }
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.profile_picture}`}
            />
        ),
        []
    )
    const getRoles = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "inline-block",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    fontWeight: "bold",
                }}
            >
                {row?.original?.roles?.map((role) => (
                    <Chip
                        key={role?.id}
                        label={role?.role_name}
                        sx={{
                            my: 0.5,
                            mr: 0.5,
                            color: "primary.main",
                        }}
                    />
                ))}
            </Box>
        ),
        []
    )
    const getStatus = useCallback(
        ({ row }) => (
            <StatusButton
                loading={statusIsLoading}
                status={row?.original?.roles[0]?.status}
                unauthorized={
                    !HasPermission("change companies admin role status")
                }
                onChange={() => {
                    mutate({
                        id: row?.original?.id,
                        status: row?.original?.roles[0]?.status,
                        roleId: row?.original?.roles[0]?.id,
                    })
                }}
            />
        ),
        [mutate, statusIsLoading]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "photo",
                header: "Photo",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 240,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "first_name",
                header: "First Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "middle_name",
                header: "Middle Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "last_name",
                header: "Last Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "role",
                header: "Role",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: getRoles,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getAvatar, getRoles, getStatus]
    )
    useEffect(() => {
        if (statusError) {
            enqueueSnackbar(
                statusError?.response?.data?.error?.message ||
                    statusError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
        }
    }, [enqueueSnackbar, statusError])

    useEffect(() => {
        if (statusData) {
            enqueueSnackbar(`Succesfully changed status.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
        }
    }, [statusData, enqueueSnackbar, refetch])
    useEffect(() => {
        if (statusError) {
            enqueueSnackbar(
                statusError?.response?.data?.error?.message ||
                    statusError?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
        }
    }, [enqueueSnackbar, statusError])

    // RENDERING
    if (!HasPermission("list company system users")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <AddCompanyAdmin
                    handleModalClose={handleModalClose}
                    refetch={refetch}
                    open={open}
                    companyId={state?.corporateId}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, id, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("assign admin role") && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Add Company Admin
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                page={`system/corporates/${state?.corporateId}/system/users`}
                                formatter={handleSystemUsersExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default CompanySystemUsers
