import React, { useState } from "react"
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Divider,
    TextField,
    Typography,
} from "@mui/material"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"

function Agent({ name, phone, src }) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#f7f7f7",
                py: "1em",
                px: "1em",
            }}
        >
            <Avatar
                style={{
                    height: "2em",
                    width: "2em",
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
                src={src || " "}
            />
            <Typography sx={{ fontSize: "1em", textAlign: "center" }}>
                {name}
            </Typography>
            <Typography sx={{ fontSize: "1em" }}>+{phone}</Typography>
        </Box>
    )
}

function AddSingleAgentCode({ search, close }) {
    const [shortCode, setShortCode] = useState("")

    const queryClient = useQueryClient()

    const axiosPrivate = useAxiosPrivate()

    const { enqueueSnackbar } = useSnackbar()

    const {
        data: driver,
        isLoading,
        isError,
    } = useQuery(
        ["assign_single_driver_code", search],
        () =>
            axiosPrivate
                .get(`system/drivers/phone/${search}`)
                .then((res) => res.data),
        {
            enabled: !!search,
        }
    )

    const { mutate: assignShortCode, isLoading: assigning } = useMutation(
        (fromData) =>
            axiosPrivate.post(
                `system/agent/register
        `,
                fromData
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["agents"],
                })
                enqueueSnackbar("Successfully Added", {
                    variant: "success",
                })
                close()
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError ||
                        errorMessage ||
                        "Couldn't assign the short code. Try again later please",
                    { variant: "error" }
                )
            },
        }
    )

    const handleAssign = () => {
        const assignmentData = {
            user_id: driver?.data?.user_id,
            short_code: shortCode,
        }
        assignShortCode(assignmentData)
    }

    if (isLoading)
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        )
    if (isError)
        return (
            <Alert
                severity="error"
                sx={{ py: 2, pl: 3 }}
                onClose={() => close()}
            >
                The provided phone does not exist, please try again.
            </Alert>
        )

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <Box>
                {driver?.data ? (
                    <Agent
                        name={driver?.data?.full_name}
                        phone={driver?.data?.phone}
                        src={driver?.data?.image_url}
                    />
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            gap: "1em",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Alert severity="warning" sx={{ p: 2 }}>
                            A driver with the provided phone does not exist in
                            RidePlus.
                        </Alert>
                    </Box>
                )}
            </Box>
            <Box component="form" autoComplete="off">
                <TextField
                    label="Short Code"
                    value={shortCode}
                    fullWidth
                    size="small"
                    onChange={(e) => setShortCode(e.target.value)}
                />
            </Box>
            <Divider sx={{ my: "1em" }} />
            <Box
                sx={{
                    display: "flex",
                    gap: "1em",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => close()}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "primary.main" },
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    startIcon={
                        assigning ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => handleAssign()}
                >
                    Add
                </Button>
            </Box>
        </Box>
    )
}

export default AddSingleAgentCode
