/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Avatar, Box, Button, IconButton, Tooltip } from "@mui/material"
import { AttachMoney, Edit, Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { Link } from "react-router-dom"

import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../../Utils/config.js"
import HasPermission from "../../../Utils/access.js"
import filterModel from "../../../Utils/filterModel.js"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"

import Unauthorized from "../../../Component/unauthorized.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomExportOptions from "../../../Component/customExport.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import AddAgentCode from "./addAgentCode.jsx"
import UpdateAgentCode from "./updateAgentCode.jsx"

// List Agents Component
function Agents() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        full_name: "contains",
        phone: "contains",
        short_code: "contains",
        created_at: "is",
        updated_at: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const [update, setUpdate] = useState(false)
    const agentData = useRef()

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "agents",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/efloat/agents`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get all agents"),
        }
    )

    // HELPERS and EVENT HANDLERS
    const handleExport = (agent) =>
        agent?.map((element) => ({
            created_at: DateTime.fromISO(element?.created_at).toFormat("ff"),
            short_code: element?.short_code,
            full_name: element?.full_name,
            phone: element?.user?.phone,
            updated_at: DateTime.fromISO(element?.updated_at).toFormat("ff"),
        }))
    const getAvatar = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 1,
                }}
            >
                <Avatar
                    sx={{
                        width: 50,
                        height: 50,
                    }}
                    src={
                        row?.original?.user?.profile_picture?.includes("https")
                            ? row?.original?.user?.profile_picture
                            : `${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${row?.original?.user?.profile_picture}`
                    }
                />
                {row?.original?.full_name}
            </Box>
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box id={row?.original?.id} sx={{ flex: 1 }}>
                {HasPermission("update agent data") && (
                    <Tooltip title="Update Agent Code">
                        <IconButton
                            size="small"
                            sx={{ color: "primary.main" }}
                            onClick={() => {
                                setUpdate(true)
                                agentData.current = row?.original
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip>
                )}
                {HasPermission("get e-float transaction") && (
                    <Link to={`${row.original.user_id}/transactions`}>
                        <Tooltip title="Transactions">
                            <IconButton
                                size="small"
                                sx={{ color: "primary.main" }}
                            >
                                <AttachMoney />
                            </IconButton>
                        </Tooltip>
                    </Link>
                )}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 280,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 180,
                accessorKey: "short_code",
                header: "Short Code",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 280,
                accessorKey: "full_name",
                header: "Agent",
                flex: 1,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Phone",
                Cell: ({ row }) => row?.original?.user?.phone,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 280,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getAvatar]
    )

    // RENDER
    if (!HasPermission("get all agents")) {
        return <Unauthorized />
    }
    return (
        <Box>
            {open && <AddAgentCode open={open} setOpen={setOpen} />}
            {update && (
                <UpdateAgentCode
                    open={update}
                    setOpen={setUpdate}
                    agentData={agentData.current}
                />
            )}
            <Box sx={{ width: "100%", height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box>
                            {HasPermission("agent register") &&
                                HasPermission("upload agent") && (
                                    <Button
                                        size="small"
                                        sx={{
                                            bgcolor: "primary.main",
                                            color: "white",
                                            px: 2,
                                            mx: 1,
                                            boxShadow: 2,
                                            " :hover": {
                                                bgcolor: "primary.main",
                                                color: "white",
                                            },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        Add Agent(s)
                                    </Button>
                                )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/efloat/agents"
                                formatter={handleExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default Agents
