import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import { CloudUpload } from "@mui/icons-material"
import { useMutation } from "react-query"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import Dropzone from "../../Component/dragAndDrop.jsx"

// Add Corporate Component
function AddCorporates({ handleModalClose, open, refetch }) {
    // USESTATE
    const [files, setFiles] = useState([])
    const [licenseFileName, setLicenseFileName] = useState()
    const [logoErrorMessage, setLogoErrorMessage] = useState(null)

    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { dirtyFields, errors },
        setValue,
    } = useForm({ mode: "onSubmit", defaultValues: { license_file: "" } })

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION
    const {
        mutate,
        isLoading,
        data: corporateData,
        error,
    } = useMutation((formData) =>
        axiosPrivate.post(`/system/companies`, formData)
    )
    const { mutate: logoUpload } = useMutation((logoDocument) =>
        axiosPrivate.post(`/assets`, logoDocument, {
            "Content-Type": "multipart/form-data",
            type: "formData",
        })
    )
    const {
        mutate: licenseUpload,
        isLoading: licenseUploadIsLoading,
        data: licenseUploadFile,
        error: licenseFileUploadError,
    } = useMutation((licenseDocument) =>
        axiosPrivate.post(`assets`, licenseDocument, {
            "Content-Type": "multipart/form-data",
            type: "formData",
        })
    )

    // HELPERS and EVENT HANDLERS
    const handleLicenseFileUpload = (fileInput) => {
        setLicenseFileName(fileInput?.name)
        const licenseFormData = new FormData()
        licenseFormData.set("asset", fileInput)
        licenseFormData.set("type", "company_license")
        licenseUpload(licenseFormData)
    }
    const onSubmit = (form) => {
        if (logoErrorMessage !== null) {
            return
        }
        if (files?.length !== 1) {
            enqueueSnackbar("Company logo is required!", {
                variant: "error",
            })
            return
        }

        if (files?.[0]?.path) {
            const formData = new FormData()
            formData.append("asset", files[0])
            formData.append("type", "company_logo")

            logoUpload(formData, {
                onSuccess: (data) => {
                    setValue("logo", data?.data?.data)
                    return mutate({
                        ...form,
                        logo: data?.data?.data,
                        license_file: licenseUploadFile?.data?.data,
                    })
                },
                onError: (logoError) =>
                    enqueueSnackbar(
                        logoError?.response?.data?.error?.message ||
                            logoError?.message ||
                            "Network Error!",
                        {
                            variant: "error",
                        }
                    ),
            })
        }
    }

    // USEEFFECT
    useEffect(() => {
        if (
            files?.length > 0 &&
            !files?.[0]?.name?.match(/\.(png|jpg|jpeg)$/)
        ) {
            setFiles([])
            setLogoErrorMessage(
                "Company logo must be one of the following types: png, jpg or jpeg!"
            )
        }
        if (files?.[0]?.name?.match(/\.(png|jpg|jpeg)$/))
            setLogoErrorMessage(null)
    }, [enqueueSnackbar, files])

    useEffect(() => {
        if (licenseUploadFile) {
            setValue("license_file", licenseFileName)
        }
    }, [licenseFileName, licenseUploadFile, setValue])
    useEffect(() => {
        if (corporateData) {
            enqueueSnackbar(`Succesfully created a new corporate.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            handleModalClose()
            refetch()
        }
    }, [corporateData, enqueueSnackbar, handleModalClose, refetch])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])
    useEffect(() => {
        if (licenseFileUploadError) {
            if (
                licenseFileUploadError?.response?.data?.error?.field_error
                    ?.length > 0
            ) {
                licenseFileUploadError?.response?.data?.error?.field_error?.map(
                    (msg) =>
                        enqueueSnackbar(msg.description || "Network Error!", {
                            variant: "error",
                            preventDuplicate: true,
                            autoHideDuration: 3000,
                        })
                )
            } else {
                enqueueSnackbar(
                    licenseFileUploadError?.response?.data?.error?.message ||
                        licenseFileUploadError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 3000,
                    }
                )
            }
            setValue("license_file", null)
        }
    }, [enqueueSnackbar, licenseFileUploadError, setValue])

    // RENDER
    return (
        <Dialog maxWidth="md" open={open} onClose={handleModalClose}>
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Add Corporate
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 2,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            mt: 2,
                        }}
                    >
                        <Dropzone
                            files={files?.[0]?.preview}
                            setFiles={setFiles}
                            errors={logoErrorMessage}
                        />
                    </Box>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: "Company name is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Corporate Name"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="name"
                                        error={!!errors?.name}
                                        helperText={
                                            errors?.name
                                                ? errors.name.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    required: "Company Phone is required",
                                    minLength: 9,
                                    maxLength: 13,
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Phone Number"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.phone}
                                        helperText={
                                            errors?.phone
                                                ? errors.phone?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Company email is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                        autoComplete="email"
                                        error={!!errors?.email}
                                        helperText={
                                            errors?.email
                                                ? errors.email.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: "Company address is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Address"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.address}
                                        helperText={
                                            errors?.address
                                                ? errors.address?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="sub_city"
                                control={control}
                                rules={{
                                    required: "Company sub-city is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="Sub-City"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.sub_city}
                                        helperText={
                                            errors?.sub_city
                                                ? errors.sub_city?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="house_number"
                                control={control}
                                rules={{
                                    required:
                                        "Company house number is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        label="House Number"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.house_number}
                                        helperText={
                                            errors?.house_number
                                                ? errors.house_number?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="license_number"
                                control={control}
                                rules={{
                                    required:
                                        "Company license number is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="License Number"
                                        fullWidth
                                        autoComplete="license_number"
                                        error={!!errors?.license_number}
                                        helperText={
                                            errors?.license_number
                                                ? errors.license_number.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 1.5,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="license_file"
                                control={control}
                                rules={{
                                    required:
                                        "Company license document is required",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="License Document"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        autoComplete="license_file"
                                        error={!!errors?.license_file}
                                        helperText={
                                            errors?.license_file &&
                                            dirtyFields?.license_file ===
                                                undefined
                                                ? errors.license_file.message
                                                : null
                                        }
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: 1,
                                            flex: 1,
                                        }}
                                        {...field}
                                        InputProps={{
                                            endAdornment: (
                                                <label htmlFor="license-file-input">
                                                    <Button
                                                        sx={{
                                                            mr: -2,
                                                        }}
                                                        component="label"
                                                        disabled={
                                                            licenseUploadIsLoading
                                                        }
                                                    >
                                                        {licenseUploadIsLoading ? (
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <CloudUpload />
                                                        )}
                                                        <input
                                                            id="license-file-input"
                                                            type="file"
                                                            hidden
                                                            onChange={(e) =>
                                                                handleLicenseFileUpload(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            }
                                                        />
                                                    </Button>
                                                </label>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="account_type"
                                control={control}
                                rules={{
                                    required:
                                        "Company account type is required.",
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        label="Account Type"
                                        margin="normal"
                                        fullWidth
                                        select
                                        error={!!errors?.account_type}
                                        helperText={
                                            errors?.account_type
                                                ? errors.account_type?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    >
                                        <MenuItem value="PREPAID">
                                            Prepaid
                                        </MenuItem>
                                        <MenuItem value="POSTPAID">
                                            Postpaid
                                        </MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controller
                                name="service_charge"
                                control={control}
                                rules={{
                                    required:
                                        "Company service charge is required",
                                    validate: (value) => {
                                        if (value < 0) {
                                            return "Service charge must be a positive number"
                                        }
                                        return true
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        inputProps={{
                                            step: "0.01",
                                            min: "0",
                                        }}
                                        label="Service Charge"
                                        margin="normal"
                                        fullWidth
                                        error={!!errors?.service_charge}
                                        helperText={
                                            errors?.service_charge
                                                ? errors.service_charge?.message
                                                : null
                                        }
                                        size="small"
                                        sx={{
                                            mt: 1.5,
                                            mb: 0.5,
                                            mr: -2,
                                            flex: 2,
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={handleModalClose}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "#fdbc3d",
                        ":hover": { bgcolor: "#fdbc3d" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Add
                    </Typography>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                color: "black",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddCorporates
