import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "@mui/material"
import { compact } from "lodash"

function CustomeTabs({ tabs, onChange }) {
    const cleanTabs = compact(tabs)
    const [selected, setSelected] = useState(cleanTabs[0]?.value)

    const handleChange = (e, val) => {
        setSelected(val)
    }

    useEffect(() => {
        onChange(selected)
    }, [selected, onChange])

    return (
        <Tabs
            value={selected}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
        >
            {cleanTabs.map((tab) => (
                <Tab value={`${tab?.value}`} label={`${tab?.label}`} />
            ))}
        </Tabs>
    )
}

export default CustomeTabs
