import { DateTime } from "luxon";

const updatedAt = (item) => {
    if (item?.updated_at?.Time) DateTime.fromISO(item.updated_at?.Time).toFormat("ff")
    if (item?.updatedAt) DateTime.fromISO(item.updated_at).toFormat("ff")
    return ""
}
const handleDateExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        updated_at: updatedAt(item.updated_at),
        deleted_at: item?.deleted_at ? DateTime.fromISO(item.deleted_at).toFormat("ff") : "",
    }))
export default handleDateExport