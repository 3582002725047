import { DateTime } from "luxon"

const handleReceiptExport = (data) =>
    data?.map((item) => ({
        date: DateTime.fromISO(item.created_at).toFormat("ff"),
        // fs_number: item.fs_number,
        invoice_number: `CA-${item.receipt_number?.toString().padStart(8, "0")}`,
        customer_name: item.full_name,
        customer_phone: item.user.phone,
        tin: item.service_number,
        amount: item.amount,
        vat: item.vat,
        type: item.type,
        last_updated: item?.updated_at ? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
    }))
export default handleReceiptExport