import { DateTime } from "luxon";

const mapDateLimitWeekDays = (item) => {
    if (item?.date_limit === false)
        return "No Date Limit"
    const days = item?.date_limit_weekdays
    return days?.map(
        (dayNumber) =>
            ["M", "Tu", "W", "Th", "F", "Sat", "Sun"][dayNumber - 1]
    )
}
const spendingLimitAmount = (item ) => {
    const isUnlimited = !item.spending_limit_amount
    return isUnlimited ? (
        "Unlimited"
    ) : (
        item?.spending_limit_amount
    )
}
const tripLimitAmount=(item ) => {
    const isUnlimited = item?.trip_limit_amount === 0
    return isUnlimited ? (
        "Unlimited"
    ) : (
        item?.trip_limit_amount
    )
}
const handleCopmanyProgramsExport = (data) =>
    data?.map((item) => ({
        ...item,
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        updated_at: item?.updated_at? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
        expire_date: DateTime.fromISO(item.expire_date).toFormat("ff"),
        time_limit_times: `${item.time_limit_times}`,
        trip_limit_amount: tripLimitAmount(item),
        date_limit_weekdays: `${mapDateLimitWeekDays(item)}`,
        spending_limit_amount: spendingLimitAmount(item),
    }))
export default handleCopmanyProgramsExport

