/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { MoreVert, Refresh } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"

// Chart of Accounts List Component
function ChartOfAccounts() {
    const navigate = useNavigate()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        parent_id: "contains",
        name: "contains",
        created_at: "is",
        updated_at: "is",
        opening_balance: "=",
        balance: "=",
        normal_balance: "is",
        type: "contains",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const defaultFilter = {
        column_field: "parent_id",
        operator_value: "equals",
        value: "1",
    }
    const [anchorEl, setAnchorEl] = useState(null)
    const [account, setAccount] = useState({})

    const model = filterModel(filterFns, columnFilters)
    model.push(defaultFilter)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, error, refetch } = useQuery(
        [
            "chart_of_accounts",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/accounts`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(model ?? undefined),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: () =>
                error?.response?.data?.error?.field_error?.length > 0
                    ? error?.response?.data?.error?.field_error?.map((msg) =>
                          enqueueSnackbar(msg.description || "Network Error!", {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          })
                      )
                    : enqueueSnackbar(
                          error?.response?.data?.error?.message ||
                              error?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getNormalBalanceType = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    "&: hover": {
                        backgroundColor: "#f5f5f5",
                        color: "black",
                    },
                    fontWeight: "bold",
                }}
            >
                {cell?.row?.original?.normal_balance}
            </Button>
        ),
        []
    )
    const getType = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor: "#f5f5f5",
                    color: "#fbcf3b",
                    "&: hover": {
                        backgroundColor: "#f5f5f5",
                        color: "#fbcf3b",
                    },
                    fontWeight: "bold",
                }}
            >
                {cell?.row?.original?.type}
            </Button>
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                size="small"
                disableElevation
                variant="contained"
                sx={{
                    backgroundColor:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#e5faea"
                            : "#ffd6d6",
                    color:
                        cell?.row?.original?.status === "ACTIVE"
                            ? "#33ca51"
                            : "red",
                    "&: hover": {
                        backgroundColor:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#e5faea"
                                : "#ffd6d6",
                        color:
                            cell?.row?.original?.status === "ACTIVE"
                                ? "#33ca51"
                                : "red",
                    },
                }}
            >
                {cell?.row?.original?.status}
            </Button>
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                {row?.original?.type === "HEAD" && (
                    <IconButton
                        sx={{
                            color: "primary.main",
                        }}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setAccount(row?.original)
                        }}
                    >
                        <MoreVert />
                    </IconButton>
                )}
                <Menu
                    key={row?.original.ID}
                    id={row?.original.ID}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        onClick={() => {
                            navigate(`${account?.ID}`)
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Sub Account
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [account?.ID, anchorEl, navigate]
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue()
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 180,
                accessorKey: "parent_id",
                header: "Parent ID",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "name",
                header: "Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "opening_balance",
                header: "Initial Balance",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())} ETB`
                        : `${cell?.getValue()} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "balance",
                header: "Current Balance",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())} ETB`
                        : `${cell?.getValue()} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "normal_balance",
                header: "Normal Balance",
                filterVariant: "select",
                filterSelectOptions: ["CREDIT", "DEBIT"],
                Cell: getNormalBalanceType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "type",
                header: "Type",
                filterVariant: "select",
                filterSelectOptions: ["LEAF", "HEAD"],
                Cell: getType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["ACTIVE", "INACTIVE"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    cell?.getValue()
                        ? DateTime.fromISO(cell?.getValue()).toLocaleString(
                              DateTime.DATETIME_MED
                          )
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                flex: 1,
                enableColumnFilter: false,
                Cell: actions,
            },
        ],
        [actions, getNormalBalanceType, getStatus, getType]
    )

    // RENDER
    return !HasPermission("get chart of accounts") && error ? (
        <Unauthorized />
    ) : (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                page="/system/accounts"
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default ChartOfAccounts
