import { useInfiniteQuery } from "react-query"
import useAxiosPrivate from "./useAxiosPrivate.js"

const useInfinitQueries = ({ key, url }) => {
    const axiosPrivate = useAxiosPrivate()

    return useInfiniteQuery({
        queryKey: [key, url],
        queryFn: async ({ pageParam }) => {
            const { page = 0, perPage = 15 } = pageParam || {}
            const res = await axiosPrivate
                .get(`/system/${url}`, {
                    params: {
                        page: page,
                        per_page: perPage,
                    },
                })
            return res
        },
        getNextPageParam: (lastPage) => {
            const { page: lastPageNo, total } = lastPage?.data?.meta_data || {}
            const hasNextPage = total - (lastPageNo + 1) * 15 > 0

            return hasNextPage ? {
                page: lastPageNo + 1,
                perPage: 15,
            } : undefined
        },
    })
}

export default useInfinitQueries
