import React, { useState } from "react"
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material"
import { useMutation, useQueryClient } from "react-query"
import { useSnackbar } from "notistack"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

function UpdateTIN({ open, setOpen, driverData }) {
    const [tin, setTIN] = useState(driverData?.tin_number ?? "")

    const queryClient = useQueryClient()

    const axiosPrivate = useAxiosPrivate()

    const { enqueueSnackbar } = useSnackbar()

    const { mutate: updateTIN, isLoading: updating } = useMutation(
        (fromData) =>
            axiosPrivate.patch(`system/driver/${driverData?.id}/tin`, fromData),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({
                    queryKey: ["drivers"],
                })
                enqueueSnackbar("Successfully Updated", {
                    variant: "success",
                })
                setOpen(false)
            },
            onError: (error) => {
                const fieldError =
                    error.response?.data?.error?.field_error?.[0]?.description
                const errorMessage = error.response?.data?.error?.message
                enqueueSnackbar(
                    fieldError ||
                        errorMessage ||
                        "Couldn't update the TIN. Try again later please",
                    { variant: "error" }
                )
            },
        }
    )

    const handleUpdate = () => {
        updateTIN({
            tin_number: tin,
        })
    }

    const { xs, md } = useBreakPoints()

    return (
        <Dialog
            fullScreen={xs && !md}
            maxWidth="md"
            onClose={() => setOpen(false)}
            open={open}
            PaperProps={{
                sx: {
                    minWidth: md && "70ch",
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Update TIN
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box component="form" autoComplete="off" sx={{ p: "3em" }}>
                    <TextField
                        label="TIN"
                        value={tin}
                        fullWidth
                        size="small"
                        onChange={(e) => setTIN(e.target.value)}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    px: 4,
                    py: 2,
                    bgcolor: "#fafafa",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "primary.main" },
                        backgroundColor: "primary.main",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    startIcon={
                        updating ? (
                            <CircularProgress
                                size="1em"
                                sx={{ color: "white" }}
                            />
                        ) : null
                    }
                    onClick={() => handleUpdate()}
                >
                    Update
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => setOpen(false)}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UpdateTIN
