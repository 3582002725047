/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import { Box, Button, IconButton, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useLocation } from "react-router-dom"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomExportOptions from "../../../Component/customExport.jsx"
import filterModel from "../../../Utils/filterModel.js"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"
import handleTransactionsExport from "../../../Utils/File Export Formatter/transactionsExport.js"

// List Company Program Transactions Component
function CompanyProgramTransactions() {
    const { state } = useLocation()

    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        passenger_name: "contains",
        passenger_phone: "contains",
        created_at: "is",
        driver_name: "contains",
        driver_phone: "contains",
        amount: "=",
        start_time: "contains",
        end_time: "contains",
        program_name: "contains",
        type: "contains",
        payment_type: "contains",
        status: "is",
        note: "contains",
        dropoff_name: "contains",
        pickup_name: "contains",
        dropoff_time: "is",
        pickup_time: "is",
        trip_type: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const defaultFilter = {
        column_field: "program_id",
        operator_value: "contains",
        value: state?.programData?.id,
    }

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    const model = filterModel(filterFns, columnFilters)
    model.push(defaultFilter)

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "company_program_transactions",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`system/transactions`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    filter:
                        model?.length === 0
                            ? undefined
                            : JSON.stringify(
                                  model?.map((item) => ({
                                      column_field: item.column_field,
                                      operator_value: item.operator_value,
                                      value:
                                          item.operator_value === "empty" ||
                                          item.operator_value === "notEmpty"
                                              ? ""
                                              : item.value,
                                  }))
                              ),
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error.response.data.error.message ||
                        error.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    // HELPERS and EVENT HANDLERS
    const getProgram = useCallback(
        ({ row }) => (
            <Box>
                {row?.original?.detail?.program_name !== "" && (
                    <Button
                        key={row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "black",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {row?.original?.detail?.program_name}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getType = useCallback(
        ({ cell }) => (
            <Box>
                {cell?.row?.original?.type !== "" && (
                    <Button
                        key={cell?.row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "#fbcf3b",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "#fbcf3b",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {cell?.row?.original?.type}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getPaymentType = useCallback(
        ({ cell }) => (
            <Box>
                {cell?.row?.original?.detail?.type !== "" && (
                    <Button
                        key={cell?.row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: "#f5f5f5",
                            color: "black",
                            "&: hover": {
                                backgroundColor: "#f5f5f5",
                                color: "black",
                            },
                            fontWeight: "bold",
                        }}
                    >
                        {cell?.row?.original?.detail?.type}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Box>
                {cell?.row?.original?.status !== "" && (
                    <Button
                        key={cell?.row?.original?.id}
                        size="small"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor:
                                cell?.row?.original?.status === "APPROVED"
                                    ? "#e5faea"
                                    : "#ffd6d6",
                            color:
                                cell?.row?.original?.status === "APPROVED"
                                    ? "#33ca51"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    cell?.row?.original?.status === "APPROVED"
                                        ? "#e5faea"
                                        : "#ffd6d6",
                                color:
                                    cell?.row?.original?.status === "APPROVED"
                                        ? "#33ca51"
                                        : "red",
                            },
                        }}
                    >
                        {cell?.row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Date",
                filterVariant: "date",
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 230,
                accessorKey: "pickup_name",
                header: "From",
                Cell: ({ row }) => row?.original?.detail?.pickup_name ?? "",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "dropoff_name",
                header: "To",
                Cell: ({ row }) => row?.original?.detail?.dropoff_name ?? "",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "pickup_time",
                header: "Start Time",
                filterVariant: "date",
                Cell: ({ row }) =>
                    row?.original?.detail?.pickup_time
                        ? DateTime.fromISO(
                              row?.original?.detail?.pickup_time
                          ).toLocaleString(DateTime.DATETIME_MED)
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "dropoff_time",
                header: "End Time",
                filterVariant: "date",
                Cell: ({ row }) =>
                    row?.original?.detail?.dropoff_time
                        ? DateTime.fromISO(
                              row?.original?.detail?.dropoff_time
                          ).toLocaleString(DateTime.DATETIME_MED)
                        : "",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Filter: datePicker,
            },
            {
                size: 180,
                accessorKey: "passenger_name",
                header: "Passenger",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.detail?.passenger_name ?? ""}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "passenger_phone",
                header: "Passenger Phone",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.detail?.passenger_phone ?? ""}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "driver_name",
                header: "Driver",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.detail?.driver_name}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "driver_phone",
                header: "Driver Phone",
                Cell: ({ cell }) =>
                    `${cell?.row?.original?.detail?.driver_phone}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "amount",
                header: "Amount",
                align: "left",
                headerAlign: "left",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.row?.original?.detail?.amount > 0
                        ? `${roundToTwoDecimalPlaces(
                              cell?.row?.original?.detail?.amount
                          )} ETB`
                        : `${cell?.row?.original?.detail?.amount} ETB`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 230,
                accessorKey: "trip_type",
                header: "Trip Type",
                Cell: ({ row }) => row?.original?.detail?.trip_type ?? "",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "program_name",
                header: "Program",
                Cell: getProgram,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "type",
                header: "Transaction Type",
                filterVariant: "select",
                filterSelectOptions: ["TRANSFER", "DEPOSIT", "WITHDRAW"],
                Cell: getType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "payment_type",
                header: "Payment Type",
                Cell: getPaymentType,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["APPROVED", "PENDING", "REJECTED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "note",
                header: "Note",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getProgram, getStatus, getType, getPaymentType]
    )

    // RENDER
    if (!HasPermission("list all transactions")) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ flex: 1, height: "100%" }}>
            <MaterialReactTable
                columns={columns}
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    disabled:
                        filterFns[column?.id] === "notEmpty" ||
                        filterFns[column?.id] === "empty",
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={
                                filterModel(filterFns, columnFilters)
                                    ?.length === 0
                                    ? [defaultFilter]
                                    : model
                            }
                            page="system/transactions"
                            formatter={handleTransactionsExport}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                }}
            />
        </Box>
    )
}

export default CompanyProgramTransactions
