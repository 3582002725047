import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import {
    Box,
    Typography,
    Container,
    Button,
    Avatar,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
    Grid,
} from "@mui/material"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom"

import gold from "../../Assets/Gold.png"
import white from "../../Assets/white.png"
import bronze from "../../Assets/Bronze.png"
import silver from "../../Assets/Sliver.png"
import axios from "../../Utils/Api/axios.js"
import OtpInputForm from "./otpInputForm.jsx"
import StoreLinks from "../../Component/storeLinks.jsx"
import ErrorPage from "../../Component/errorPage.jsx"

const pointLevel = (level) => {
    switch (level) {
        case "BRONZE":
            return {
                logo: bronze,
                bgColor:
                    " linear-gradient(89.83deg, #CD7F32 0.16%, #DE9B5A 33.56%, #FFE5CB 69.95%, #FFAF61 99.87%)",
            }
        case "SILVER":
            return {
                logo: silver,
                bgColor:
                    "linear-gradient(90deg, #A3A2A2 0.09%, #A5A5A5 32%, #FFFFFF 68%, #A3A3A3 100%)",
            }
        case "GOLD":
            return {
                logo: gold,
                bgColor:
                    "linear-gradient(90deg, #DE9F17 -1.41%, #CF9A15 31.49%, #FFF68A 68.51%, #FFCF45 101.41%)",
            }
        default:
            return { logo: white, bgColor: "#E8E8E8" }
    }
}
const airtimeOptions = (amount) => {
    switch (amount) {
        case "100":
            return {
                option1: 50,
                option2: 100,
            }
        case "200":
            return {
                option1: 100,
                option2: 200,
            }
        default:
            return { option1: 50 }
    }
}

function RedeemPoints() {
    const { phone } = useParams()
    const [open, setOpen] = useState(false)
    const [openOTP, setOpenOTP] = useState(false)
    const [countdown, setCountdown] = useState(59)
    const [selectedConfig, setSelectedConfig] = useState()
    const [selectedAirtime, setSelectedAirtime] = useState({
        amount: 0,
        selected: false,
    })
    const { enqueueSnackbar } = useSnackbar()
    const {
        data: loyalityData,
        isLoading,
        refetch,
        error: loyalityError,
    } = useQuery(["balance", phone], () => axios.get(`balance/${phone}`))

    const {
        mutate,
        isLoading: redeeming,
        data: redeemData,
    } = useMutation((formData) => axios.post(`/readme_points`, formData), {
        onSuccess: () => {
            setOpen(false)
            setOpenOTP(true)
            setSelectedConfig(null)
            setSelectedAirtime({ amount: 0, selected: false })
        },
        onError: (error) => {
            if (error?.response?.data?.error?.message) {
                enqueueSnackbar(
                    error?.response?.data?.error?.message || "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
            setOpen(false)
            setSelectedAirtime({ amount: 0, selected: false })
        },
    })
    const handleOpen = (config) => {
        setSelectedConfig(config)
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setSelectedConfig(null)
        setSelectedAirtime({ amount: 0, selected: false })
    }
    const handleConfirm = () => {
        const formData = {
            id: selectedConfig?.id,
            amount: selectedAirtime.amount === 0 ? 90 : selectedAirtime.amount,
            user_type: "USER",
            phone: phone,
        }
        mutate(formData)
        setCountdown(59)
    }
    const handleBtnClick = (selectedAmount) => {
        setSelectedAirtime({ amount: selectedAmount, selected: true })
    }
    const handleOTPClose = () => {
        setOpenOTP(false)
    }
    const handleDefaultLevel = () => {
        enqueueSnackbar("You don't have sufficient points to redeem.", {
            preventDuplicate: true,
        })
    }
    if (isLoading)
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 6,
                }}
            >
                <CircularProgress />
            </Box>
        )
    if (loyalityError) {
        return <ErrorPage error={loyalityError} />
    }
    return (
        <Container maxWidth="xs" disableGutters>
            <Box
                sx={{
                    width: "100%",
                    height: { xs: "300px", sm: "332px" },
                    background: pointLevel(
                        loyalityData?.data?.data?.loyality_balance?.level
                    )?.bgColor,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: 1,
                    position: "relative",
                }}
            >
                <Button
                    variant="contained"
                    sx={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                        color: "#fff",
                        bgcolor: "#fbcf3b",
                        ":hover": {
                            color: "#fff",
                            bgcolor: "#fbcf3b",
                        },
                        borderRadius: 4,
                    }}
                    size="small"
                >
                    <Typography
                        variant="body2"
                        textAlign="center"
                        fontWeight={700}
                    >
                        {loyalityData?.data?.data?.loyality_balance?.level} COIN
                    </Typography>
                </Button>
                <Box
                    sx={{
                        borderRadius: "50%",
                        width: { xs: "135px", sm: "150px" },
                        height: { xs: "135px", sm: "150px" },
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                        mt: 2,
                    }}
                >
                    <Avatar
                        alt={`${loyalityData?.data?.data?.loyality_balance?.level}`}
                        src={
                            pointLevel(
                                loyalityData?.data?.data?.loyality_balance
                                    ?.level
                            )?.logo
                        }
                        sx={{ width: "100%", height: "100%" }}
                    />
                </Box>
                <Typography
                    variant="h4"
                    fontWeight={700}
                    sx={{ marginTop: "5px" }}
                >
                    {Math.floor(
                        loyalityData?.data?.data?.loyality_balance?.balance
                    )?.toLocaleString()}{" "}
                    Points
                </Typography>
                <Typography variant="body1" fontWeight={700} sx={{ my: "4px" }}>
                    Redeem up to{" "}
                    {loyalityData?.data?.data?.loyality_config[0]?.amount} ETB
                </Typography>
                <Typography variant="body1" fontWeight={700} mb={2}>
                    Exp. Date -{" "}
                    {DateTime.fromISO(
                        loyalityData?.data?.data?.loyality_balance?.expire_date
                    ).toLocaleString(DateTime.DATE_FULL)}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: "100%",
                    height: {
                        xs: "calc(100vh - 320px)",
                        sm: "calc(100vh - 340px)",
                    },
                    bgcolor: "#FFFFFF",
                    borderRadius: "20px 20px 0px 0px",
                    position: "relative",
                    top: "-20px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: "auto",
                        p: "20px 15px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                    }}
                >
                    {loyalityData?.data?.data?.loyality_config?.map(
                        (config) => (
                            <Button
                                key={config.id}
                                sx={{
                                    justifyContent: "flex-start",
                                    textTransform: "none",
                                    p: 1.5,
                                    borderRadius: 2,
                                    boxShadow: 2,
                                }}
                                onClick={() =>
                                    loyalityData?.data?.data?.loyality_balance
                                        ?.level === "DEFAULT"
                                        ? handleDefaultLevel()
                                        : handleOpen(config)
                                }
                            >
                                <img
                                    src={config.logo}
                                    alt={config.name}
                                    width="50px"
                                    style={{
                                        marginRight: "10px",
                                        marginLeft: "5px",
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Typography
                                        variant="body1"
                                        fontWeight={700}
                                        color="black"
                                    >
                                        {config.name}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="start"
                                        fontWeight={700}
                                        color="grey"
                                    >
                                        Redeem up to{" "}
                                        {
                                            loyalityData?.data?.data?.loyality_config?.filter(
                                                (item) =>
                                                    item.name === config.name
                                            )[0]?.amount
                                        }{" "}
                                        ETB
                                    </Typography>
                                </Box>
                            </Button>
                        )
                    )}
                </Box>

                <Box
                    sx={{
                        position: "sticky",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        bgcolor: "white",
                        display: "flex",
                        justifyContent: "end",
                        px: 2,
                    }}
                >
                    <StoreLinks />
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    fullWidth
                    PaperProps={{
                        sx: { borderRadius: 2 },
                    }}
                >
                    <DialogContent>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight={700}
                        >
                            Redeem Your
                        </Typography>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight={700}
                            mt={-1}
                        >
                            {selectedConfig?.name}
                        </Typography>
                        {selectedConfig?.name === "Sewasew Subscription" ? (
                            <Typography
                                textAlign="center"
                                gutterBottom
                                mt={1}
                                mb={-1}
                                variant="subtitle2"
                                color="#2e313a"
                                fontWeight={700}
                            >
                                Only monthly subscription is available!
                            </Typography>
                        ) : (
                            <Grid
                                container
                                spacing={2}
                                sx={{ px: 1, mt: 1, justifyContent: "center" }}
                            >
                                <Grid item xs={6}>
                                    <Button
                                        variant={
                                            selectedAirtime.amount ===
                                                airtimeOptions(
                                                    selectedConfig?.amount
                                                ).option1 &&
                                            selectedAirtime.selected
                                                ? "contained"
                                                : "outlined"
                                        }
                                        fullWidth
                                        sx={{
                                            px: 1,
                                            ":hover": {
                                                bgcolor: "primary.main",
                                            },
                                        }}
                                        onClick={() =>
                                            handleBtnClick(
                                                airtimeOptions(
                                                    selectedConfig?.amount
                                                ).option1
                                            )
                                        }
                                    >
                                        <Box sx={{ width: "100%" }}>
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    fontWeight: 700,
                                                    color:
                                                        selectedAirtime.amount ===
                                                            airtimeOptions(
                                                                selectedConfig?.amount
                                                            ).option1 &&
                                                        selectedAirtime.selected
                                                            ? "#fff"
                                                            : "black",
                                                }}
                                            >
                                                {
                                                    airtimeOptions(
                                                        selectedConfig?.amount
                                                    ).option1
                                                }{" "}
                                                ETB
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 13,
                                                    fontWeight: 700,
                                                    color:
                                                        selectedAirtime.amount ===
                                                            airtimeOptions(
                                                                selectedConfig?.amount
                                                            ).option1 &&
                                                        selectedAirtime.selected
                                                            ? "#fff"
                                                            : "black",
                                                }}
                                            >
                                                {loyalityData?.data?.data
                                                    ?.loyality_balance
                                                    ?.level === "BRONZE"
                                                    ? Number(
                                                          selectedConfig?.rate
                                                      )?.toLocaleString()
                                                    : (
                                                          Number(
                                                              selectedConfig?.rate
                                                          ) * 0.5
                                                      )?.toLocaleString()}{" "}
                                                Points
                                            </Typography>
                                        </Box>
                                    </Button>
                                </Grid>
                                {airtimeOptions(selectedConfig?.amount)
                                    ?.option2 && (
                                    <Grid item xs={6}>
                                        <Button
                                            variant={
                                                selectedAirtime.amount ===
                                                    airtimeOptions(
                                                        selectedConfig?.amount
                                                    ).option2 &&
                                                selectedAirtime.selected
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            fullWidth
                                            onClick={() =>
                                                handleBtnClick(
                                                    airtimeOptions(
                                                        selectedConfig?.amount
                                                    ).option2
                                                )
                                            }
                                            sx={{
                                                px: 1,
                                                ":hover": {
                                                    bgcolor: "primary.main",
                                                },
                                            }}
                                        >
                                            <Box sx={{ width: "100%" }}>
                                                <Typography
                                                    sx={{
                                                        fontSize: 15,
                                                        fontWeight: 700,
                                                        color:
                                                            selectedAirtime.amount ===
                                                                airtimeOptions(
                                                                    selectedConfig?.amount
                                                                ).option2 &&
                                                            selectedAirtime.selected
                                                                ? "#fff"
                                                                : "black",
                                                    }}
                                                >
                                                    {
                                                        airtimeOptions(
                                                            selectedConfig?.amount
                                                        )?.option2
                                                    }{" "}
                                                    ETB
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: 13,
                                                        fontWeight: 700,
                                                        color:
                                                            selectedAirtime.amount ===
                                                                airtimeOptions(
                                                                    selectedConfig?.amount
                                                                ).option2 &&
                                                            selectedAirtime.selected
                                                                ? "#fff"
                                                                : "black",
                                                    }}
                                                >
                                                    {Number(
                                                        selectedConfig?.rate
                                                    )?.toLocaleString()}{" "}
                                                    Points
                                                </Typography>
                                            </Box>
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </DialogContent>
                    <DialogActions
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            px: 4,
                            gap: 1,
                            mb: 1,
                        }}
                    >
                        <Button
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={handleConfirm}
                            disabled={
                                (selectedConfig?.name !==
                                    "Sewasew Subscription" &&
                                    selectedAirtime.amount === 0) ||
                                redeeming
                            }
                            sx={{
                                color: "white",
                                bgcolor: "black",
                                ":hover": {
                                    color: "white",
                                    bgcolor: "black",
                                },
                                textTransform: "none",
                                fontWeight: 700,
                                p: 1.5,
                                borderRadius: 2,
                            }}
                        >
                            Continue{" "}
                            {redeeming && (
                                <CircularProgress
                                    size="1em"
                                    sx={{ color: "#fff", ml: "1em" }}
                                />
                            )}
                        </Button>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            sx={{
                                textTransform: "none",
                                color: "black",
                                fontWeight: 900,
                                fontSize: 16,
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                {openOTP && (
                    <OtpInputForm
                        id={redeemData?.data?.data?.id}
                        open={openOTP}
                        countdown={countdown}
                        setCountdown={setCountdown}
                        handleClose={handleOTPClose}
                        refetch={refetch}
                    />
                )}
            </Box>
        </Container>
    )
}

export default RedeemPoints
