const URL = (url) => {
    const pathnames = url.split("/").filter((x) => x)

    if (pathnames.length === 2 || pathnames.length === 3) {
        return pathnames[1]?.replace(/s$/, "")
    }
    if (pathnames.length === 4 || pathnames.length === 5) {
        return pathnames[3]?.replace(/s$/, "")
    }
    if (pathnames.length === 6 || pathnames.length === 7) {
        return (url?.includes("program"))?
        `${pathnames[3]?.replace(/s$/, "")} ${pathnames[5]?.replace(/s$/, "")}`:
            pathnames[6]?.replace(/s$/, "")
    }
    return ""
}
export default URL