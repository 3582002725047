import { find, isEqual } from "lodash"
import { useSnackbar } from "notistack"
import { useRef } from "react"
import { useMutation } from "react-query"
import useAxiosPrivate from "./useAxiosPrivate.js"

const useAssignAgentCode = () => {
    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const assign = useRef([])

    const { mutate, isLoading } = useMutation((data) =>
        axiosPrivate.post(`/system/agent/seed`, {
            agent_data: data
        })
    )
    const handleAssign = (value) => {
        if (find(assign.current, (el) => isEqual(el, value))) return
        assign.current = [...assign.current, value]
    }
    return {
        assignAgent: handleAssign,
        isLoading,
        requestAssign: (callback) => {
            if (assign.current.length === 0) {
                enqueueSnackbar(
                    "None of the phone are on RidePlus",
                    {
                        variant: "warning",
                    }
                )
                return
            }
            mutate(assign.current, {
                onSuccess: () => {
                    enqueueSnackbar("Short Code Added Successfully", {
                        variant: "success",
                    })
                    callback()
                },
                onError: (error) => {
                    const fieldError =
                        error.response?.data?.error?.field_error[0]?.description
                    const errorMessage = error.response?.data?.error?.message
                    enqueueSnackbar(
                        fieldError || errorMessage || "Request Failed",
                        { variant: "error" }
                    )
                },
            })
        },
        clearAssign: () => {
            assign.current = []
        },
    }
}

export default useAssignAgentCode
