import React from "react"
import { CircularProgress, IconButton } from "@mui/material"

/*  This button component is used for 
    making default images or removing unnecessary images 
*/
function ImageActionButton(props) {
    const { isLoading, id, bgcolor, text, disabled, onClick } = props

    return (
        <IconButton
            size="small"
            disableRipple
            sx={{
                color: text === "Remove" ? "secondary.main" : "black",
                bgcolor: bgcolor,
                borderRadius: 3,
                fontSize: 10,
                mr: id === 1 ? -3 : 0.25,
                ml: id === 1 ? 0.25 : -3,
                mb: text === "Remove" ? 0 : 1,
            }}
            onClick={onClick}
            disabled={disabled || isLoading}
        >
            {isLoading && (
                <CircularProgress
                    size={10}
                    sx={{
                        color: "black",
                        position: "absolute",
                        top: "80%",
                        left: "60%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            )}
            {text}
        </IconButton>
    )
}
export default ImageActionButton
