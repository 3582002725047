/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { Alert, Box, Button, IconButton, Tooltip } from "@mui/material"
import { Refresh, Visibility } from "@mui/icons-material"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import customFilterPanel from "../../../Component/customFilter.jsx"
import CustomeDateFilter from "../../../Component/customDateFilterPanel.jsx"
import CustomExportOptions from "../../../Component/customExport.jsx"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import filterModel from "../../../Utils/filterModel.js"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"
import GenerateInvoice from "./generateInvoice.jsx"
import TopUp from "./companyTopUp.jsx"
import { handleInvoicesExport } from "../../../Utils/File Export Formatter/transactionsExport.js"

const invoiceStatusBgColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#F5F5F5"
        case "APPROVED":
            return "#E5FFEA"
        case "OVERDUE":
            return "#FFE5E5"
        case "REJECTED":
            return "#EFEFEF"
        default:
            return "white"
    }
}
const invoiceStatusColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#FFDC00"
        case "APPROVED":
            return "#16C138"
        case "OVERDUE":
            return "#E11212"
        case "REJECTED":
            return "#000000"
        default:
            return "text.primary"
    }
}
function CompanyInvoices() {
    const navigate = useNavigate()
    const { state } = useLocation()

    // USESTATE HOOK
    const [open, setOpen] = useState(false)
    const [openTopUp, setOpenTopUp] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        number: "=",
        item: "contains",
        due_balance: "=",
        quantity: "=",
        invoice_type: "is",
        due_date: "is",
        created_at: "is",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )
    // FILTER MODEL
    const model = filterModel(filterFns, columnFilters)?.map((item) => ({
        column_field: item.column_field,
        operator_value: item.operator_value,
        value:
            item.operator_value === "empty" ||
            item.operator_value === "notEmpty"
                ? ""
                : item.value,
    }))

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "company_invoices",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `/system/corporate/${state?.corporateId}/invoices`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(model),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("list company invoices"),
        }
    )

    // HELPERS & EVENT HANDLERS
    const handleModalClose = () => {
        setOpen(false)
    }
    const getActions = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                }}
            >
                <IconButton
                    onClick={() =>
                        navigate(`${row.original?.number}/details`, {
                            state: {
                                original: row.original,
                                corporateData: state?.companyData,
                            },
                        })
                    }
                >
                    <Visibility sx={{ color: "primary.main" }} />
                </IconButton>
            </Box>
        ),
        [navigate, state?.companyData]
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    width: "130px",
                }}
            >
                <Alert
                    severity="info"
                    icon={false}
                    sx={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        backgroundColor: `${invoiceStatusBgColor(
                            cell?.row?.original?.status
                        )}`,
                        color: `${invoiceStatusColor(
                            cell?.row?.original?.status
                        )}`,
                        fontWeight: "bold",
                    }}
                >
                    {cell?.row?.original?.status?.toLowerCase()}
                </Alert>
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    const columns = useMemo(
        () => [
            {
                accessorKey: "created_at",
                header: "Time",
                size: 200,
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell.getValue()).toFormat("ff"),
                Filter: datePicker,
            },
            {
                accessorKey: "number",
                header: "Invoice No",
                size: 200,
                filterVariant: "number",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "due_balance",
                header: "Due Balance (ETB)",
                size: 240,
                filterVariant: "number",
                Cell: ({ row }) =>
                    roundToTwoDecimalPlaces(row?.original?.due_balance),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "quantity",
                header: "Quantity",
                size: 200,
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "due_date",
                header: "Due Date",
                size: 240,
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell.getValue()).toFormat("ff"),
                Filter: datePicker,
            },
            {
                accessorKey: "status",
                header: "Status",
                size: 200,
                filterVariant: "select",
                filterSelectOptions: [
                    "PENDING",
                    "APPROVED",
                    "REJECTED",
                    "OVERDUE",
                ],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "action",
                header: "Action",
                enableSorting: false,
                enableColumnFilter: false,
                Cell: getActions,
            },
        ],
        [getActions, getStatus]
    )

    // RENDER
    if (!HasPermission("list company invoices")) return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <GenerateInvoice
                    handleModalClose={handleModalClose}
                    companyId={state?.corporateId}
                    refetch={refetch}
                    open={open}
                />
            )}
            {openTopUp && (
                <TopUp
                    accountType={state?.companyData?.account_type}
                    companyId={state?.corporateId}
                    invoice={invoice}
                    open={openTopUp}
                    setOpen={setOpenTopUp}
                    refetch={refetch}
                    setInvoice={setInvoice}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                        disabled:
                            filterFns[column?.id] === "notEmpty" ||
                            filterFns[column?.id] === "empty",
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("add corporate top up request") && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpenTopUp(true)}
                                >
                                    TopUp
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                page="/system/transactions"
                                formatter={handleInvoicesExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default CompanyInvoices
