import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from "@mui/material"
import { useMutation, useQuery } from "react-query"
import { useSnackbar } from "notistack"
import {
    Add,
    Delete,
    HighlightOff,
    PlaylistRemove,
    TaskAlt,
} from "@mui/icons-material"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import HasPermission from "../../Utils/access.js"

function AssignRole({ open, handleEditModalClose, userData, refetch }) {
    const [roles, setRoles] = useState([])
    const selectedRole = useRef({})

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // MUTATION & QUERY
    const { data: rolesData, isLoading: roleIsLoading } = useQuery(
        ["roles"],
        () =>
            axiosPrivate.get(`/system/roles`, {
                params: {
                    per_page: -1,
                },
            }),
        {
            refetchOnWindowsFocus: false,
            enabled: HasPermission("get all roles"),
        }
    )
    const {
        isFetching,
        data: userWithRole,
        refetch: refetchUserData,
    } = useQuery(
        ["system_user_with_role"],
        () =>
            axiosPrivate.get(`system/users`, {
                params: {
                    per_page: -1,
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get system users"),
        }
    )
    const {
        mutate,
        error,
        isLoading: assignIsLoading,
    } = useMutation(
        (formData) =>
            axiosPrivate.post(`/system/users/${userData?.id}/roles`, formData),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully assigned a new role.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                refetchUserData()
            },
        }
    )
    const {
        mutate: revoke,
        error: revokeError,
        isLoading: revokeIsLoading,
    } = useMutation(
        (formData) =>
            axiosPrivate.patch(`/system/users/${userData?.id}/roles`, formData),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully revoked role.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                setRoles(roles?.filter((item) => item !== selectedRole.current))
                refetchUserData()
            },
        }
    )

    // HELPERS & EVENT HANDLERS
    const getUserRoles = useMemo(
        () =>
            userWithRole?.data?.data
                ?.filter((user) => user.id === userData?.id)
                ?.map((item) => item.roles)[0]
                ?.map((role) => ({
                    name: role?.role_name,
                    id: role?.id,
                    status: role?.status,
                })),
        [userData?.id, userWithRole?.data?.data]
    )
    const isSameRole = (a, b) => a.id === b.id
    const onSubmit = (id) => {
        const roleToBeRemoved = getUserRoles?.filter(
            (previousValue) =>
                !roles?.some((currentValue) =>
                    isSameRole(previousValue, currentValue)
                )
        )
        if (roleToBeRemoved?.length > 0) {
            revoke({ role_id: roleToBeRemoved[0]?.id })
        }
        if (id !== undefined) mutate({ role: id })
    }

    // USEEFFECT
    useEffect(() => {
        if (userWithRole) {
            setRoles(
                getUserRoles?.map((role) => ({
                    id: role?.id,
                    name: role?.name,
                    status: role?.status,
                }))
            )
        }
    }, [getUserRoles, userWithRole])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error])
    useEffect(() => {
        if (revokeError) {
            if (revokeError?.response?.data?.error?.field_error?.length > 0) {
                revokeError?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    revokeError?.response?.data?.error?.message ||
                        revokeError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, revokeError])

    useEffect(() => {
        if (roles === undefined) {
            handleEditModalClose()
            refetch()
        }
    }, [handleEditModalClose, refetch, roles])

    // RENDER
    return (
        <Dialog
            fullWidth
            scroll="paper"
            open={open}
            onClose={() => {
                refetch()
                handleEditModalClose()
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: "#FAFAFA",
                    py: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        Update {`${userData?.first_name ?? "User"}'s`} Roles
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    mt: 2,
                    mx: 2,
                }}
            >
                {roleIsLoading || isFetching ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                            minHeight: "200px",
                            minWidth: "250px",
                        }}
                    >
                        <CircularProgress
                            size={40}
                            sx={{
                                color: "black",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                            }}
                        />
                    </Box>
                ) : (
                    <>
                        {roles?.map((element, index) => (
                            <Box
                                key={element?.id}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    bgcolor: `${
                                        index % 2 !== 0 ? "#F5F5F5" : null
                                    }`,
                                    my: 1,
                                }}
                            >
                                <Box
                                    key={element.id}
                                    sx={{
                                        display: "flex",
                                        width: "100%",
                                        bgcolor: "#fff",
                                    }}
                                >
                                    <FormControl
                                        key={element.id}
                                        margin="normal"
                                        size="small"
                                        fullWidth
                                        sx={{
                                            m: 0,
                                        }}
                                    >
                                        <Select
                                            key={element.id}
                                            fullWidth
                                            MenuProps={{
                                                sx: {
                                                    "&& .Mui-selected": {
                                                        backgroundColor:
                                                            "primary.main",
                                                    },
                                                    ml: 1,
                                                    my: 1,
                                                },
                                            }}
                                            value={
                                                rolesData?.data?.data
                                                    ?.filter(
                                                        (item) =>
                                                            item?.id ===
                                                            element.id
                                                    )
                                                    ?.map((value) => value)[0]
                                            }
                                            onChange={(e) => {
                                                setRoles(
                                                    roles?.map((item) => {
                                                        if (
                                                            item?.id ===
                                                            element?.id
                                                        ) {
                                                            return {
                                                                id: e.target
                                                                    .value.id,
                                                                name: e.target
                                                                    .value.name,
                                                                status: e.target
                                                                    .value
                                                                    .status,
                                                            }
                                                        }

                                                        return item
                                                    })
                                                )
                                            }}
                                        >
                                            <MenuItem
                                                key={element?.id}
                                                value=""
                                            >
                                                <Tooltip
                                                    arrow
                                                    title="Revoke role"
                                                    placement="right-end"
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            m: ".1em",
                                                            backgroundColor:
                                                                "whitesmoke",
                                                            color: "black",
                                                            borderRadius:
                                                                "20px",
                                                            pr: "1em",
                                                            width: "95%",
                                                        }}
                                                    >
                                                        <IconButton size="small">
                                                            <PlaylistRemove />
                                                        </IconButton>
                                                        <Typography
                                                            variant="subtitle2"
                                                            noWrap
                                                        >
                                                            None
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            </MenuItem>
                                            {rolesData?.data?.data?.map(
                                                (role) => (
                                                    <MenuItem
                                                        value={role}
                                                        key={role?.id}
                                                        sx={{
                                                            bgcolor:
                                                                roles?.filter(
                                                                    (item) =>
                                                                        item.id ===
                                                                        role.id
                                                                ).length
                                                                    ? "primary.main"
                                                                    : "",
                                                        }}
                                                        disabled={
                                                            roles?.filter(
                                                                (item) =>
                                                                    item.id ===
                                                                    role.id
                                                            ).length
                                                        }
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                m: ".1em",
                                                                backgroundColor:
                                                                    role?.status ===
                                                                    "ACTIVE"
                                                                        ? "success.main"
                                                                        : "error.main",
                                                                color: "white",
                                                                borderRadius:
                                                                    "20px",
                                                                pr: "1em",
                                                                width: "95%",
                                                            }}
                                                        >
                                                            <IconButton
                                                                size="small"
                                                                sx={{
                                                                    color: "white",
                                                                }}
                                                            >
                                                                {role?.status ===
                                                                "ACTIVE" ? (
                                                                    <TaskAlt fontSize=".1em" />
                                                                ) : (
                                                                    <HighlightOff fontSize=".1em" />
                                                                )}
                                                            </IconButton>
                                                            <Typography
                                                                variant="subtitle2"
                                                                noWrap
                                                            >
                                                                {role?.name}
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            ml: 1,
                                        }}
                                    >
                                        <Button
                                            sx={{
                                                bgcolor: "#fdbc3d",
                                                ":hover": {
                                                    bgcolor: "#fdbc3d",
                                                },
                                                textTransform: "none",
                                                fontWeight: "bold",
                                                color: "#fff",
                                                width: 100,
                                            }}
                                            variant="contained"
                                            type="submit"
                                            onClick={() => {
                                                onSubmit(element?.id)
                                                selectedRole.current = element
                                            }}
                                            disabled={
                                                getUserRoles?.filter(
                                                    (item) =>
                                                        item.id === element.id
                                                ).length ||
                                                !HasPermission("assign role") ||
                                                !HasPermission("revoke role") ||
                                                assignIsLoading ||
                                                revokeIsLoading
                                            }
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                if (
                                                    element?.name === "" ||
                                                    element?.name === undefined
                                                ) {
                                                    setRoles(
                                                        roles.filter(
                                                            (item) =>
                                                                item !== element
                                                        )
                                                    )
                                                } else {
                                                    revoke({
                                                        role_id: element?.id,
                                                    })
                                                    selectedRole.current =
                                                        element
                                                }
                                            }}
                                            disabled={
                                                !HasPermission("revoke role") ||
                                                revokeIsLoading
                                            }
                                        >
                                            <Delete
                                                sx={{
                                                    color: "red",
                                                }}
                                            />
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                        <Box
                            sx={{
                                boxShadow: 1,
                                width: "30%",
                                fontSize: 12,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                my: 2,
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setRoles([
                                        ...roles,
                                        {
                                            id: roles?.length,
                                            name: "",
                                            status: "",
                                        },
                                    ])
                                }}
                                startIcon={<Add />}
                            >
                                Add Role
                            </Button>
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions
                sx={{
                    mb: 1,
                    pr: 4,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => {
                        refetch()
                        handleEditModalClose()
                    }}
                >
                    <Typography>Cancel</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AssignRole
