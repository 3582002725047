import PolylineUtil from "polyline-encoded"
import React, { useEffect } from "react"
import fscreen from "fscreen"
import L from "leaflet"
import "../Assets/mapIcons.css"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"

const defaultCenter = [8.8960349, 38.7704111]

const endMarkerICon = new L.Icon({
    iconUrl:
        "https://www.clipartmax.com/png/full/236-2368050_free-map-pin-icon-png-map-pin-yellow-png.png",
    iconSize: [35, 35],
    iconAnchor: [15, 20],
    popupAnchor: [0, -25],
})

const startMarker = new L.Icon({
    iconUrl:
        "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-green-icon.png",
    iconSize: [18, 18],
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "#000",
    popupAnchor: [0, -25],
})

export const FullScreenToggle = () => {
    const map = useMap()
    const container = map.getContainer()

    const handler = () => {
        if (fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(container)
        } else {
            fscreen.exitFullscreen()
        }
    }

    L.Control.FullScreenToggle = L.Control.extend({
        onAdd: function () {
            const control = document.querySelector("#fullscreen")

            if (!control) {
                const IconContainer = L.DomUtil.create("div", "fullscreen")
                IconContainer.setAttribute("id", "fullscreen")
                L.DomUtil.create("div", "fullscreen-inner", IconContainer)
                IconContainer.addEventListener("click", () => {
                    if (fscreen.fullscreenEnabled) handler()
                })
                return IconContainer
            }

            return control
        },
        onRemove: function () {},
    })

    L.control.fullscreen = function (opt) {
        return new L.Control.FullScreenToggle(opt)
    }

    L.control.fullscreen({ position: "topleft" }).addTo(map)
}

export const Polyline = ({ path, polylineDecoded }) => {
    const map = useMap()

    useEffect(() => {
        if (path) {
            const decodedPolyline = PolylineUtil.decode(path)

            // POLYLINE
            const polyline = L.polyline(decodedPolyline, {
                color: "lime",
                lineCap: "circle",
            }).addTo(map)

            map.fitBounds(polyline.getBounds())
        } else if (polylineDecoded?.length > 0) {
            // POLYLINE
            const polyline = L.polyline(polylineDecoded, {
                color: "lime",
                lineCap: "circle",
            }).addTo(map)

            map.fitBounds(polyline.getBounds())
        } else {
            map.setView(defaultCenter, 1)
            L.popup(defaultCenter, {
                content:
                    "<p>Trip Info Unavailable!<br />No recorded Info for this trip</p>",
            }).openOn(map)
        }
    }, [map, path, polylineDecoded])
}

function CustomIcon({ point, text }) {
    return (
        <div
            style={{
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <span
                style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "green",
                }}
            >
                {text}
            </span>
            <h3>{point} </h3>
        </div>
    )
}
function CustomMapContainer({ polyline, startingPoint, endPoint }) {
    const decodedPolyline = PolylineUtil.decode(
        polyline === undefined ? "????" : polyline
    )

    const startingPointPosition = decodedPolyline[0]
    const endPointPosition = decodedPolyline[decodedPolyline.length - 1]

    return (
        <MapContainer style={{ height: "100%", width: "100%" }}>
            <Marker position={startingPointPosition} icon={startMarker}>
                <Popup>
                    <CustomIcon point={startingPoint} text="Start Location" />
                </Popup>
            </Marker>
            <Marker position={endPointPosition} icon={endMarkerICon}>
                <Popup>
                    <CustomIcon point={endPoint} text="End Location" />
                </Popup>
            </Marker>
            <Polyline path={polyline} />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <FullScreenToggle />
        </MapContainer>
    )
}

export default CustomMapContainer
