/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    // MRT_ToggleFiltersButton,
    // MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useMutation, useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { useLocation, useNavigate } from "react-router-dom"
import { Receipt, Refresh } from "@mui/icons-material"
import { DateTime } from "luxon"
import CustomExportOptions from "../../../Component/customExport.jsx"
import customFilterPanel from "../../../Component/customFilter.jsx"
import filterModel from "../../../Utils/filterModel.js"
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate.js"
import TopUp from "./companyTopUp.jsx"
import HasPermission from "../../../Utils/access.js"
import Unauthorized from "../../../Component/unauthorized.jsx"
import handleDateExport from "../../../Utils/File Export Formatter/dateFilterFormat.js"
import handleReceiptsExport from "../../../Utils/File Export Formatter/billingExportFormat.js"
import { roundToTwoDecimalPlaces } from "../../../Utils/dataFormat.js"

const receiptStatusBgColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#F5F5F5"
        case "APPROVED":
            return "#E5FFEA"
        case "FAILED":
            return "#FFE5E5"
        case "REJECTED":
            return "#EFEFEF"
        default:
            return "white"
    }
}
const receiptStatusColor = (status) => {
    switch (status) {
        case "PENDING":
            return "#FFDC00"
        case "APPROVED":
            return "#16C138"
        case "FAILED":
            return "#E11212"
        case "REJECTED":
            return "#000000"
        default:
            return "text.primary"
    }
}

// Receipts List View Component
function Receipts({ topUps, invoice }) {
    const { state } = useLocation()
    const navigate = useNavigate()
    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        bank_name: "contains",
        reference_number: "contains",
        amount: "=",
        remark: "contains",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const [topUpData, setTopUpData] = useState(topUps)
    const request = useRef({
        type: "",
        id: "",
    })

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id,
                sort: item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const {
        isFetching,
        data,
        refetch,
        error: topUpFetchError,
    } = useQuery(
        [
            "system_top_ups",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(
                `system/corporate/${
                    state?.corporateId
                        ? state?.corporateId
                        : state?.corporateData?.id
                }/top_ups`,
                {
                    params: {
                        page: `${pagination.pageIndex}`,
                        per_page: `${pagination.pageSize}`,
                        filter: JSON.stringify(
                            filterModel(filterFns, columnFilters)
                        ),
                        sort: JSON.stringify(sort ?? []),
                        search: globalFilter ?? undefined,
                        link_operator: "and",
                    },
                }
            ),
        {
            enabled:
                topUps === undefined && HasPermission("get pre paid top ups"),
        }
    )

    const {
        isFetching: invoiceIsFetching,
        data: invoices,
        refetch: refetchInvoices,
    } = useQuery(
        ["selected_company_invoice"],
        () =>
            axiosPrivate.get(
                `/system/corporate/${state?.corporateData?.id}/invoices`,
                {
                    params: {
                        filter: JSON.stringify([
                            {
                                column_field: "number",
                                operator_value: "equals",
                                value: invoice?.number,
                            },
                        ]),
                    },
                }
            ),
        {
            onError: (invoiceError) =>
                enqueueSnackbar(
                    invoiceError?.response?.data?.error?.message ||
                        invoiceError?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: topUps !== undefined,
        }
    )

    const {
        mutate: approve,
        isLoading: approvalIsLoading,
        error,
    } = useMutation(
        (status) =>
            axiosPrivate.post(
                `/system/corporate_top_up/${request.current.id}/confirm`,
                status
            ),
        {
            onSuccess: () => {
                enqueueSnackbar(`Succesfully changed status.`, {
                    variant: "success",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                })
                if (topUps !== undefined) {
                    refetchInvoices()
                } else {
                    refetch()
                }
            },
            onError: (approvalError) =>
                approvalError?.response?.data?.error?.field_error?.length > 0
                    ? approvalError?.response?.data?.error?.field_error?.map(
                          (msg) =>
                              enqueueSnackbar(
                                  msg.description || "Network Error!",
                                  {
                                      variant: "error",
                                      preventDuplicate: true,
                                      autoHideDuration: 2000,
                                  }
                              )
                      )
                    : enqueueSnackbar(
                          approvalError?.response?.data?.error?.message ||
                              approvalError?.message ||
                              "Network Error!",
                          {
                              variant: "error",
                              preventDuplicate: true,
                              autoHideDuration: 2000,
                          }
                      ),
        }
    )

    // EVENT HANDLERS & HELPERS
    const getInvoice = useMemo(
        () =>
            invoices?.data?.data
                ?.filter((item) => item.id === invoice?.id)
                ?.map((element) => element)[0]?.top_ups,
        [invoice?.id, invoices?.data?.data]
    )
    // getInvoice.push(getTopUpApprover)
    const actions = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                }}
            >
                {(row?.original?.status === "PENDING" ||
                    row?.original?.corporate_top_up?.status === "PENDING") && (
                    <>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#16C138",
                                "&: hover": {
                                    backgroundColor: "#16C138",
                                },
                                color: "#fff",
                                textTransform: "none",
                                fontSize: 14,
                                fontWeight: "bold",
                                width: "80px",
                                height: "35px",
                            }}
                            size="small"
                            onClick={() => {
                                approve({ status: "APPROVED" })
                                request.current = {
                                    type: "APPROVED",
                                    id:
                                        row?.original?.id ||
                                        row?.original?.corporate_top_up?.id,
                                }
                            }}
                            disabled={
                                approvalIsLoading ||
                                !HasPermission(
                                    "approve corporate top up request"
                                )
                            }
                        >
                            Approve
                            {approvalIsLoading &&
                                request.current.type === "APPROVED" &&
                                request.current.id ===
                                    (row?.original?.id ||
                                        row?.original?.corporate_top_up
                                            ?.id) && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "black",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#E11212",
                                "&: hover": {
                                    backgroundColor: "#E11212",
                                },
                                color: "#fff",
                                textTransform: "none",
                                fontSize: 14,
                                fontWeight: "bold",
                                ml: 1,
                                width: "80px",
                                height: "35px",
                            }}
                            size="small"
                            onClick={() => {
                                approve({ status: "REJECTED" })
                                request.current = {
                                    type: "REJECTED",
                                    id:
                                        row?.original?.id ||
                                        row?.original?.corporate_top_up?.id,
                                }
                            }}
                            disabled={
                                approvalIsLoading ||
                                !HasPermission(
                                    "approve corporate top up request"
                                )
                            }
                        >
                            Reject
                            {approvalIsLoading &&
                                request.current.type === "REJECTED" &&
                                request.current.id ===
                                    (row?.original?.id ||
                                        row?.original?.corporate_top_up
                                            ?.id) && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "black",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                        </Button>
                    </>
                )}
                {(row?.original?.status === "APPROVED" ||
                    row?.original?.corporate_top_up?.status === "APPROVED") &&
                    HasPermission("get receipt by transaction ID") && (
                        <Tooltip title="Receipt">
                            <IconButton
                                onClick={() =>
                                    navigate(
                                        `${
                                            row?.original
                                                ?.accounting_transaction_id ||
                                            row?.original?.corporate_top_up
                                                ?.accounting_transaction_id
                                        }`
                                    )
                                }
                            >
                                <Receipt sx={{ color: "primary.main" }} />
                            </IconButton>
                        </Tooltip>
                    )}
            </Box>
        ),
        [approvalIsLoading, approve]
    )
    const getStatus = useCallback(
        ({ cell }) => (
            <Box
                sx={{
                    width: "130px",
                }}
            >
                <Alert
                    severity="info"
                    icon={false}
                    sx={{
                        justifyContent: "center",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        backgroundColor: `${receiptStatusBgColor(
                            topUps === undefined
                                ? cell?.row?.original?.corporate_top_up?.status
                                : cell?.row?.original?.status
                        )}`,
                        color: `${receiptStatusColor(
                            topUps === undefined
                                ? cell?.row?.original?.corporate_top_up?.status
                                : cell?.row?.original?.status
                        )}`,
                        fontWeight: "bold",
                    }}
                >
                    {topUps === undefined
                        ? cell?.row?.original?.corporate_top_up?.status?.toLowerCase()
                        : cell?.row?.original?.status?.toLowerCase()}
                </Alert>
            </Box>
        ),
        [topUps]
    )

    useEffect(() => {
        if (error && topUps !== undefined) {
            refetchInvoices()
        } else {
            refetch()
        }
    }, [error, refetch, refetchInvoices, topUps])
    useEffect(() => {
        if (topUpFetchError && topUps === undefined)
            enqueueSnackbar(
                error?.response?.data?.error?.message ||
                    error?.message ||
                    "Network Error!",
                {
                    variant: "error",
                    preventDuplicate: true,
                    autoHideDuration: 2000,
                }
            )
    }, [
        enqueueSnackbar,
        error?.message,
        error?.response?.data?.error?.message,
        topUpFetchError,
        topUps,
    ])

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                accessorKey: "created_at",
                header: "Time",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? DateTime.fromISO(row?.original?.created_at).toFormat(
                              "ff"
                          )
                        : DateTime.fromISO(
                              row?.original?.corporate_top_up?.created_at
                          ).toFormat("ff"),
            },
            {
                size: 240,
                accessorKey: "created_by",
                header: "Created By",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? `${row?.original?.creator_first_name} ${row?.original?.creator_middle_name} ${row?.original?.creator_last_name}`
                        : `${row?.original?.corporate_top_up?.creator_first_name} ${row?.original?.corporate_top_up?.creator_middle_name} ${row?.original?.corporate_top_up?.creator_last_name}`,
            },
            {
                size: 240,
                accessorKey: "creator_phone",
                header: "Creator Phone",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? row?.original?.creator_phone
                        : row?.original?.corporate_top_up?.creator_phone,
            },
            {
                size: 240,
                accessorKey: "bank_name",
                header: "Bank",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? row?.original?.bank_name
                        : row?.original?.corporate_top_up?.bank_name,
            },
            {
                size: 240,
                accessorKey: "account_number",
                header: "Account Number",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? row?.original?.account_number
                        : row?.original?.corporate_top_up?.account_number,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "reference_number",
                header: "Reference Number",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? row?.original?.reference_number
                        : row?.original?.corporate_top_up?.reference_number,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "amount",
                header: "Amount (ETB)",
                filterVariant: "number",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? `${roundToTwoDecimalPlaces(
                              row?.original?.amount ?? 0
                          )}`
                        : `${roundToTwoDecimalPlaces(
                              row?.original?.corporate_top_up?.amount ?? 0
                          )}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "remark",
                header: "Note",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? row?.original?.remark
                        : row?.original?.corporate_top_up?.remark,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 240,
                accessorKey: "approved_by",
                header: "Approved By",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? `${invoice?.approver_first_name} ${invoice?.approver_middle_name} ${invoice?.approver_last_name}`
                        : `${row?.original?.receipt?.approver_first_name} ${row?.original?.receipt?.approver_middle_name} ${row?.original?.receipt?.approver_last_name}`,
            },
            {
                size: 240,
                accessorKey: "approer_phone",
                header: "Approver Phone",
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? invoice?.approver_phone
                        : row?.original?.receipt?.approver_phone,
            },
            {
                size: 240,
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: actions,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: [
                    "PENDING",
                    "APPROVED",
                    "FAILED",
                    "REJECTED",
                ],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "updated_at",
                header: "Updated At",
                size: 200,
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ row }) =>
                    topUps !== undefined
                        ? DateTime.fromISO(row?.original?.updated_at).toFormat(
                              "ff"
                          )
                        : DateTime.fromISO(
                              row?.original?.corporate_top_up?.updated_at
                          ).toFormat("ff"),
            },
        ],
        [actions, getStatus, invoice, topUps]
    )

    // RENDERING
    if (topUps === undefined && !HasPermission("get pre paid top ups"))
        return <Unauthorized />

    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <TopUp
                    accountType={state?.companyData?.account_type}
                    companyId={state?.corporateId}
                    invoice={topUps !== undefined ? invoice : {}}
                    topUpData={topUpData}
                    open={open}
                    setOpen={setOpen}
                    refetch={topUps === undefined ? refetch : refetchInvoices}
                    setTopUpData={setTopUpData}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={
                        (topUps === undefined
                            ? data?.data?.data
                            : getInvoice) ?? []
                    }
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualPagination
                    enableSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("add corporate top up request") &&
                                state?.companyData?.account_type ===
                                    "PREPAID" && (
                                    <Button
                                        size="small"
                                        sx={{
                                            bgcolor: "primary.main",
                                            color: "white",
                                            px: 2,
                                            mx: 1,
                                            boxShadow: 2,
                                            " :hover": {
                                                bgcolor: "primary.main",
                                                color: "white",
                                            },
                                        }}
                                        onClick={() => setOpen(true)}
                                    >
                                        TopUp
                                    </Button>
                                )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            {topUps === undefined && (
                                <>
                                    <Tooltip arrow title="Refresh Data">
                                        <IconButton onClick={() => refetch()}>
                                            <Refresh />
                                        </IconButton>
                                    </Tooltip>
                                    <CustomExportOptions
                                        table={table}
                                        model={filterModel(
                                            filterFns,
                                            columnFilters
                                        )}
                                        page={`system/corporate/${
                                            state?.corporateId
                                                ? state?.corporateId
                                                : state?.corporateData?.id
                                        }/top_ups`}
                                        formatter={
                                            topUps === undefined
                                                ? handleReceiptsExport
                                                : handleDateExport
                                        }
                                    />
                                </>
                            )}
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={
                        topUps !== undefined
                            ? getInvoice?.length
                            : data?.data?.meta_data?.total
                    }
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons:
                            topUps !== undefined
                                ? invoiceIsFetching
                                : isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default Receipts
