import React, { useEffect, useRef } from "react"
import { FormControlLabel, Switch } from "@mui/material"

const color = {
    active: "#33ca51",
    inactive: "#dd4848",
    pending: "gray",
}

function StatusButton({ status, onChange, loading, unauthorized }) {
    const pending = useRef(false)

    useEffect(() => {
        if (!loading) pending.current = false
    }, [loading])

    const handleChange = (e) => {
        const data = e.target.checked
        onChange(data)
        pending.current = true
    }
    // I disable state should be updated on the next rerender
    return (
        <FormControlLabel
            labelPlacement="start"
            label={status}
            checked={status === "ACTIVE"}
            disabled={pending.current || unauthorized}
            onChange={handleChange}
            control={
                <Switch
                    disableRipple
                    disabled={pending.current || unauthorized}
                    size="small"
                    sx={{
                        "& .MuiSwitch-switchBase": {
                            "&.Mui-checked": {
                                "& + .MuiSwitch-track": {
                                    backgroundColor: "#c3eacb",
                                },
                                "& .MuiSwitch-thumb": {
                                    color: color.active,
                                },
                                "&.Mui-disabled + .MuiSwitch-thumb": {
                                    backgroundColor: "lightseagreen",
                                    color: "lightgreen",
                                    opacity: 0.6,
                                },
                            },
                        },
                        "& .MuiSwitch-thumb": {
                            color: "#f86049b5",
                        },
                        "& .MuiSwitch-track": {
                            backgroundColor: "#d9acac",
                        },
                    }}
                />
            }
            sx={{
                backgroundColor: status === "ACTIVE" ? "#e5ffea" : "#ffd6d6",
                opacity: pending.current ? 0.4 : 1,
                color: status === "ACTIVE" ? color.active : "#f86049b5",
                px: ".5em",
                py: ".5em",
                borderRadius: 1,
                width: "17ch",
                fontWeight: 900,
            }}
        />
    )
}

export default StatusButton
