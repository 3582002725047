import React, { useState } from "react"
import { FileDownload } from "@mui/icons-material"
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material"
import { ExportToCsv } from "export-to-csv"
import useExport from "../Hooks/useExport.js"

function CustomExportOptions({ table, model, formatter, page, linkOperator }) {
    const [anchorEl, setAnchorEl] = useState(null)
    const openMenu = Boolean(anchorEl)

    const csvOptions = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
    }

    const csvExporter = new ExportToCsv(csvOptions)
    const newModel =
        // eslint-disable-next-line no-nested-ternary
        model?.length > 1 &&
        model[Number(model?.length) - 2]?.value?.includes("passenger_id")
            ? [
                  model[Number(model?.length) - 1],
                  model[Number(model?.length) - 2],
              ]
            : model.length === 0
            ? model
            : [model[Number(model?.length) - 1]]
    const paths = page?.split("/")

    // Fetch all data
    const { refetch: fetchAllData } = useExport(
        `${page}`,
        page?.split("/")[paths.length - 1]?.includes("transactions") ||
            page?.includes("loyalties")
            ? newModel
            : [],
        formatter,
        linkOperator ?? "and"
    )

    // Fetch filtered data
    const { refetch: fetchFilteredData } = useExport(
        `${page}`,
        model,
        formatter,
        linkOperator ?? "and"
    )

    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleExportRows = (rows) => {
        const dataToBeExported = rows?.map((item) => item.original)
        csvExporter.generateCsv(formatter(dataToBeExported))
    }

    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
            }}
        >
            <IconButton size="small" onClick={handleClick}>
                <FileDownload />
            </IconButton>
            <Menu
                sx={{
                    mt: 0.5,
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#fff",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                            p: "0.5rem",
                            flexWrap: "wrap",
                        }}
                    >
                        <MenuItem onClick={() => fetchAllData()}>
                            {" "}
                            <ListItemIcon>
                                <FileDownload />
                            </ListItemIcon>
                            <ListItemText>All Data</ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={model?.length === 0}
                            onClick={() => fetchFilteredData()}
                        >
                            <ListItemIcon>
                                <FileDownload />
                            </ListItemIcon>
                            <ListItemText>Filtered</ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={table.getRowModel().rows?.length === 0}
                            onClick={() =>
                                handleExportRows(table.getRowModel().rows)
                            }
                        >
                            <ListItemIcon>
                                <FileDownload />
                            </ListItemIcon>
                            <ListItemText> Current Page</ListItemText>
                        </MenuItem>
                        <MenuItem
                            disabled={
                                !table.getIsSomeRowsSelected() &&
                                !table.getIsAllRowsSelected()
                            }
                            onClick={() =>
                                handleExportRows(
                                    table.getSelectedRowModel().rows
                                )
                            }
                        >
                            <ListItemIcon>
                                <FileDownload />
                            </ListItemIcon>
                            <ListItemText>Selected</ListItemText>
                        </MenuItem>
                    </Box>
                </Box>
            </Menu>
        </Box>
    )
}
export default CustomExportOptions
