import React, { useEffect, useMemo, useRef, useState } from "react"
import L from "leaflet"
import fscreen from "fscreen"
import { useQuery } from "react-query"
import PolylineUtil from "polyline-encoded"
import { Box, DialogContent, Divider, Grid, Typography } from "@mui/material"
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet"

import "../Assets/mapIcons.css"
import { BASE_URL, VERSION } from "../Utils/config.js"
import { Polyline } from "./mapContainer.jsx"
import {
    convertMStoKMH,
    formatTime,
    formatToThreeDecimalPlaces,
    formatToTwoDecimalPlaces,
} from "../Utils/dataFormat.js"
import useAxiosPrivate from "../Hooks/useAxiosPrivate.js"
import HasPermission from "../Utils/access.js"

const endMarkerIcon = new L.Icon({
    iconUrl:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Circle-icons-taxi.svg/2048px-Circle-icons-taxi.svg.png",
    iconSize: [35, 35],
    iconAnchor: [15, 20],
    popupAnchor: [0, -25],
})

const startMarkerIcon = new L.Icon({
    iconUrl:
        "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-10/256/Trafficlight-green-icon.png",
    iconSize: [18, 18],
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "#000",
    popupAnchor: [0, -25],
})

const FullScreenToggle = () => {
    const map = useMap()
    const container = map.getContainer()

    const handler = () => {
        if (fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(container)
        } else {
            fscreen.exitFullscreen()
        }
    }

    L.Control.FullScreenToggle = L.Control.extend({
        onAdd: function () {
            const control = document.querySelector("#fullscreen")

            if (!control) {
                const IconContainer = L.DomUtil.create("div", "fullscreen")
                IconContainer.setAttribute("id", "fullscreen")
                L.DomUtil.create("div", "fullscreen-inner", IconContainer)
                IconContainer.addEventListener("click", () => {
                    if (fscreen.fullscreenEnabled) handler()
                })
                return IconContainer
            }

            return control
        },
        onRemove: function () {},
    })

    L.control.fullscreen = function (opt) {
        return new L.Control.FullScreenToggle(opt)
    }

    L.control.fullscreen({ position: "topleft" }).addTo(map)
}
function StartedTripMapComponent(props) {
    const { id, refetch } = props

    const axiosPrivate = useAxiosPrivate()

    const [polylineDecoded, setPolylineDecoded] = useState([])
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [tripData, setTripData] = useState(null)

    const socket = useRef(null) // Use a ref for the WebSocket instance

    const handleFullScreenChange = () => {
        setIsFullScreen(document.fullscreenElement !== null)
    }

    const { data: code } = useQuery(
        ["ws_code"],
        () => axiosPrivate.get("/system/trip/admin/code"),
        {
            enabled: HasPermission("initiate trip connection for admin"),
        }
    )

    useEffect(() => {
        if (code?.data?.data?.code) {
            socket.current = new WebSocket(
                `${BASE_URL.replace(
                    "https",
                    "wss"
                )}/${VERSION}/system/trip/track/${id}?code=${
                    code?.data?.data?.code
                }`
            )

            // Cleanup function to close the WebSocket connection
            return () => {
                socket.current.close()
            }
        }

        return () => {}
    }, [code, id])
    useEffect(() => {
        if (code?.data?.data?.code) {
            socket.current.onmessage = (event) => {
                const data = JSON.parse(event.data)
                if (data.ok === true) {
                    setTripData(data)
                    if (data.data.encoded_poly_line) {
                        const decodedCoordinates = PolylineUtil.decode(
                            data?.data?.encoded_poly_line
                        )
                        setPolylineDecoded(decodedCoordinates)
                    } else if (data.data.is_trip_ended) {
                        refetch()
                    } else {
                        const locationUpdateCoordinates =
                            data.meta_data.extra.location_updates.map(
                                (item) => [item.latitude, item.longitude]
                            )
                        setPolylineDecoded((prev) => [
                            ...prev,
                            ...locationUpdateCoordinates,
                        ])
                    }
                }
            }

            socket.current.onclose = () => {
                if (tripData) refetch()
            }
        }
    }, [code?.data?.data?.code, id, refetch, tripData])

    useEffect(() => {
        document.addEventListener("fullscreenchange", handleFullScreenChange)
        return () => {
            document.removeEventListener(
                "fullscreenchange",
                handleFullScreenChange
            )
        }
    }, [])

    const startingPointPosition = polylineDecoded[0]
    const endPointPosition = polylineDecoded[polylineDecoded.length - 1]

    const locationUpdates = tripData?.meta_data?.extra?.location_updates

    const popupHeight = isFullScreen ? "130px" : "120px"

    const tripDetails = useMemo(
        () => [
            {
                name: "Distance",
                value:
                    Number(tripData?.data?.data?.distance) > 1000
                        ? `${formatToThreeDecimalPlaces(
                              Number(tripData?.data?.data?.distance) / 1000
                          )} km`
                        : `${formatToThreeDecimalPlaces(
                              tripData?.data?.data?.distance
                          )} m`,
            },
            {
                name: "Waiting Time",
                value: formatTime(Number(tripData?.data?.data?.waiting_time)),
            },
            {
                name: "Speed",
                value: locationUpdates
                    ? `${convertMStoKMH(
                          Number(
                              tripData?.meta_data?.extra?.location_updates[
                                  locationUpdates.length - 1
                              ]?.speed
                          )
                      )} km/hr`
                    : "0 km/hr",
            },
            {
                name: "Cost",
                value: `${formatToTwoDecimalPlaces(
                    tripData?.data?.data?.cost
                )} ETB`,
            },
            {
                name: "Remaining Credit",
                value: `${formatToTwoDecimalPlaces(
                    tripData?.meta_data?.extra?.driver_remaining_credit
                )} ETB`,
            },
        ],
        [locationUpdates, tripData]
    )

    return HasPermission("initiate trip connection for admin") && socket.current && (
            <MapContainer style={{ height: "100%", width: "100%" }}>
                <Marker
                    position={startingPointPosition}
                    icon={startMarkerIcon}
                />
                <Marker position={endPointPosition} icon={endMarkerIcon}>
                    <Popup>
                        <DialogContent>
                            <Box
                                sx={{
                                    width: { xs: "150px", sm: "350px" },
                                    maxHeight: popupHeight,
                                    maxWidth: "100%",
                                }}
                            >
                                <Grid
                                    container
                                    sx={{
                                        "& > * + *": {
                                            mt: "0px !important",
                                        },
                                    }}
                                >
                                    {tripDetails?.map((item, index) => (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={4}
                                                key={item.name}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: 10,
                                                        mt: 0,
                                                    }}
                                                >
                                                    {" "}
                                                    {item.name}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontWeight: "bold",
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    {" "}
                                                    {item.value}
                                                </Typography>
                                            </Grid>
                                            {(index + 1) % 3 === 0 && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={item.name}
                                                >
                                                    <Divider />
                                                </Grid>
                                            )}
                                        </>
                                    ))}
                                </Grid>
                            </Box>
                        </DialogContent>
                    </Popup>
                </Marker>
                {polylineDecoded.length > 0 && (
                    <Polyline polylineDecoded={polylineDecoded} />
                )}
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <FullScreenToggle />
            </MapContainer>
        )
}

export default StartedTripMapComponent
