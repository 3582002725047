import React, { useEffect } from "react"
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import { useSnackbar } from "notistack"
import { useMutation, useQuery } from "react-query"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"

function IncompletePaymentForm(props) {
    const { open, setOpen, refetch, id } = props
    // HOOK FORM
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onChange" })

    const { enqueueSnackbar } = useSnackbar()

    const axiosPrivate = useAxiosPrivate()

    const { data: banks } = useQuery(
        ["banks"],
        () => axiosPrivate.get(`/system/configs`),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )
    const { mutate, data, isLoading, error } = useMutation((formData) =>
        axiosPrivate.post(`/system/drivers/${id}/credit`, formData)
    )

    const onSubmit = (inputData) => {
        mutate(inputData)
    }

    useEffect(() => {
        if (data) {
            enqueueSnackbar(`Your request is sent succesfully.`, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
            })
            refetch()
            setOpen(false)
        }
    }, [data, enqueueSnackbar, refetch, setOpen])

    useEffect(() => {
        if (error) {
            if (error?.response?.data?.error?.field_error?.length > 0) {
                error?.response?.data?.error?.field_error?.map((msg) =>
                    enqueueSnackbar(msg.description || "Network Error!", {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    })
                )
            } else {
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                )
            }
        }
    }, [enqueueSnackbar, error, setOpen])

    return (
        <Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
            <DialogTitle
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Box>
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        Request Payment
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        mx: 1,
                    }}
                >
                    <Controller
                        name="bank_name"
                        control={control}
                        rules={{
                            required: "Please select a bank.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Bank"
                                margin="normal"
                                fullWidth
                                select
                                error={!!errors?.bank_name}
                                helperText={
                                    errors?.bank_name
                                        ? errors.bank_name?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            >
                                {banks?.data?.data?.credit_top_up?.credit_top_up_banks?.map(
                                    (item) => (
                                        <MenuItem
                                            value={item?.name}
                                            key={item?.name}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        width: 30,
                                                        height: 30,
                                                        mr: 1,
                                                    }}
                                                    src={item?.logo}
                                                />
                                                {item.name}
                                            </Box>
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        )}
                    />
                    <Controller
                        name="reference_number"
                        control={control}
                        rules={{
                            required: "Reference number is required",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Reference Number"
                                fullWidth
                                margin="normal"
                                autoComplete="reference_number"
                                error={!!errors?.reference_number}
                                helperText={
                                    errors?.reference_number
                                        ? errors.reference_number.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="amount"
                        control={control}
                        rules={{
                            required: "Amount is required.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Amount"
                                margin="normal"
                                type="number"
                                fullWidth
                                error={!!errors?.amount}
                                helperText={
                                    errors?.amount
                                        ? errors.amount?.message
                                        : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="type"
                        control={control}
                        rules={{
                            required: "Credit Type is required.",
                        }}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Credit Type"
                                margin="normal"
                                fullWidth
                                select
                                error={!!errors?.type}
                                helperText={
                                    errors?.type ? errors.type?.message : null
                                }
                                size="small"
                                sx={{
                                    mt: 1.5,
                                    mb: 0.5,
                                    mr: -2,
                                    flex: 2,
                                }}
                                {...field}
                            >
                                <MenuItem value="RIDE_CREDIT">
                                    Ride Credit
                                </MenuItem>
                                <MenuItem value="RIDE_PLUS_CREDIT">
                                    Plus Credit
                                </MenuItem>
                            </TextField>
                        )}
                    />
                    <Controller
                        name="remark"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                variant="outlined"
                                label="Remark"
                                fullWidth
                                multiline
                                rows={4}
                                margin="normal"
                                autoComplete="note"
                                error={!!errors?.note}
                                helperText={
                                    errors?.note ? errors.note.message : null
                                }
                                size="small"
                                {...field}
                            />
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    mt: 1,
                    pr: 3,
                    pt: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    bgcolor: "#fafafa",
                }}
            >
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        ":hover": { bgcolor: "#e4e4e4" },
                        backgroundColor: "#e4e4e4",
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    onClick={() => setOpen(false)}
                >
                    <Typography>Cancel</Typography>
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    sx={{
                        bgcolor: "#fdbc3d",
                        ":hover": { bgcolor: "#fdbc3d" },
                        textTransform: "none",
                        fontWeight: "bold",
                        width: 120,
                    }}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    <Typography sx={{ color: "secondary.main" }}>
                        Submit
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "black",
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default IncompletePaymentForm
