/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import { Box, Button,  IconButton, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import customFilterPanel from "../../Component/customFilter.jsx"
import filterModel from "../../Utils/filterModel.js"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"

// List Drivers Component
function Trips() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        full_name: "contains",
        phone: "contains",
        email: "contains",
        start_point_name: "contains",
        end_point_name: "contains",
        pickup_time: "is",
        dropoff_time: "is",
        final_cost: "=",
        created_at: "is",
        updated_at: "is",
        distance: "is",
        first_name: "contains",
        driver_phone: "contains",
        passenger_phone: "contains",
        reason: "contains",
        status: "is",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "drivers",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`/system/trips/ride`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters) ?? undefined
                    ),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get ride trips"),
        }
    )

    const getStatus = useCallback(
        ({ cell }) => (
            <Button
                key={cell?.row?.original?.id}
                disableElevation
                variant="contained"
                sx={{
                    borderRadius: "10px",
                    fontWeight: "bold",
                    backgroundColor:
                        cell?.row?.original?.status === "PAID"
                            ? "#E5FFEA"
                            : "#ffd6d6",
                    color:
                        cell?.row?.original?.status === "PAID"
                            ? "#16C138"
                            : "red",
                    "&: hover": {
                        backgroundColor:
                            cell?.row?.original?.status === "PAID"
                                ? "#E5FFEA"
                                : "#ffd6d6",
                        color:
                            cell?.row?.original?.status === "PAID"
                                ? "#16C138"
                                : "red",
                    },
                }}
            >
                {cell?.row?.original?.status}
            </Button>
        ),
        []
    )

    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "start_point_name",
                header: "Start Location",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "end_point_name",
                header: "End Location",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "pickup_time",
                header: "Start Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "dropoff_time",
                header: "End Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "cost",
                header: "Cost",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 260,
                accessorKey: "distance",
                header: "Distance",
                filterVariant: "number",
                Cell: ({ cell }) => roundToTwoDecimalPlaces(cell.getValue()),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "first_name",
                header: "Driver Name",
                Cell: ({ row }) =>
                    `${row.original?.driver_info?.first_name} ${row.original?.driver_info?.middle_name}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Driver Phone",
                Cell: ({ row }) => `+${row.original?.driver_info?.phone}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 270,
                accessorKey: "passenger_phone",
                header: "Passenger Phone",
                filterVariant: "number",
                Cell: ({ cell }) => roundToTwoDecimalPlaces(cell.getValue()),
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "created_at",
                header: "Paymnet Request Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                size: 300,
                accessorKey: "reason",
                header: "Reason",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["PAID", "UNPAID"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
        ],
        [getStatus]
    )

    // RENDER
    if (!HasPermission("get ride trips")) {
        return <Unauthorized />
    }
    return (
        <Box sx={{ flex: 1, height: "100%" }}>
            <MaterialReactTable
                columns={columns}
                data={data?.data?.data ?? []}
                initialState={{
                    columnPinning: {
                        left: ["mrt-row-select"],
                        right: ["action"],
                    },
                }}
                enableColumnResizing
                enableColumnFilterModes
                enableStickyHeader
                enableColumnOrdering
                enableRowSelection
                enablePinning
                manualFiltering
                manualPagination
                manualSorting
                filterFns={{
                    after: (row, filterValue) =>
                        row.customField === filterValue,
                }}
                muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                    helperText: `Filter Mode: ${filterFns[column?.id]}`,
                })}
                onColumnFiltersChange={setColumnFilters}
                onColumnFilterFnsChange={setFilterFns}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                renderToolbarInternalActions={({ table }) => (
                    <>
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <CustomExportOptions
                            table={table}
                            model={filterModel(filterFns, columnFilters)}
                            page="/system/trips/ride"
                            formatter={handleDateExport}
                        />
                        <MRT_ToggleFiltersButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_FullScreenToggleButton table={table} />
                    </>
                )}
                muiTableBodyCellProps={({ table, column }) => {
                    const columnName = column.id
                    const pinnedState = table.getState().columnPinning

                    const isLeftPinned = pinnedState?.left?.some(
                        (el) => el === columnName
                    )
                    const isRightPinned = pinnedState?.right?.some(
                        (el) => el === columnName
                    )
                    if (isLeftPinned || isRightPinned)
                        return {
                            sx: {
                                "&.MuiTableCell-root": {
                                    boxShadow: isRightPinned
                                        ? "-7px 0px 10px -1.7px lightgray"
                                        : "7px 0px 10px -1.7px lightgray",
                                },
                            },
                        }
                    return null
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: `calc(100vh - 225px)` },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                rowCount={data?.data?.meta_data?.total ?? 0}
                state={{
                    columnFilters,
                    filterFns,
                    globalFilter,
                    isFetching,
                    pagination,
                    showSkeletons: isFetching,
                    sorting,
                }}
            />
        </Box>
    )
}

export default Trips
