/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useRef, useState } from "react"
import {
    Avatar,
    Box,
    CircularProgress,
    IconButton,
    Typography,
} from "@mui/material"
import { Download, Email, Phone, Signpost } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
import html2canvas from "html2canvas"
import { DateTime } from "luxon"
import jsPDF from "jspdf"

import EarningInfo from "./earningTable.jsx"
import ride from "../../Assets/RIDE_logo.png"
import stamp from "../../Assets/Hybride designs Stamp.png"
import ridePlus from "../../Assets/ride_logo.png"
import rideFood from "../../Assets/ride_food.jpg"
import hybridDesigns from "../../Assets/hybridDesigns.jpg"
import useBreakPoints from "../../Hooks/useBreakPoints.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import ErrorPage from "../../Component/errorPage.jsx"

const logo = [
    { title: "RIDE", img: ride, width: 50, height: 35 },
    { title: "RIDE PLUS", img: ridePlus, width: 50, height: 35 },
    { title: "RIDE FOOD", img: rideFood, width: 50, height: 30 },
]
function TaxReport() {
    const { phone } = useParams()
    const { sm, md, xs, lg } = useBreakPoints()
    const taxReportRef = useRef({
        style: {
            transform: lg
                ? "scale(1)"
                : md
                ? "scale(0.8)"
                : sm
                ? "scale(0.6)"
                : xs
                ? "scale(0.45)"
                : "scale(1)",
        },
    })
    const btnRef = useRef({
        style: { transform: "scale(1)" },
    })
    const [isDownloading, setIsDownloading] = useState(false)

    // Get the current date
    const currentDate = DateTime.now()
    // Get the current year
    const currentYear = currentDate.year

    // Create a date with the current year
    const dateCurrentYear = DateTime.fromObject({
        year: currentYear,
        // ሰኔ 30
        month: 7,
        day: 7,
    })

    // Create a date with the previous year (ሐምሌ 01)
    const datePreviousYear = DateTime.fromObject({
        year: currentYear - 1,
        // ሐምሌ 01
        month: 7,
        day: 8,
    })

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isLoading, data, error } = useQuery(["receips"], () =>
        axiosPrivate.get(`driver/${phone}/tax`, {
            params: {
                per_page: -1,
                filter: JSON.stringify(
                    [
                        {
                            column_field: "created_at",
                            operator_value: "between",
                            value: `${datePreviousYear.toISODate()}|${dateCurrentYear.toISODate()}`,
                        },
                    ] ?? undefined
                ),
            },
        })
    )
    // The driver info that issued the tax report
    const requestedDriver = useMemo(
        () =>
            data?.data?.data?.filter((item) =>
                phone?.includes(item?.driver_phone)
            ),
        [data?.data?.data, phone]
    )

    // PDF download handler
    const handleDownloadPDF = async () => {
        setIsDownloading(true)
        const receiptContainer = taxReportRef.current
        const btnElement = btnRef.current

        // Store the original display value of the button
        const originalDisplay = btnElement.style.display

        // Hide the button temporarily
        btnElement.style.display = "none"

        const canvas = await html2canvas(receiptContainer, {
            allowTaint: true,
            useCORS: true,
            scale: window.devicePixelRatio,
        })

        // Restore the button's display
        btnElement.style.display = originalDisplay

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF("p", "mm", "a4")

        // Calculate the ratio to fit the content within the A4 dimensions
        const scaleFactor = 210 / canvas.width

        // Add the captured content to the PDF
        pdf.addImage(
            canvas,
            "PNG",
            0,
            0,
            canvas.width * scaleFactor,
            canvas.height * scaleFactor
        )

        // Save the PDF
        pdf.save(`tax-report`)
        setIsDownloading(false)
    }

    // Adjust zoom level when component mounts or screen size changes
    useEffect(() => {
        const zoomedContainer = taxReportRef.current
        const handleResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(0.8)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(0.6)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(0.45)"
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [xs, sm, md, lg, taxReportRef])
    useEffect(() => {
        const zoomedContainer = btnRef.current
        const handleBtnResize = () => {
            if (lg) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (md) {
                zoomedContainer.style.transform = "scale(1)"
            } else if (sm) {
                zoomedContainer.style.transform = "scale(1.1)"
            } else if (xs) {
                zoomedContainer.style.transform = "scale(1.2)"
            }
        }

        handleBtnResize()
        window.addEventListener("resize", handleBtnResize)
        return () => window.removeEventListener("resize", handleBtnResize)
    }, [xs, sm, md, lg, btnRef])

    // RENDER
    if (error) return <ErrorPage error={error} />

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100vh",
                overflowX: "auto",
                p: 1,
            }}
        >
            <div
                id="container"
                style={{
                    transformOrigin: "top center",
                }}
                ref={taxReportRef}
            >
                <Box
                    sx={{
                        border: 2,
                        borderColor: "#fdbc3d",
                        bgcolor: "#fff",
                        px: 4,
                        pt: 4,
                        m: xs ? "2em" : "0.5em",
                        position: "relative",
                        width: "210mm", // A4 width
                        minHeight: "297mm", // A4 height
                        overflow: "hidden", // Contain the watermark
                        "&::before": {
                            content: '"8294"',
                            position: "absolute",
                            left: "-19%",
                            top: "50%",
                            transform: "translateY(-50%) rotate(-90deg)",
                            fontSize: "15rem",
                            fontWeight: "bold",
                            color: "rgba(0, 0, 0, 0.05)",
                            zIndex: 999,
                            pointerEvents: "none",
                            transformOrigin: "center",
                            whiteSpace: "nowrap",
                            display: isLoading && "none",
                        },
                    }}
                    id="tax-report"
                >
                    {isLoading ? (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress
                                size={60}
                                sx={{
                                    position: "absolute",
                                    top: sm ? "25%" : "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    pb: 2,
                                    mb: 2,
                                    my: -4,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        ml: -2,
                                    }}
                                >
                                    <img
                                        src={hybridDesigns}
                                        alt="HYBRID"
                                        style={{ height: 130 }}
                                    />
                                </Box>
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {logo?.map((item) => (
                                            <Box
                                                key={item.title}
                                                sx={{
                                                    width: item.width,
                                                    height: item.height,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt:
                                                        item.height === 30
                                                            ? 0.5
                                                            : 0,
                                                }}
                                            >
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    style={{
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                    <Typography
                                        textAlign="center"
                                        variant="body2"
                                    >
                                        www.ride8294.com
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{ textAlign: "end", fontWeight: 700 }}
                                >
                                    Date:- {currentDate.toFormat("ff")}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ textAlign: "end", fontWeight: 700 }}
                                >
                                    Online ID:- {data?.data?.data[0]?.id}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: "flex-start",
                                        mt: "2em",
                                        fontWeight: 700,
                                    }}
                                >
                                    Addis Ababa City Administration Revenue
                                    Authority{" "}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: "flex-start",
                                        fontWeight: 700,
                                    }}
                                >
                                    ………………………. District Small Tax Payers Office
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: "flex-start",
                                        fontWeight: 700,
                                    }}
                                >
                                    Addis Ababa
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: "2em",
                                    gap: 3,
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontWeight: 700,
                                        textAlign: "center",
                                    }}
                                >
                                    Subject:-{" "}
                                    <u>
                                        Provision of RIDE Drivers` Income
                                        Information
                                    </u>
                                </Typography>
                                <Typography variant="body1">
                                    In response to your request for the annual
                                    income information of{" "}
                                    <strong>
                                        Mr. {requestedDriver?.[0]?.driver_name}
                                    </strong>
                                    , who has been providing{" "}
                                    <strong>RIDE</strong> services under a
                                    partnership agreement with our company, we
                                    hereby submit the required details for your
                                    office`s annual income tax calculation
                                    purposes.
                                </Typography>
                                <Typography variant="body1">
                                    Based on our records, the tax-payer`s income
                                    information from{" "}
                                    <strong>
                                        {datePreviousYear.toFormat("DDD")}
                                    </strong>{" "}
                                    to{" "}
                                    <strong>
                                        {currentDate < dateCurrentYear
                                            ? currentDate.toFormat("DDD")
                                            : dateCurrentYear.toFormat("DDD")}
                                    </strong>
                                    , while operating a vehicle with the plate
                                    number code{" "}
                                    <strong>
                                        {data?.data?.data[0]?.plate_number}
                                    </strong>
                                    , is as follows.
                                </Typography>
                                <EarningInfo data={data?.data?.data} />
                                <Typography variant="body1">
                                    This income has been calculated based on the
                                    total number of trips started and
                                    finished/completed by the tax-payer using
                                    our company`s system.
                                </Typography>
                            </Box>
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: "start",
                                    mr: "2em",
                                    mt: "1em",
                                    fontWeight: 700,
                                }}
                            >
                                With Best Regards,
                            </Typography>
                            <Avatar
                                sx={{
                                    width: sm ? 150 : 150,
                                    height: sm ? 140 : 140,
                                    bgcolor: "transparent",
                                    position:
                                        data?.data?.data?.length <= 6
                                            ? "relaive"
                                            : "absolute",
                                    top: "81%",
                                    left: "20%",
                                    zIndex: 999,
                                }}
                            >
                                <img
                                    src={stamp}
                                    alt="Stamp"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                    }}
                                />
                            </Avatar>{" "}
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    p: 2,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pl: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            width: "100%",
                                            gap: "0.5em",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    border: 2,
                                                    color: "#1e2050",
                                                }}
                                            >
                                                <Phone fontSize="small" />
                                            </IconButton>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="#1e2050"
                                        >
                                            +251 116 574866
                                            <br />
                                            +251 116 506004
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            gap: "0.5em",
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            sx={{ border: 2, color: "#1e2050" }}
                                        >
                                            <Email fontSize="small" />
                                        </IconButton>
                                        <Typography
                                            variant="body2"
                                            color="#1e2050"
                                        >
                                            info@hybriddesignsplc.com
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            gap: "0.5em",
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            sx={{ border: 2, color: "#1e2050" }}
                                        >
                                            <Signpost fontSize="small" />
                                        </IconButton>
                                        <Typography
                                            variant="body2"
                                            color="#1e2050"
                                        >
                                            P.O Box 1960, CODE 1110
                                        </Typography>
                                    </Box>
                                </Box>

                                <Typography
                                    variant="caption"
                                    sx={{
                                        mt: 2,
                                        display: "block",
                                        textAlign: "center",
                                        color: "#e98e3a",
                                    }}
                                >
                                    Please Respond to Hybrid Designs PLC using
                                    one of the following recognized methods
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
                <Box
                    id="download-btn"
                    ref={btnRef}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                        "@media print": {
                            display: "none",
                        },
                    }}
                >
                    {!isLoading && (
                        <IconButton
                            size="small"
                            sx={{
                                bgcolor: "#fdbc3d",
                                color: "black",
                                ":hover": { bgcolor: "#fdbc3d" },
                                borderRadius: 5,
                                px: 2,
                                py: 1,
                                gap: 0.5,
                                width: 250,
                            }}
                            disabled={isDownloading}
                            onClick={handleDownloadPDF}
                        >
                            <Typography
                                variant="body2"
                                fontWeight={900}
                                textAlign="center"
                            >
                                Download
                            </Typography>
                            <Download />
                        </IconButton>
                    )}
                </Box>
            </div>
        </Box>
    )
}

export default TaxReport
