import React, { useMemo } from "react"
import { Box, Typography } from "@mui/material"
import MaterialReactTable from "material-react-table"
import {
    roundToThreeDecimalPlaces,
    roundToTwoDecimalPlaces,
} from "../Utils/dataFormat.js"

function TripsCount({ data }) {
    const getStatus = ({ row }) => (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Box
                sx={{
                    backgroundColor: `${row?.original?.status?.color}`,
                    p: 1,
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    ml: 0.5,
                    mr: 1.5,
                }}
            />
            <Typography
                sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                }}
            >
                {row?.original?.status?.text}
            </Typography>
        </Box>
    )
    const columns = useMemo(
        () => [
            {
                size: 160,
                accessorKey: "status",
                header: "",
                Cell: getStatus,
            },
            {
                size: 160,
                accessorKey: "amount",
                header: "Trip count",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(row?.original?.amount ?? 0)}`,
            },
            {
                size: 160,
                accessorKey: "average_km",
                header: "Average km",
                Cell: ({ row }) =>
                    `${roundToThreeDecimalPlaces(
                        Number(row?.original?.average_km) / 1000 ?? 0
                    )}`,
            },
            {
                size: 160,
                accessorKey: "total_km",
                header: "Total km",
                Cell: ({ row }) =>
                    `${roundToThreeDecimalPlaces(
                        Number(row?.original?.total_km) / 1000 ?? 0
                    )}`,
            },
            {
                size: 160,
                accessorKey: "average_amount",
                header: "Average amount",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.average_amount ?? 0
                    )}`,
            },
            {
                size: 160,
                accessorKey: "total_amount",
                header: "Total amount",
                Cell: ({ row }) =>
                    `${roundToTwoDecimalPlaces(
                        row?.original?.total_amount ?? 0
                    )}`,
            },
        ],
        []
    )
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <MaterialReactTable
                columns={columns}
                data={data ?? []}
                enableTopToolbar={false}
                enableColumnActions={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnResizing
                initialState={{
                    columnPinning: {
                        left: ["status"],
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        "& .Mui-TableHeadCell-Content": {
                            justifyContent: "space-between",
                        },
                    },
                }}
                enablePinning
            />
        </Box>
    )
}
export default TripsCount
