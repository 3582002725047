import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import React, { useContext, useEffect } from "react"
import AuthContext from "../Context/authProvider.jsx"
import utils from "../Utils/login_redirect.js"

// Sign In with Ride Plus SSO
function SignIn() {
    // CONTEXT
    const { persist, setPersist } = useContext(AuthContext)

    // USEEFFECT
    useEffect(() => {
        localStorage.setItem("persist", persist)
        utils.LoginRedirect("email")
        setPersist(true)
    }, [persist, setPersist])

    // RENDER
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                minHeight: "40vh",
            }}
        >
            <CircularProgress
                size={50}
                sx={{
                    color: "secondary.main",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                }}
            />
        </Box>
    )
}
export default SignIn
