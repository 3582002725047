/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useState } from "react"
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from "@mui/material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { MoreVert, Refresh } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import CustomExportOptions from "../../Component/customExport.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"

// Family Package list view component
function FamilyPackage() {
    const navigate = useNavigate()

    // USESTATE HOOK
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [anchorEl, setAnchorEl] = useState(null)
    const [owner, setOwner] = useState({})

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        ["family_package", pagination.pageIndex, pagination.pageSize],
        () =>
            axiosPrivate.get(`/system/family-packages`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
        }
    )

    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <IconButton
                    sx={{
                        color: "primary.main",
                    }}
                    onClick={(e) => {
                        setAnchorEl(e.currentTarget)
                        setOwner(row?.original)
                    }}
                >
                    <MoreVert />
                </IconButton>
                <Menu
                    key={row?.original.id}
                    id={row?.original.id}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem
                        disabled={!HasPermission("list family programs")}
                        onClick={() => {
                            navigate(`${owner?.first_name}/programs`, {
                                state: { id: owner?.id },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Programs
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("list all transactions")}
                        onClick={() => {
                            navigate(`${owner?.first_name}/transactions`, {
                                state: { id: owner?.id },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Transactions
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!HasPermission("list family program members")}
                        onClick={() => {
                            navigate(`${owner?.first_name}/members`, {
                                state: { id: owner?.id },
                            })
                            setAnchorEl(null)
                        }}
                    >
                        <Typography
                            sx={{
                                color: "#707070",
                            }}
                        >
                            Members
                        </Typography>
                    </MenuItem>
                </Menu>
            </Box>
        ),
        [anchorEl, navigate, owner]
    )
    // DATA STRUCTURE
    const columns = [
        {
            size: 180,
            accessorKey: "phone",
            header: "Phone",
        },
        {
            size: 180,
            accessorKey: "first_name",
            header: "First Name",
        },
        {
            size: 180,
            accessorKey: "middle_name",
            header: "Middle Name",
        },
        {
            size: 180,
            accessorKey: "last_name",
            header: "Last Name",
        },
        {
            size: 220,
            accessorKey: "email",
            header: "Email",
        },
        {
            accessorKey: "action",
            header: "Action",
            enableSorting: false,
            enableColumnFilter: false,
            Cell: actions,
        },
    ]

    // RENDER
    if (!HasPermission("list family program owners")) return <Unauthorized />
    return (
        <Box sx={{ height: "100%" }}>
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["action"],
                        },
                    }}
                    enableColumnResizing
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    onPaginationChange={setPagination}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <CustomExportOptions
                                table={table}
                                model={[]}
                                page="/system/family-packages"
                                formatter={handleDateExport}
                            />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                    }}
                />
            </Box>
        </Box>
    )
}

export default FamilyPackage
