/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useState } from "react"
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import { Refresh } from "@mui/icons-material"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import { useQuery } from "react-query"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"

import HasPermission from "../../Utils/access.js"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import { roundToTwoDecimalPlaces } from "../../Utils/dataFormat.js"
import handleDateExport from "../../Utils/File Export Formatter/dateFilterFormat.js"

import Unauthorized from "../../Component/unauthorized.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"

// Efloat TopUp Transactions List Page Component
function EfloatTopUpTransactions() {
    // USESTATE HOOK
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        amount: "=",
        status: "is",
        created_at: "is",
        updated_at: "is",
        email: "contains",
        phone: "contains",
        method: "contains",
        last_name: "contains",
        short_code: "contains",
        first_name: "contains",
        middle_name: "contains",
        telebirr_transaction_id: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })

    const defaultFilter = {
        column_field: "efloat_type",
        operator_value: "equals",
        value: "E-Float Top Up",
    }
    const driversCountFilter = useMemo(
        () => columnFilters?.filter((item) => item.id === "created_at"),
        [columnFilters]
    )
    const driversCountSort = useMemo(
        () =>
            sorting
                ?.filter((item) => item.id === "created_at")
                .map((item) => ({
                    field: item?.id || item?.field,
                    sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
                })),
        [sorting]
    )
    const model = filterModel(filterFns, columnFilters)
    model.push(defaultFilter)

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()
    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "efloat-topup-transactions",
            sort,
            filterFns,
            globalFilter,
            columnFilters,
            pagination.pageIndex,
            pagination.pageSize,
        ],
        () =>
            axiosPrivate.get(`/system/efloat`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(model ?? undefined),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get e-float request"),
        }
    )
    const { isFetching: fetchingBalance, data: balance } = useQuery(
        [
            "recharge-balance",
            sort,
            filterFns,
            globalFilter,
            columnFilters,
            pagination.pageIndex,
            pagination.pageSize,
        ],
        () =>
            axiosPrivate.get(`/system/efloat/balances`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(model ?? undefined),
                    sort: JSON.stringify(sort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get-efloat-balance"),
        }
    )
    const { isFetching: fetchingDriversCount, data: driversCount } = useQuery(
        [
            "efloat-topup-drivers-count",
            filterFns,
            globalFilter,
            driversCountSort,
            driversCountFilter,
            pagination.pageIndex,
            pagination.pageSize,
        ],
        () =>
            axiosPrivate.get(`/system/efloat/report`, {
                params: {
                    per_page: -1,
                    filter: JSON.stringify(
                        filterModel(filterFns, driversCountFilter) ?? undefined
                    ),
                    sort: JSON.stringify(driversCountSort ?? []),
                    search: globalFilter ?? undefined,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get e-float report"),
        }
    )
    const EfloatDriversCount = useMemo(
        () =>
            driversCount?.data?.data?.filter(
                (item) => item.type === "E-Float Top Up"
            )[0],
        [driversCount?.data?.data]
    )
    // HELPERS and EVENT HANDLERS
    const getStatus = useCallback(
        ({ row }) => (
            <Box>
                {(row?.original?.status !== "" ||
                    row?.original?.status !== undefined) && (
                    <Button
                        key={row?.original?.id}
                        disableElevation
                        variant="contained"
                        sx={{
                            borderRadius: "10px",
                            fontWeight: "bold",
                            backgroundColor:
                                row?.original?.status === "SUCCEEDED"
                                    ? "#E5FFEA"
                                    : row?.original?.status === "PROCESSING"
                                    ? "#F5F5F5"
                                    : "#ffd6d6",
                            color:
                                row?.original?.status === "SUCCEEDED"
                                    ? "#16C138"
                                    : row?.original?.status === "PROCESSING"
                                    ? "#FFDC00"
                                    : "red",
                            "&: hover": {
                                backgroundColor:
                                    row?.original?.status === "SUCCEEDED"
                                        ? "#E5FFEA"
                                        : row?.original?.status === "PROCESSING"
                                        ? "#F5F5F5"
                                        : "#ffd6d6",
                                color:
                                    row?.original?.status === "SUCCEEDED"
                                        ? "#16C138"
                                        : row?.original?.status === "PROCESSING"
                                        ? "#FFDC00"
                                        : "red",
                            },
                            width: 160,
                            py: 1,
                        }}
                    >
                        {row?.original?.status}
                    </Button>
                )}
            </Box>
        ),
        []
    )
    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 220,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                Cell: ({ row }) => row?.original?.user_info?.first_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "middle_name",
                header: "Middle Name",
                Cell: ({ row }) => row?.original?.user_info?.middle_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "last_name",
                header: "Last Name",
                Cell: ({ row }) => row?.original?.user_info?.last_name,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 180,
                accessorKey: "phone",
                header: "Phone",
                Cell: ({ row }) => row?.original?.user_info?.phone,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "email",
                header: "Email",
                Cell: ({ row }) => row?.original?.user_info?.email,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "short_code",
                header: "Agent Code",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 200,
                accessorKey: "amount",
                header: "Amount (ETB)",
                filterVariant: "number",
                Cell: ({ cell }) =>
                    cell?.getValue() > 0
                        ? `${roundToTwoDecimalPlaces(cell?.getValue())}`
                        : `${cell?.getValue()}`,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "method",
                header: "Method",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 260,
                accessorKey: "telebirr_transaction_id",
                header: "Telebirr Transaction ID",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "status",
                header: "Status",
                filterVariant: "select",
                filterSelectOptions: ["PROCESSING", "SUCCEEDED", "FAILED"],
                Cell: getStatus,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "updated_at",
                header: "Last Updated",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
        ],
        [getStatus]
    )
    // RENDER
    if (!HasPermission("get e-float request")) {
        return <Unauthorized />
    }
    return (
        <Box>
            <Box sx={{ height: "100%" }}>
                <MaterialReactTable
                    columns={columns}
                    data={data?.data?.data ?? []}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select"],
                            right: ["status"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                            }}
                        >
                            {HasPermission("get-efloat-balance") && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: 1,
                                        ml: 1,
                                    }}
                                >
                                    <Typography variant="body2" mr={1}>
                                        Recharge Balance:{" "}
                                    </Typography>
                                    {fetchingBalance ? (
                                        <CircularProgress size="1em" />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontWeight: 900,
                                            }}
                                        >
                                            {Number(
                                                balance?.data?.data?.filter(
                                                    (item) =>
                                                        item.short_code ===
                                                        "301734"
                                                )[0]?.current_balance
                                            )?.toLocaleString()}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            {HasPermission("get e-float report") && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mt: 1,
                                        ml: 1,
                                    }}
                                >
                                    <Typography variant="body2" mr={1}>
                                        Drivers Count:{" "}
                                    </Typography>
                                    {fetchingDriversCount ? (
                                        <CircularProgress size="1em" />
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontWeight: 900,
                                            }}
                                        >
                                            {Number(
                                                EfloatDriversCount?.total_drivers
                                            )?.toLocaleString()}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={model}
                                page="/system/efloat"
                                formatter={handleDateExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}

export default EfloatTopUpTransactions
