/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useMemo, useRef, useState } from "react"
import {
    Avatar,
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material"
import { DateTime } from "luxon"
import MaterialReactTable, {
    MRT_FullScreenToggleButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table"
import {
    Edit,
    HighlightOff,
    Refresh,
    TaskAlt,
    Visibility,
} from "@mui/icons-material"
import { useQuery } from "react-query"
import { useSnackbar } from "notistack"
import { useNavigate } from "react-router-dom"
import AddUsers from "./addUsers.jsx"
import CustomExportOptions from "../../Component/customExport.jsx"
import customFilterPanel from "../../Component/customFilter.jsx"
import CustomeDateFilter from "../../Component/customDateFilterPanel.jsx"
import HasPermission from "../../Utils/access.js"
import Unauthorized from "../../Component/unauthorized.jsx"
import filterModel from "../../Utils/filterModel.js"
import useAxiosPrivate from "../../Hooks/useAxiosPrivate.js"
import {
    SSO_API_BASE_URL,
    SSO_ASSETS_URL,
    SSO_VERSION,
} from "../../Utils/config.js"
import AssignRole from "./assignRole.jsx"
import { handleSystemUsersExport } from "../../Utils/File Export Formatter/companyUsersExport.js"

// Users List View Component
function Users() {
    const navigate = useNavigate()
    // USESTATES & USEREFS
    const [columnFilters, setColumnFilters] = useState([])
    const [filterFns, setFilterFns] = useState({
        created_at: "is",
        email: "contains",
        first_name: "contains",
        middle_name: "contains",
        last_name: "contains",
        phone: "contains",
    })
    const [globalFilter, setGlobalFilter] = useState()
    const [sorting, setSorting] = useState([
        {
            field: "created_at",
            sort: "desc",
        },
    ])
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    })
    const [open, setOpen] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const userData = useRef({})
    const userRoles = useRef({})

    // Structured Sort Based on Backend Requirements
    const sort = useMemo(
        () =>
            sorting?.map((item) => ({
                field: item?.id || item?.field,
                sort: item?.sort === "desc" || item?.desc ? "desc" : "asc",
            })),
        [sorting]
    )

    // SNACKBAR
    const { enqueueSnackbar } = useSnackbar()

    // CUSTOM HOOK
    const axiosPrivate = useAxiosPrivate()

    // QUERY & MUTATION
    const { isFetching, data, refetch } = useQuery(
        [
            "system_users",
            columnFilters,
            globalFilter,
            pagination.pageIndex,
            pagination.pageSize,
            sort,
            filterFns,
            columnFilters,
        ],
        () =>
            axiosPrivate.get(`system/users`, {
                params: {
                    page: `${pagination.pageIndex}`,
                    per_page: `${pagination.pageSize}`,
                    filter: JSON.stringify(
                        filterModel(filterFns, columnFilters)
                    ),
                    sort: JSON.stringify(sort),
                    search: globalFilter,
                    link_operator: "and",
                },
            }),
        {
            onError: (error) =>
                enqueueSnackbar(
                    error?.response?.data?.error?.message ||
                        error?.message ||
                        "Network Error!",
                    {
                        variant: "error",
                        preventDuplicate: true,
                        autoHideDuration: 2000,
                    }
                ),
            enabled: HasPermission("get system users"),
        }
    )

    // EVENT HANDLERS & HELPERS
    const handleModalClose = () => {
        setOpen(false)
    }
    const handleEditModalClose = () => {
        setOpenEdit(false)
    }
    const getAvatar = useCallback(
        ({ cell }) => (
            <Avatar
                sx={{
                    width: 50,
                    height: 50,
                }}
                src={`${SSO_API_BASE_URL}/${SSO_VERSION}/${SSO_ASSETS_URL}/${cell?.row?.original?.profile_picture}`}
            />
        ),
        []
    )
    const actions = useCallback(
        ({ row }) => (
            <Box sx={{ flex: 1 }}>
                <Button
                    key={row?.original?.id}
                    onClick={() => {
                        navigate(`${row?.original?.id}/details`, {
                            state: { userData: row?.original },
                        })
                    }}
                    sx={{
                        minWidth: 24,
                        p: 1,
                        color: "primary.main",
                    }}
                >
                    <Visibility />
                </Button>
            </Box>
        ),
        [navigate]
    )
    const getRoles = useCallback(
        ({ row }) => (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box>
                    {row?.original?.roles?.map((role) => (
                        <Box
                            sx={{
                                display: "inline-block",
                                justifyContent: "start",
                                alignItems: "center",
                                fontWeight: "bold",
                                overflow: "hidden",
                                m: ".05em",
                                backgroundColor:
                                    role?.status === "ACTIVE"
                                        ? "success.main"
                                        : "error.main",
                                color: "white",
                                borderRadius: "20px",
                                pr: "1em",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <IconButton
                                    size="small"
                                    sx={{
                                        color: "white",
                                    }}
                                >
                                    {role?.status === "ACTIVE" ? (
                                        <TaskAlt fontSize=".1em" />
                                    ) : (
                                        <HighlightOff fontSize=".1em" />
                                    )}
                                </IconButton>
                                <Typography variant="subtitle2" noWrap>
                                    {role?.role_name}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        justifyContent: "flex-end",
                    }}
                >
                    <IconButton
                        disabled={
                            !(
                                HasPermission("assign role") ||
                                HasPermission("revoke role")
                            )
                        }
                        onClick={() => {
                            setOpenEdit(true)
                            userData.current = row?.original
                            userRoles.current = row?.original?.roles?.map(
                                (role) => ({
                                    name: role?.role_name,
                                    id: role?.id,
                                    status: role?.status,
                                })
                            )
                        }}
                        sx={{
                            fontSize: 16,
                            fontWeight: "bold",
                            borderRadius: 0,
                            color: "primary.main",
                        }}
                    >
                        <Edit />
                    </IconButton>
                </Box>
            </Box>
        ),
        []
    )

    const datePicker = (props) => <CustomeDateFilter {...props} />

    // DATA STRUCTURE
    const columns = useMemo(
        () => [
            {
                size: 150,
                accessorKey: "photo",
                header: "Photo",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                align: "center",
                Cell: getAvatar,
            },
            {
                size: 240,
                accessorKey: "created_at",
                header: "Registered Time",
                filterVariant: "date",
                renderColumnFilterModeMenuItems: customFilterPanel,
                Cell: ({ cell }) =>
                    DateTime.fromISO(cell?.getValue()).toLocaleString(
                        DateTime.DATETIME_MED
                    ),
                Filter: datePicker,
            },
            {
                accessorKey: "first_name",
                header: "First Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "middle_name",
                header: "Middle Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "last_name",
                header: "Last Name",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                accessorKey: "phone",
                header: "Phone",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 220,
                accessorKey: "email",
                header: "Email",
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            {
                size: 300,
                accessorKey: "role",
                header: "Role(s)",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: getRoles,
                renderColumnFilterModeMenuItems: customFilterPanel,
            },
            // TO DO: After the backend decides the implementation
            // {
            //     size: 220,
            //     accessorKey: "status",
            //     header: "Status",
            //     filterVariant: "select",
            //     filterSelectOptions: ["ACTIVE", "INACTIVE"],
            //     Cell: getStatus,
            //     renderColumnFilterModeMenuItems: customFilterPanel,
            // },
            {
                accessorKey: "actions",
                header: "Actions",
                enableSorting: false,
                enableColumnFilter: false,
                enableGlobalFilter: false,
                Cell: actions,
            },
        ],
        [actions, getAvatar, getRoles]
    )

    // RENDERING
    if (!HasPermission("get system users")) {
        return <Unauthorized />
    }

    return (
        <Box sx={{ height: "100%" }}>
            {open && (
                <AddUsers
                    handleModalClose={handleModalClose}
                    open={open}
                    refetch={refetch}
                />
            )}
            {openEdit && (
                <AssignRole
                    open={openEdit}
                    userData={userData.current}
                    userRoles={userRoles.current}
                    handleEditModalClose={handleEditModalClose}
                    refetch={refetch}
                />
            )}
            <Box sx={{ flex: 1, height: "100%" }}>
                <MaterialReactTable
                    data={data?.data?.data ?? []}
                    columns={columns}
                    initialState={{
                        columnPinning: {
                            left: ["mrt-row-select", "photo"],
                            right: ["actions"],
                        },
                    }}
                    enableColumnResizing
                    enableColumnFilterModes
                    enableStickyHeader
                    enableColumnOrdering
                    enableRowSelection
                    enablePinning
                    manualFiltering
                    manualPagination
                    manualSorting
                    filterFns={{
                        after: (row, filterValue) =>
                            row.customField === filterValue,
                    }}
                    muiTableHeadCellFilterTextFieldProps={({ column }) => ({
                        helperText: `Filter Mode: ${filterFns[column?.id]}`,
                    })}
                    onColumnFiltersChange={setColumnFilters}
                    onColumnFilterFnsChange={setFilterFns}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    renderTopToolbarCustomActions={() => (
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            {HasPermission("get users by phone") && (
                                <Button
                                    size="small"
                                    sx={{
                                        bgcolor: "primary.main",
                                        color: "white",
                                        px: 2,
                                        mx: 1,
                                        boxShadow: 2,
                                        " :hover": {
                                            bgcolor: "primary.main",
                                            color: "white",
                                        },
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Add User
                                </Button>
                            )}
                        </Box>
                    )}
                    renderToolbarInternalActions={({ table }) => (
                        <>
                            <Tooltip arrow title="Refresh Data">
                                <IconButton onClick={() => refetch()}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <CustomExportOptions
                                table={table}
                                model={filterModel(filterFns, columnFilters)}
                                page="/system/users"
                                formatter={handleSystemUsersExport}
                            />
                            <MRT_ToggleFiltersButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_FullScreenToggleButton table={table} />
                        </>
                    )}
                    muiTableBodyCellProps={({ table, column }) => {
                        const columnName = column.id
                        const pinnedState = table.getState().columnPinning

                        const isLeftPinned = pinnedState?.left?.some(
                            (el) => el === columnName
                        )
                        const isRightPinned = pinnedState?.right?.some(
                            (el) => el === columnName
                        )
                        if (isLeftPinned || isRightPinned)
                            return {
                                sx: {
                                    "&.MuiTableCell-root": {
                                        boxShadow: isRightPinned
                                            ? "-7px 0px 10px -1.7px lightgray"
                                            : "7px 0px 10px -1.7px lightgray",
                                    },
                                },
                            }
                        return null
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: `calc(100vh - 225px)` },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            "& .Mui-TableHeadCell-Content": {
                                justifyContent: "space-between",
                            },
                        },
                    }}
                    rowCount={data?.data?.meta_data?.total ?? 0}
                    state={{
                        columnFilters,
                        filterFns,
                        globalFilter,
                        isFetching,
                        pagination,
                        showSkeletons: isFetching,
                        sorting,
                    }}
                />
            </Box>
        </Box>
    )
}
export default Users
