import React from "react";
import { Avatar, Card, CardMedia, Grid } from "@mui/material";
import { Box, Container } from "@mui/system";
import pageNotFound from '../Assets/404-error.jpg'
import logo from "../Assets/ride_logo.png";

// Page Not Found Component
function PageNotFound() {
    return (
        <Grid
            container
            sx={{
                minHeight: "100vh",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Container component="main" maxWidth="md" >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Avatar
                        sx={{
                            width: 200,
                            height: 180,
                            borderRadius: 0
                        }}
                        alt="logo"
                        src={logo}
                    />
                    <Card elevation={0} sx={{ width: "40%", mt: -4}}>
                        <CardMedia
                            component="img"
                            image={pageNotFound}
                            alt="page not found"
                        />
                    </Card>
                </Box>
            </Container>
        </Grid>
    )
}
export default PageNotFound;