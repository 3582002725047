import { DateTime } from "luxon"
import { roundToTwoDecimalPlaces } from "../dataFormat.js"

const handleTripsExport = (data) =>
    data?.map((item) => ({
        ...item,
        create_time: item.create_time?  DateTime.fromISO(item.create_time).toFormat("ff") : "",
        created_at: DateTime.fromISO(item.created_at).toFormat("ff"),
        updated_at: item?.updated_at ? DateTime.fromISO(item.updated_at).toFormat("ff") : "",
        start_time: item?.start_time ? DateTime.fromISO(item.start_time).toFormat("ff") : "",
        end_time: item?.end_time ? DateTime.fromISO(item.end_time).toFormat("ff") : "",
        distance: item?.distance > 1000
            ? `${Number(item?.distance) / 1000
            } km`
            : `${roundToTwoDecimalPlaces(item?.distance)} m`,
        estimated_price: item?.estimated_cost > 0
            ? `${roundToTwoDecimalPlaces(item?.estimated_cost)}`
            : `${item?.estimated_cost ?? 0}`,
        price: item?.cost > 0
            ? `${roundToTwoDecimalPlaces(item?.cost)}`
            : `${item?.cost ?? 0}`,
        start_location: `latitude: ${item?.start_location?.latitude}, longitude:${item?.start_location?.longitude ?? 0}`,
        end_location: `latitude: ${item?.end_location?.latitude}, longitude:${item?.end_location?.longitude ?? 0}`,
        driver_info: `${item?.driver_info?.first_name ?? ""} ${item?.driver_info?.middle_name ?? ""} ${item?.driver_info?.last_name ?? ""}`,
        driver_phone: item?.driver_info?.phone,
    }))
export default handleTripsExport