import React from "react"
import ViewRideTripDetails from "../../../Component/rideTripDetails.jsx"
import ViewRidePlusTripDetails from "../../../Component/ridePlusTripDetails.jsx"

// Package Trip Details View Component
function ViewPackageTripDetails({ tripData, open, handleClose }) {
    return tripData?.type === "Ride Trip" ? (
        <ViewRideTripDetails
            id={tripData?.id}
            open={open}
            handleClose={handleClose}
        />
    ) : (
        <ViewRidePlusTripDetails
            id={tripData?.id}
            open={open}
            handleClose={handleClose}
        />
    )
}

export default ViewPackageTripDetails
